import { Box } from "@mui/material";
import PagedTableList from "saga-library/src/components/TableList/PagedTableList";
import { Radio, SearchField, TableListHeaderConfig, SimpleRadioGroup } from "saga-library/src";
import React, { useEffect, useTransition } from "react";
import { AbClaim } from "../../../../types/billing";
import { ABClaimAssessmentOutcome } from "../../../../utils/ABClaimConstants";
import { AssessedClaimRow } from "./AssessedClaimRow";
import { useReadQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import _get from "lodash/get";
import { useAssessedClaimsContext } from '../../providers/AssessedClaimsProvider'
import { useAccountContext } from '../../../../providers/AccountContext'
import { PractitionerDropdown } from '../../../../components/PractitionerDropdown'

const columns: TableListHeaderConfig[] = [
  { name: 'Patient' },
  { name: 'Service date', sortable: true },
  { name: 'Service' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Claimed' },
  { name: 'Paid' },
  { name: 'Claim', sortable: true },
  { name: 'Outcome' },
  { name: 'Expl' },

]

export interface AssessedClaimsListProps {
  selectedClaims: AbClaim[]
  setSelectedClaims: (v) => void
  dataTestId: string
}

const AssessedClaimsList = ({
  selectedClaims,
  setSelectedClaims,
  dataTestId
}: AssessedClaimsListProps) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [outcome, setOutcome] = React.useState<string>(queryParams.get('filter') || "*")
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const [isPending, startTransition] = useTransition()
  const { assessedClaimsListQueryRef, changeFilters, selectedClaimIds, setSelectedClaimIds } = useAssessedClaimsContext()

  const data = useReadQuery(assessedClaimsListQueryRef!)
  let claimsList = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.edges', [] as any).map(e => e.node)
  let pageInfo = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.pageInfo')

  const onRequestSort = (key, order) => {
    // TODO: Fix sorting
    // assessedClaimsParams.sorter = {
    //   sortOrder: order === "asc" ? SortOrder.ASCENDING : SortOrder.DESCENDING,
    //   sortColumn: key === "Claim" ? AssessedAbClaimSortColumn.CLAIM_IDENTIFIER : AssessedAbClaimSortColumn.SERVICE_DATE
    // }
  }

  const onRequestNext = (cursor) => {
    // TODO: Fix 'load more'
  }

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>, claims: AbClaim[]) => {
    if (event.target.checked) {
      setSelectedClaims(claims)
    } else {
      setSelectedClaims([])
    }
  }

  const onFiltersChanged = (filters) => {
    startTransition(() => {
      changeFilters(filters)
    })
  }

  const onCheckboxClick = (claim_id) => {
    let newClaimList: AbClaim[] = [...selectedClaims]
    if (!selectedClaims.some((c) => c.id === claim_id)) {
      const claim = claimsList.find(ac => ac.id === claim_id)
      if (claim) newClaimList.push(claim)
    } else {
      newClaimList = newClaimList.filter((c) => c.id !== claim_id)
    }
    setSelectedClaims(newClaimList)
  }

  const onRowClick = (claim: AbClaim) => {
    navigate(buildTenantRoute(`billing/assessed/c/${claim.id}`, tenant_id))
  }


  let rows = []
  if (!isPending) {
    rows = claimsList?.map(node => AssessedClaimRow(node, onCheckboxClick, !!selectedClaims.find(sc => sc.id === node.id), onRowClick)) || []
  }

  return (
    <Box height={'100%'} display={'flex'} flexDirection={'column'} paddingRight={1}>
      <AssessedClaimListHeader
        onFiltersChanged={onFiltersChanged}
        outcome={outcome}
        setOutcome={setOutcome}
        dataTestId={dataTestId}
      />
      <PagedTableList
        loading={isPending}
        showCheckbox={!!outcome && outcome !== ABClaimAssessmentOutcome.HELD}
        checkedRows={selectedClaimIds}
        setCheckedRows={setSelectedClaimIds}
        size={'small'}
        columns={columns}
        rows={rows}
        onRequestSort={onRequestSort}
        defaultSortColumn={columns.findIndex(x => x.name === 'Claim')}
        pagingMode={'continuous'}
        pageInfo={pageInfo}
        onRequestNext={onRequestNext}
        onSelectAllClick={(e) => onSelectAll(e, claimsList)}
        emptyListComponent={ { message: "Claims received from AHCIP appear here." } }
        dataTestId={dataTestId}
      />
    </Box>
  )
}

const AssessedClaimListHeader = ({onFiltersChanged, outcome, setOutcome, dataTestId}) => {
  const [practitioner, setPractitioner] = React.useState<string | null>(null)
  const [search, setSearch] = React.useState<string>("")

  useEffect( () => {
    let outcomeFilter = outcome
    if (outcome === 'ACCEPTED' || outcome === 'RESUBMITTED' || outcome === "*") {
      outcomeFilter = null
    }
    onFiltersChanged (
      {
        assessmentOutcomeFilter: outcomeFilter,
        practitionerId: practitioner,
        searchTerm: search,
        accepted: outcome === 'ACCEPTED',
        adjusted: outcome === ABClaimAssessmentOutcome.APPLIED,
        resubmittedOnly: outcome === 'RESUBMITTED',
      }
    )
  }, [outcome, practitioner, search])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box justifyContent={'center'}>
        <SimpleRadioGroup
          label={null}
          row={true}
          name={'assessmentOutcome'}
          value={outcome}
          onChange={ (e) => {
            setOutcome(e.currentTarget.value)
          }}
          sx={{ gap: '10px' }}
          dataTestId={`${dataTestId}-outcomes`}
        >
          <Radio label={'All'} value={"*"} sx={{ ml: 0 }} dataTestId={`${dataTestId}-all`} />
          <Radio label={'Accepted'} value={'ACCEPTED'} dataTestId={`${dataTestId}-accepted`} />
          <Radio label={'Held by AHCIP'} value={ABClaimAssessmentOutcome.HELD} dataTestId={`${dataTestId}-held`} />
          <Radio label={'Adjusted'} value={ABClaimAssessmentOutcome.APPLIED} dataTestId={`${dataTestId}-applied`} />
          <Radio label={'Refused'} value={ABClaimAssessmentOutcome.REFUSED} dataTestId={`${dataTestId}-refused`} />
          <Radio label={'Resubmitted'} value={'RESUBMITTED'} dataTestId={`${dataTestId}-resubmitted`} />
        </SimpleRadioGroup>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
        <PractitionerDropdown
          onChange={(id) => setPractitioner(id)}
          dataTestId={`${dataTestId}-practitioner`}
        />
        <SearchField value={search} onChange={onChange} dataTestId={`${dataTestId}-search`} />
      </Box>
    </Box>
  )
}

export default AssessedClaimsList
