import { gql } from "@apollo/client";

export const LINKED_DOCUMENTS_FRAGMENT = gql`
  fragment LinkedDocumentsFragment on LinkedDocument {
    linkedDocumentId:id
    documentDate
    description:description
    category
  }
`

export const TASK_ASSIGNMENT_FRAGMENT = gql`
  ${LINKED_DOCUMENTS_FRAGMENT}
  fragment TaskAssignmentFragment on TaskAssignment {
    id
    name
    description
    priority
    startDate
    dueDate
    patient {
      id
      firstName
      lastName
      primaryIdentifierLabel
      primaryIdentifier
      dob
    }
    state {
      id
      name
      category
    }
    type {
      id
      type
    }
    assignedUserId
    assignedRoleId
    practitionerId
    assignedUser {
      id
      firstName
      lastName
    }
    assignedRole {
      id
      name
    }
    version
    comments{
      id
      comment
      audit{
        creUserId
        crePit
        luPit
        creUserFirstName
        creUserLastName
      }
      version
    }   
    linkedItems{
      id
      itemId
      itemType
      item{
        ... on AbLabResult {
          id
          isLinkedDocument
          description
          date
          linkedDocument {
            ...LinkedDocumentsFragment
          }
        }
               
        ... on Allergy {
          id
          allergen
          reactionDescription
          severity
          reactionType
        }
        
        ... on Form {
          id
          name
          description        
          formDate
          isLinkedDocument
          linkedDocument {
            ...LinkedDocumentsFragment
          }
        }  
        
        ... on Letter {
          id
          title
          notes
          letterDate
          isLinkedDocument
          linkedDocument { 
            ...LinkedDocumentsFragment
          }
        }
           
        ... on EncounterNote {
          id
          encounterNoteDate
          encounterNoteDisplayDate
          signOffPit
          description
          isLinkedDocument
          linkedDocument {
            ...LinkedDocumentsFragment
          }
          template {
            id
          }
        }
        
        ... on ConditionAndProcedure {
          id
          conditionDescription: description 
          conditionStatus: status
          conditionSeverity: severity
        }
          
        ... on LinkedDocument {
          ...LinkedDocumentsFragment
        }
        
        ... on Prescription {
          prescriptionId: id
          status
          dosages {
            id
            dosageRange
            dosageUnits
            durationAmount
            durationUnits
          } 
          drug {
            name
            code
            codeType
          }         
          startDate
          quantityUnits
        }
          
        ... on TaskAssignment {
          taskId: id          
          taskName: name
          description
          priority
          taskStartDate: startDate
          dueDate
          state {
            id
            name
            category
          }
          type {
            id
            type
          }
        } 
      }
    }
  }
`



export const LIST_TASK_ASSIGNMENTS = gql`
  ${TASK_ASSIGNMENT_FRAGMENT}
  query ListTaskAssignments($tenantId: ID!, $roleId: ID, $userId: ID) {
    tenant(id: $tenantId) {
      task {
        assignment {
          list(roleId: $roleId, userId: $userId) {
            ...TaskAssignmentFragment
          }
        }
      }
    }
  }
`

export const GET_PATIENT_TASK_ASSIGNMENTS = gql`
  ${TASK_ASSIGNMENT_FRAGMENT}
  query GetPatientTaskAssignments($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        task {
          list {
            ...TaskAssignmentFragment
          }
        }
      }
    }
  }
`

export const ASSIGN_TASK = gql`
  ${TASK_ASSIGNMENT_FRAGMENT}
  mutation AssignTask($tenantId: ID!, $input: TaskAssignmentInput!) {
    tenant(id: $tenantId) {
      task {
        assignment {
          create(input: $input) {
            ...TaskAssignmentFragment
          }
        }
      }
    }
  }
`

export const UPDATE_TASK = gql`
  ${TASK_ASSIGNMENT_FRAGMENT}
  mutation UpdateTask($tenantId: ID!, $id: ID!, $input: TaskAssignmentInput!) {
    tenant(id: $tenantId) {
      task {
        assignment {
          update(id: $id, input: $input) {
            ...TaskAssignmentFragment
          }
        }
      }
    }
  }
`

export const DELETE_TASK = gql`
  mutation($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      task {
        assignment {
          delete(id: $id, version: $version)
        }
      }
    }
  }
`

export const UPDATE_TASK_ASSIGNMENT_STATUSES = gql`
  mutation UpdateTaskAssignmentStatuses($tenantId: ID!, $ids: [ID!]!, $stateId: ID!) {
    tenant(id: $tenantId) {
      task {
        assignment {
          updateStatuses(ids: $ids, stateId: $stateId)
        }
      }
    }
  }
`

const TASK_TYPE_FRAGMENT = gql`
  fragment TaskTypeFragment on TaskType {
    id
    type
    version
    active
  }
`

export const LIST_TASK_TYPES = gql`
  ${TASK_TYPE_FRAGMENT}
  query ListTaskTypes($tenantId: ID!) {
    tenant(id: $tenantId) {
      task {
        type {
          list {
            ...TaskTypeFragment
          }
        }
      }
    }
  }
`

export const CREATE_TASK_TYPE = gql`
  ${TASK_TYPE_FRAGMENT}
  mutation CreateTaskType($tenantId: ID!, $input: TaskTypeInput!) {
    tenant(id: $tenantId) {
      task {
        type {
          create(input: $input) {
            ...TaskTypeFragment
          }
        }
      }
    }
  }
`

export const UPDATE_TASK_TYPE = gql`
  ${TASK_TYPE_FRAGMENT}
  mutation UpdateTaskType($tenantId: ID!, $id: ID!, $input: TaskTypeInput!) {
    tenant(id: $tenantId) {
      task {
        type {
          update(id: $id, input: $input) {
            ...TaskTypeFragment
          }
        }
      }
    }
  }
`

export const DELETE_TASK_TYPE = gql`
  ${TASK_TYPE_FRAGMENT}
  mutation($tenantId: ID!, $taskTypeId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      task {
        type {
          delete(id: $taskTypeId, version: $version){
            ...TaskTypeFragment
          }
        }
      }
    }
  }
`

export const TASK_STATE_FRAGMENT= gql`
  fragment TaskStateFragment on TaskState {
    id
    name
    category
    order
    version
    active
  }
`

export const LIST_TASK_STATES = gql`
  ${TASK_STATE_FRAGMENT}
  query ListTaskStates($tenantId: ID!) {
    tenant(id: $tenantId) {
      task {
        state {
          list {
            ...TaskStateFragment
          }
        }
      }
    }
  }
`

export const CREATE_TASK_STATE = gql`
  mutation CreateTaskState($tenantId: ID!, $input: TaskStateInput!) {
    tenant(id: $tenantId) {
      task {
        state {
          create(input: $input) 
        }
      }
    }
  }
`

export const UPDATE_TASK_STATE = gql`
  ${TASK_STATE_FRAGMENT}
  mutation UpdateTaskState($tenantId: ID!, $taskStateId: ID!, $input: TaskStateInput!) {
    tenant(id: $tenantId) {
      task {
        state {
          update(id: $taskStateId, input: $input){
            ...TaskStateFragment
          } 
        }
      }
    }
  }
`

export const DELETE_TASK_STATE = gql`
  ${TASK_STATE_FRAGMENT}
  mutation DeleteTaskState($tenantId: ID!, $taskStateId: ID!, $version: String!){
    tenant(id: $tenantId) {
      task {
        state{
          delete(id: $taskStateId, version: $version) {
            ...TaskStateFragment
          }
        }
      }
    }
  }
`

export const UPDATE_TASK_STATE_SORT = gql`
${TASK_STATE_FRAGMENT}
  mutation UpdateTaskStateSort($tenantId: ID!, $taskStateId: ID!, $version: String!, $index: Short!) {
    tenant(id: $tenantId) {
      task {
        state {
          updateSort(id: $taskStateId, version: $version, index: $index){
            ...TaskStateFragment
          }
        }
      }
    }
  }
`

const TASK_TEMPLATE_FRAGMENT = gql`
  fragment TaskTemplateFragment on TaskTemplate {
    id
    type {
      id
      type
    }
    assignedUser {
      id
      firstName
      lastName
    }
    assignedRole {
      id
      name
    }
    name
    description
    taskName
    priority
    startOffset
    duration
    practitioner {
      id
      firstName
      lastName
    }
    version
  }
`

export const LIST_TASK_TEMPLATES = gql`
  ${TASK_TEMPLATE_FRAGMENT}
  query ListTaskTemplates($tenantId: ID!) {
    tenant(id: $tenantId) {
      task {
        template {
          list {
            ...TaskTemplateFragment
          }
        }
      }
    }
  }
`

export const CREATE_TASK_TEMPLATE = gql`
  ${TASK_TEMPLATE_FRAGMENT}
  mutation CreateTaskTemplate($tenantId: ID!, $input: TaskTemplateInput!) {
    tenant(id: $tenantId) {
      task {
        template {
          create(input: $input) {
            ...TaskTemplateFragment
          }
        }
      }
    }
  }
`

export const UPDATE_TASK_TEMPLATE = gql`
  ${TASK_TEMPLATE_FRAGMENT}
  mutation UpdateTaskTemplate($tenantId: ID!, $id: ID!, $input: TaskTemplateInput!) {
    tenant(id: $tenantId) {
      task {
        template {
          update(id: $id, input: $input) {
            ...TaskTemplateFragment
          }
        }
      }
    }
  }
`
