import React, { useEffect, useState } from 'react'
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { rules } from '../../../util/baseClaimValidation'
import { Refreshable, SimpleTextField } from "saga-library/src";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { BatchControlledServiceCodeSearch } from "../components/BatchControlledServiceCodeSearch";
import { MultipleValuesBatchMessage, serviceCodeHospitalAdmission } from "../../../../../utils/ABClaimConstants";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { BatchControlledDiagnosticCodeInput } from "../components/BatchControlledDiagnosticCodeInput";
import { BatchControlledServiceFeeModifierSelect } from "../components/BatchControlledServiceFeeModifierSelect";
import BatchControlledOverflowTextField from "../components/BatchControlledOverflowTextField";
import { SimpleTooltip } from "saga-library/src/components/Tooltip";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledFunctionalCenter } from "../components/BatchControlledFunctionalCenterInput";
import { BatchControlledReferralPractitionerSearch } from "../components/BatchControlledReferralPractitionerSearch";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import PatientRefreshable from "../../../../../components/SearchControls/Patient/PatientRefreshable";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { useAssessedClaimsContext } from '../../../providers/AssessedClaimsProvider'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import {useReadQuery} from "@apollo/client";
import _get from "lodash/get";


export const AssessedMultipleValuesField = ({ label, multipleValuesState, children, sx = {}, dataTestId }) => {
  const theme = useTheme()
  return (
    <>
      {
        multipleValuesState ?
          <SimpleTextField
            variant={'standard'}
            label={label}
            disabled={true}
            value={'Multiple values entered'}
            hideHelperText={true}
            InputProps={{
              disableUnderline: true
            }}
            InputLabelProps={{
              sx: {
                color: theme.palette.greys.medium,
                fontSize: '14px',
                ...sx
              },
            }}
            sx={{
              '& .MuiInputBase-input': {
                color: theme.palette.greys.light,
                fontStyle: 'italic',
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              },
              marginBottom: '16px',
              ...sx,
            }}
            dataTestId={`${dataTestId}-multipleValues`}
          />
          :
          children
      }
    </>
  )
}

const BatchControlledAssessedClaimAmount = ({ multipleValuesState, children }) => {
  const theme = useTheme()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <>
    {
      multipleValuesState ?
        <SimpleTooltip title={MultipleValuesBatchMessage} open={tooltipOpen}>
        <Box
          sx={{
            width: '100%',
            marginLeft: '16px',
            marginBottom: '8px',
            marginTop: '8px'
          }}
        >
          <span>Estimated total: </span>
          <Typography
            onMouseOver={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            sx={{
              fontWeight: 400,
              color: theme.palette.greys.light,
              display: 'inline-block',
              fontStyle: 'italic',
            }}
          >
           [...]
          </Typography>
        </Box>
        </SimpleTooltip>
        :
        children
    }
    </>
  )

}
const Form = ({ batchUpdateMultiple, dataTestId }) => {
  const { control, resetField, register, getValues } = useFormContext();
  const [practitionerId, setPractitionerId] = useState(getValues('practitionerId'))
  const { showSuccessAlert } = useAlerts()
  const { selectedClaimIds, assessedClaimsListQueryRef } = useAssessedClaimsContext()
  const [practitionerName, setPractitionerName] = useState(getValues('practitionerId'))

  const data = useReadQuery(assessedClaimsListQueryRef!)
  const claimsList = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.edges', [] as any).map(e => e.node)

  useEffect(() => {
    const selectedClaims = claimsList.filter(c => selectedClaimIds.includes(c.id))
    if (selectedClaims.length <= 0) {
      return
    }
    let name = selectedClaims[0].billingProfile.name + ' (' + practitionerDisplayName(selectedClaims[0].billingProfile.practitionerFirstName, selectedClaims[0].billingProfile.practitionerLastName) + ')'
    setPractitionerName(name)
  }, [claimsList, selectedClaimIds])


  const serviceDate = useWatch({
    control,
    name: 'serviceDate',
  })

  const serviceCode = useWatch({
    control,
    name: 'serviceCode',
  })

  const patient = useWatch({
    control,
    name: 'patient'
  })

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      gap={2}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
      >
        <AssessedMultipleValuesField
          label={'Practitioner'}
          multipleValuesState={batchUpdateMultiple.billingProfile}
          dataTestId={`${dataTestId}-billingProfile`}
        >
          <Refreshable
            label={'Practitioner'}
            name={'practitioner'}
            textValue={practitionerName}
            onRefreshDisplaySnackbar={(value) => {
              if (!value) {
                showSuccessAlert('Practitioner information will be updated when this claim is resubmitted.')
              }
              else {
                showSuccessAlert('Practitioner information will not be updated when this claim is resubmitted.')
              }
            }}
            dataTestId={`${dataTestId}-billingProfile-refreshable`}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Patient'}
          multipleValuesState={batchUpdateMultiple.patient}
          dataTestId={`${dataTestId}-patient`}
        >
          <PatientRefreshable
            name={'patient'}
            patient={patient}
            onRefreshDisplaySnackbar={(value) => {
              if (!value) {
                showSuccessAlert('Patient information will be updated when this claim is resubmitted.')
              }
              else {
                showSuccessAlert('Patient information will not be updated when this claim is resubmitted.')
              }
            }}
            sx={{ mb: 2 }}
            dataTestId={`${dataTestId}-patient-refreshable`}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Service date'}
          multipleValuesState={batchUpdateMultiple.serviceDate}
          dataTestId={`${dataTestId}-serviceDate`}
        >
          <BatchControlledDatePicker
            label={'Service date'}
            name={'serviceDate'}
            dataTestId={`${dataTestId}-serviceDate`}
          />
        </AssessedMultipleValuesField>
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={1}
          sx={{
            border: 1,
            borderRadius: '8px',
            color: 'greys.light',
            marginTop: '16px',
          }}
          >
          <AssessedMultipleValuesField
            label={'Service code'}
            multipleValuesState={batchUpdateMultiple.serviceCode}
            dataTestId={`${dataTestId}-serviceCode`}
          >
            <BatchControlledServiceCodeSearch
              label={'Service code'}
              name={'serviceCode'}
              practitionerId={practitionerId}
              serviceDate={serviceDate}
              sx={{ width: '100%' }}
              dataTestId={`${dataTestId}-serviceCode`}
            />
          </AssessedMultipleValuesField>
          {serviceCode &&
            serviceCodeHospitalAdmission.includes(
              serviceCode['serviceCode']
            ) && (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}
                justifyContent={'space-evenly'}
              >
                <FormattedDatePicker
                  label={'Hospital admission date'}
                  name={'hospitalAdmissionDate'}
                  sx={{ width: '100%' }}
                  dataTestId={`${dataTestId}-hospitalAdmissionDate`}
                />
              </Box>
            )}
            <AssessedMultipleValuesField
              label={'Diagnostic code'}
              multipleValuesState={batchUpdateMultiple.diagnosticCodes}
              dataTestId={`${dataTestId}-diagnosticCodes`}
            >
              <BatchControlledDiagnosticCodeInput
                label={'Diagnostic code'}
                name={'diagnosticCodes'}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
                sx={{ width: '100%' }}
                dataTestId={`${dataTestId}-diagnosticCodes`}
              />
            </AssessedMultipleValuesField>
          <AssessedMultipleValuesField
            label={'Fee modifiers'}
            multipleValuesState={batchUpdateMultiple.serviceCodeFeeModifiers}
            dataTestId={`${dataTestId}-feeModifiers`}
          >
            <BatchControlledServiceFeeModifierSelect
              serviceCodeId={serviceCode?.id}
              sx={{ my: 1, width: '100%' }}
              inputProps={{ maxLength: rules.feeModifiers.max }}
              batchUpdateMultiple={batchUpdateMultiple}
              dataTestId={`${dataTestId}-feeModifiers`}
            />
          </AssessedMultipleValuesField>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={2}
          >
            <BatchControlledOverflowTextField
              label={'Calls'}
              type={'number'}
              name={'calls'}
              field={'calls'}
              inputProps={{ maxLength: rules.calls.max }}
              sx={{ width: '112px' }}
              multipleValuesState={batchUpdateMultiple.calls}
              InputProps={register('calls', { valueAsNumber: true })}
              dataTestId={`${dataTestId}-calls`}
            />
            <BatchControlledOverflowTextField
              label={'Encounter'}
              type={'number'}
              name={'encounter'}
              field={'encounter'}
              inputProps={{ maxLength: rules.encounter.max }}
              sx={{ width: '112px' }}
              multipleValuesState={batchUpdateMultiple.encounter}
              InputProps={register('encounter', { valueAsNumber: true })}
              dataTestId={`${dataTestId}-encounter`}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
      >
        <AssessedMultipleValuesField
          label={'Facility'}
          multipleValuesState={batchUpdateMultiple.facility}
          dataTestId={`${dataTestId}-facility`}
        >
          <BatchControlledFacilityInput
            name={'facility'}
            label={'Facility'}
            practitionerId={practitionerId}
            serviceDate={serviceDate}
            dataTestId={`${dataTestId}-facility`}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Functional centre'}
          multipleValuesState={batchUpdateMultiple.functionalCenter}
          dataTestId={`${dataTestId}-functionalCenter`}
        >
          <BatchControlledFunctionalCenter
            name={'functionalCenter'}
            label={'Functional centre'}
            onClear={() => resetField('functionalCenter')}
            dataTestId={`${dataTestId}-functionalCenter`}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Location'}
          multipleValuesState={batchUpdateMultiple.locationCode}
          dataTestId={`${dataTestId}-locationCode`}
        >
          <BatchControlledLocationCodeSelect
            label={'Location'}
            name={'locationCode'}
            dataTestId={`${dataTestId}-locationCode`}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Referral practitioner'}
          multipleValuesState={batchUpdateMultiple.referralPractitioner}
          dataTestId={`${dataTestId}-referralPractitioner`}
        >
          <BatchControlledReferralPractitionerSearch
            label={'Referral practitioner'}
            name={'referralPractitioner'}
            dataTestId={`${dataTestId}-referralPractitioner`}
          />
        </AssessedMultipleValuesField>
        <BatchControlledOverflowTextField
          label={'Text'}
          type={'text'}
          name={'text'}
          field={'text'}
          multiline={true}
          multipleValuesState={batchUpdateMultiple.text}
          hideHelperText={true}
          fullWidth={true}
          inputProps={{ maxLength: rules.text.max, style: { 'height': '100%', 'overflow': 'auto'  } }}
          InputProps={{ sx: { mt: 1 }, style: { minHeight: '40px', flexGrow: 1, flexShrink: 1 } }}
          InputLabelProps={{ sx: { mt: 1 } }}
          sx={{ height: '100%', mt: 0, overflow: 'hidden' }}
          dataTestId={`${dataTestId}-text`}
        />
      </Box>
    </Box>
  )
}

export const BatchUpdateAssessedClaimBaseDetails = {
  Form: Form,
}
