import { Button, Section, Tabs, Typography, Form } from "saga-library/src";
import { PayToCode } from "../../../../../utils/ABClaimConstants";
import { Box, useTheme } from "@mui/material";
import { AbClaimMultiple, BatchClaimInputType } from '../../../../../types/billing';
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useReadQuery } from '@apollo/client'
import moment from "moment-timezone";
import { usePrompt } from "../../../../../providers/NavigationPrompt";
import { useAssessedClaimsContext } from '../../../providers/AssessedClaimsProvider'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { BatchUpdateAssessedBase } from './BatchUpdateAssessedBase'
import { BatchUpdateAssessedClaimOptions } from './BatchUpdateAssessedClaimOptions'
import { setAbClaimMultiple, setBatchAbClaimValues } from '../batchUpdateUtil'
import { useParams } from 'react-router-dom'

const FORM_NAME = "batch_update_assessed_details"

export const assessedClaimDefaults = {
  version: "0",
  billingProfile: null,
  facility: null,
  functionalCenter: null,
  referralPractitioner: null,
  serviceCode: null,
  diagnosticCodes: [],
  serviceCodeFeeModifiers: [],
  patient: null,
  locationCode: undefined,
  text: undefined,
  serviceDate: moment().format(),
  calls: 1,
  encounter: 1,
  paperDocumentation: false,
  confidential: false,
  originalEncounterDate: null,
  originalFacility: null,
  originalLocationCode: "",
  newBornCode: null,
  guardianUli: null,
  guardianRegNumber: "",
  skillId: undefined,
  payToCode: PayToCode.BAPY,
  payToUli: null,
  interceptReason: "",
  patientFirstName: "",
  patientLastName: "",
  patientPHN: "",
  patientDOB: null,
  patientGenderId: ""
}

export const BatchUpdateAssessedDetails = () => {
  const theme = useTheme()
  const { tenant_id } = useParams()
  const formRef = useRef<HTMLFormElement>(null)
  const { enableNavigationPrompt } = usePrompt()
  const { selectedClaimIds, assessedClaimsListQueryRef, batchResubmitClaims } = useAssessedClaimsContext()

  const data = useReadQuery(assessedClaimsListQueryRef!)
  let claimsList = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.edges', [] as any).map(e => e.node)
  let firstClaim = _find(claimsList, {id: selectedClaimIds[0]})
  let selectedClaims = claimsList.filter(c => selectedClaimIds.includes(c.id))

  let abClaimMultiple : AbClaimMultiple = setAbClaimMultiple(selectedClaims)


  const formMethods = useForm<BatchClaimInputType>({
    defaultValues: assessedClaimDefaults
  })
  const {
    getValues,
    formState: {dirtyFields, isSubmitSuccessful},
    setFocus,
    reset,
    setValue
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])


  const resubmit = async (data) => {
    // Cannot resubmit assessed claim with different practitioner or patient
    data.abClaimBatchUpdatedFields = {
      billingProfileId: false,
      calls: dirtyFields.calls || false,
      confidential: dirtyFields.confidential || false,
      diagnosticCodeIds: !!dirtyFields.diagnosticCodes,
      encounter: dirtyFields.encounter || false,
      facilityId: !!dirtyFields.facility,
      functionalCenterId: !!dirtyFields.functionalCenter,
      guardianUli: dirtyFields.guardianUli || false,
      interceptReason: dirtyFields.interceptReason || false,
      locationCode: dirtyFields.locationCode || false,
      newbornCode: dirtyFields.newBornCode || false,
      originalEncounterDate: dirtyFields.originalEncounterDate || false,
      originalFacilityId: dirtyFields.originalFacility || false,
      originalLocationCode: dirtyFields.originalLocationCode || false,
      paperDocumentation: dirtyFields.paperDocumentation || false,
      patientId: false,
      payToCode: dirtyFields.payToCode || false,
      payToUli: dirtyFields.payToUli || false,
      referralPractitionerId: !!dirtyFields.referralPractitioner,
      serviceCodeFeeModifierIds: !!dirtyFields.serviceCodeFeeModifiers,
      serviceCodeId: !!dirtyFields.serviceCode,
      serviceDate: !!dirtyFields.serviceDate,
      skillId: dirtyFields.skillId || false,
      text: dirtyFields.text || false,
      feeMod1Units: dirtyFields.feeMod1Units || false,
      feeMod2Units: dirtyFields.feeMod2Units || false,
      feeMod3Units: dirtyFields.feeMod3Units || false,
    }

    data.billingProfileId = data.billingProfile?.id || ''
    delete data.billingProfile

    data.facilityId = data.facility?.id || null
    delete data.facility

    data.functionalCenterId = data.functionalCenter?.id || null
    delete data.functionalCenter

    data.referralPractitionerId = data.referralPractitioner?.id || null
    delete data.referralPractitioner

    data.serviceCodeId = data.serviceCode?.id || ''
    delete data.serviceCode

    data.diagnosticCodeIds = data.diagnosticCodes?.map(dc => dc.id) || null
    delete data.diagnosticCodes

    data.serviceCodeFeeModifierIds = data.serviceCodeFeeModifiers?.map(fm => fm.value) || null
    delete data.serviceCodeFeeModifiers

    data.patientId = data.patient?.id || null
    delete data.patient

    data.originalFacilityId = data.originalFacility?.id || null
    delete data.originalFacility

    data.skillId = data.skillId?.value || null
    data.originalLocationCode = data.originalLocationCode || null
    data.interceptReason = data.interceptReason || null
    data.newBornCode = data.newBornCode || null
    data.payToUli = data.payToUli || null

    await batchResubmitClaims(data)
  }

  const shortcuts = useMemo(() => ({
    "d": () => {
      resubmit(getValues())
    },
    "f": () => {
      setFocus("facility")
    },
    "r": () => {
      setFocus("referralPractitioner")
    },
    "t": () => {
      setFocus("text")
    },
    "p": () => {
      setFocus("patient")
    },
    "e": () => {
      setFocus("encounter")
    }
  }), [setFocus])

  const handleKeyPress = useCallback((event) => {
    if (event.altKey && event.key in shortcuts){
      event.preventDefault()
      shortcuts[event.key]()
    }
  }, [shortcuts])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])

  useEffect(() => {
    setBatchAbClaimValues(abClaimMultiple, selectedClaims, setValue)
  }, [])

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
          <BatchUpdateAssessedBase
              batchUpdateMultiple={abClaimMultiple}
              claim={firstClaim}
              dataTestId={'batchUpdateAssessedClaims-base'}
          />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
          <BatchUpdateAssessedClaimOptions.Form
              batchUpdateMultiple={abClaimMultiple}
              dataTestId={'batchUpdateAssessedClaims-options'}
          />
      ),
    },
  ]

  if (selectedClaimIds.length === 0) {
    return null
  }

  const BatchUpdateHeader = ({ dataTestId }) => {
    return <>
      <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
      >
        <Section.Header>Updating {selectedClaimIds.length} {selectedClaimIds.length === 1? 'claim' : 'claims'}</Section.Header>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
          <Button
              name="cancel"
              variant={'outlined'}
              onClick={() => console.log("NYI")}
              dataTestId={`${dataTestId}-cancel-button`}
          >
            CANCEL
          </Button>
          <Button name="resubmit" onClick={() => resubmit(getValues())} dataTestId={`${dataTestId}-resubmit-button`}>
            RESUBMIT
          </Button>
        </Box>
      </Box>
      <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          color={theme.palette.warning.main}
          my={1}
      >
        <WarningAmberIcon sx={{ mr: 1 }}/>
        <Typography variant={'subtitle1'}>Only fields with values shared across all selected claims can be updated. New values will replace existing values in all claims.</Typography>
      </Box>
    </>
  }

  return (
      <FormProvider {...formMethods}>
        <BatchUpdateHeader dataTestId={'batchUpdateAssessedClaims'} />
        <Form onSubmit={resubmit} autoComplete={'false'} ref={formRef}>
          <Tabs options={options} sx={{ mb: 1 }} dataTestId={'batchUpdateAssessedClaims'} />
        </Form>
      </FormProvider>
  )
}