import {
  IconImageTooltip,
  TableList,
  TableListCellConfig,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from 'saga-library/src'
import React, { useMemo } from 'react'
import Widget from '../Widget'
import { SxProps } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useReadQuery } from '@apollo/client'
import { TaskAssignmentType } from '../../../../types/tasks/TaskAssignment'
import { TaskPriorityMap } from '../../../../types/tasks/TaskPriority'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import moment from 'moment-timezone'
import { TaskCategory } from '../../../../types/tasks/TaskState'
import { usePatientTaskContext } from '../../providers/PatientTaskProvider'
import { NewTaskModal } from '../../../inbox/components/Tasks/NewTaskModal'
import { usePatientProfileContext } from '../../providers/PatientProfileProvider'
import { UpdateTaskModal } from '../tasks/UpdateTaskModal'

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: '' },
  { name: '' },
  { name: '' }
]

export const TaskWidget = () => {
  const { profileData } = usePatientProfileContext()
  const [openModal, setOpenModal] = React.useState(false)
  const [task, setTask] = React.useState<TaskAssignmentType|undefined>(undefined)

  const handleAddItem = () => {
    setOpenModal(true)
  }

  const handleRowClicked = (task: TaskAssignmentType) => {
    setTask(task)
  }

  return (
    <Widget
      title={'Tasks'}
      route={'tasks'}
      WidgetIcon={TaskAltIcon}
      onAddItem={handleAddItem}
    >
      <TaskWidgetBody onRowClick={handleRowClicked} />
      <NewTaskModal
        open={openModal}
        setOpen={setOpenModal}
        fromChart={true}
        patient={profileData}
      />
      <UpdateTaskModal
        task={task}
        setTask={setTask}
      />
    </Widget>
  )
}


const TaskWidgetBody = ({ onRowClick }) => {
  const { parsePatientTaskQueryResults, patientTasksRef } = usePatientTaskContext()
  const { data } = useReadQuery(patientTasksRef)
  const tasks = useMemo(() => parsePatientTaskQueryResults(data), [data, parsePatientTaskQueryResults])

  return (
    <TableList
      dataTestId={'task-widget-table'}
      columns={columns}
      showHeader={false}
      rows={[
        ...tasks.map((task) => TaskRow(task, onRowClick))
      ]}
      sx={{ height: '100%', "&.MuiTableCell-root": { borderBottom: null } }}
      tableSx={{ tableLayout: 'fixed' }}
      emptyListComponent={{ message: "Click the add button to add a task.", size: 'sm', sx: { paddingTop: '1vh', textAlign: 'left' } }}
      isWidget={true}
    />
  )
}


function TaskRow(task: TaskAssignmentType, onRowClick) : TableListRowConfig {
  const rowData = [
    {
      children: <TaskDate {...task} />,
      sx: { boxSizing: 'border-box', width: '96px' }
    },
    {
      children: task.priority && (
        <IconImageTooltip title={TaskPriorityMap.get(task.priority)!.label}>
          {TaskPriorityMap.get(task.priority)!.icon}
        </IconImageTooltip>
      ),
      sx: { px: 0, width: '16px' }
    },
    {
      children: <Typography lineclamp={1} variant={'p2'}>{task.type.type}</Typography>,
      sx: { boxSizing: 'border-box', width: '96px' }
    },
    {
      children: <Typography lineclamp={1} variant={'p2'}>{task.name}</Typography>
    }
  ] as TableListCellConfig[]

  return {
    onClick: () => onRowClick(task),
    rowData,
    key: task.id
  }
}

export const TaskDate = ({ dueDate, state, variant ='p2' }: TaskAssignmentType) => {
  const taskDate = useFormattedDate(dueDate)

  const isOverdue = dueDate && moment(dueDate).isBefore(moment(), 'day')
  const isIncomplete = state.category === TaskCategory.INCOMPLETE
  let sx: SxProps | undefined
  if (isOverdue && isIncomplete) {
    sx = { color: 'error.main', backgroundColor: 'backgrounds.error' }
  }

  return <Typography variant={variant} sx={sx}>{taskDate}</Typography>
}

