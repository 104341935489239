export const nullOrNumber = (value) => {
  const num = (Number.isNaN(value) || value===null || value===undefined || !isFinite(value)) ? null : parseInt(value)
  return Number.isNaN(num) ? null : num
}

// only works for 0 - 99
export const toAlphabeticOrdinal = (value: number) => {
  const special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth']
  const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet']

  if (value < 20) {
    return special[value]
  }

  if (value % 10 === 0) {
    return deca[Math.floor(value / 10) - 2] + 'ieth'
  }

  return deca[Math.floor(value / 10) - 2] + 'y-' + special[value % 10];
}