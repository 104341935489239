import {Outlet, Route, useParams} from "react-router-dom";
import {useAccountContext} from "../providers/AccountContext";
import useTenantDataLoader from "../utils/useTenantDataLoader";
import {RootLayout, UserLayout} from "../components/Layouts";
import {MainAppBar} from "../components/MainAppBar";
import {PermissionDenied} from "./static/ErrorPages";
import {TenantContextProvider} from "../providers/TenantContextProvider";
import {LocationContextProvider} from "../providers/LocationContextProvider";
import {TaskProvider} from "../providers/TaskProvider";
import {EncounterNoteTemplateProvider} from "../providers/EncounterNoteTemplateProvider";
import {ReviewDocumentStateProvider} from "../apps/inbox/providers/ReviewDocumentStateProvider";
import {ReviewProvider} from "../apps/inbox/providers/ReviewProvider";
import {ReviewLabsProvider} from "../apps/inbox/providers/ReviewLabsProvider";
import React, {lazy} from "react";
import {ComponentSuspenseWrapper} from "../components/ComponentSuspenseWrapper";
import {BillingRoutes} from "../apps/billing/BillingRoutes";
import {Inbox} from "./Inbox";
import {AgendaContextProvider} from "../providers/AgendaContextProvider";
import {Dashboard} from "./Dashboard";


const Schedule = lazy(() => import('./Schedule'))
const Patients = lazy(() => import('../apps/patients/PatientRoutes'))
const Reports = lazy(() =>import('./ReportRoutes'))
const Settings = lazy(() =>import('./Settings'))


export const tenantRoutes = () => {
  return (
    <Route path="/t/:tenant_id" element={<TenantRouteBoundary/>}>
      <Route element={<TenantProviders/>} >
        <Route element={<UserLayout/>} >

          <Route path="patients//*" element={
            <ComponentSuspenseWrapper>
              <Patients />
            </ComponentSuspenseWrapper>
          } />

          <Route path="billing//*" element={<BillingRoutes />} />

          <Route path="reports//*" element={
            <ComponentSuspenseWrapper>
              <Reports />
            </ComponentSuspenseWrapper>
          } />

          <Route path="schedule//*" element={
            <ComponentSuspenseWrapper>
              <Schedule />
            </ComponentSuspenseWrapper>
          } />

          <Route path="settings//*" element={
            <ComponentSuspenseWrapper>
              <Settings />
            </ComponentSuspenseWrapper>
          } />

          <Route path="inbox//*" element={<Inbox />} />

          <Route index element={
            <AgendaContextProvider>
              <Dashboard />
            </AgendaContextProvider>
          } />

        </Route>
      </Route>
    </Route>
  )
}


const TenantRouteBoundary = () => {
  const { tenant_id } = useParams()
  const { userHasTenant } = useAccountContext()
  useTenantDataLoader()

  if (userHasTenant(tenant_id)) {
    return <><Outlet/></>
  }
  return (
    <RootLayout>
      <MainAppBar rightMenu displayName />
      <PermissionDenied />
    </RootLayout>
  )
}


const TenantProviders = () => {
  return (
    <TenantContextProvider>
      <LocationContextProvider>
        <TaskProvider>
          <EncounterNoteTemplateProvider>
            <ReviewDocumentStateProvider>
              <ReviewProvider>
                <ReviewLabsProvider>
                  <Outlet/>
                </ReviewLabsProvider>
              </ReviewProvider>
            </ReviewDocumentStateProvider>
          </EncounterNoteTemplateProvider>
        </TaskProvider>
      </LocationContextProvider>
    </TenantContextProvider>
  )
}