import { gql } from "@apollo/client";

export const GET_SMARTTYPE_TEXT_SHORTCUTS = gql`
  query GetSmartTypeTextShortcuts($tenantId: ID!) {
    tenant(id: $tenantId){
      smartType {
        listTextShortcuts {
          id
          shortcut
          replacementText
          limitToUser {
            id
            firstName
            lastName
          }
          version
        }
      }
    }
  }
`