import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Tabs, Form } from "saga-library/src";
import { ClaimBase } from '../ClaimBase'
import { ClaimOptions } from '../ClaimOptions'
import { useMutation, useLazyQuery } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { useAlerts } from "saga-library/src/providers/Alerts"
import { schema } from "../../util/baseClaimValidation"
import _get from "lodash/get";
import { ClaimInputType } from "../../../../types/billing";
import { setProblems } from "../../util/setProblems";
import { createClaimInputFromFormData } from "../../util/createClaimInputFromFormData";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { LoadingSpinner } from "../../../../components/LoadingScreen";
import {
  SAVE_CLAIM,
  LIST_UNSUBMITTEDCLAIMS,
  GET_PATIENT_LABEL
} from '../../../../graphql-definitions'
import { flushSync } from "react-dom";
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'
import { NavigationHeader_V2 } from 'saga-library/src/components/NavigationHeader/NavigationHeader'
import claimDefaults from "../../util/ClaimDefaults"
import { withMultipleSelectedHOC } from './UnsubmittedMultipleSelected'


const FORM_NAME = "new_claim_details"

export const NewUnsubmittedClaim = withMultipleSelectedHOC(() => {
  const {setCurrentPatientId, setRedirectToNewlyCreatedClaim} = useUnsubmittedClaimsContext()

  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const [saveClaim, { loading }] = useMutation(
    SAVE_CLAIM,
    {
      onCompleted: (data)=>{
        const problems = _get(data, 'tenant.abClaim.createAbClaim.problems', null)
        setProblems(problems, setError)
        if(problems && problems.length > 0){
          flushSync(() => {
            clearNavigationPrompt(FORM_NAME)
          })
          showWarningAlert("Claim has been saved but contains errors. It cannot be submitted until all errors are resolved.")
          setRedirectToNewlyCreatedClaim(true)
          navigate(`/t/${tenant_id}/billing/c/${data.tenant.abClaim.createAbClaim.id}`)
        } else {
          showSuccessAlert('Claim has been created.')
        }
        if (isRepeating) {
          setIsRepeating(false)
          const currentValues = getValues()
          currentValues.patient = null
          reset(currentValues)
          patientInputRef.current && patientInputRef.current.focus()
        } else {
          reset(claimDefaults)
          practitionerInputRef.current && practitionerInputRef.current.focus()
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        if (error?.graphQLErrors?.length > 0 && error?.graphQLErrors[0]?.extensions?.userError === true){
          showErrorAlert(error.graphQLErrors[0].message)
        } else {
          showErrorAlert('Claim couldn\'t be saved.')
        }
      },
    }
  )

  const { showErrorAlert, showWarningAlert, showSuccessAlert } = useAlerts()
  const [isRepeating, setIsRepeating] = useState(false)
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()

  const practitionerInputRef = useRef<HTMLInputElement>()
  const patientInputRef = useRef<HTMLInputElement>()

  const formRef = useRef<HTMLFormElement>(null)

  const formMethods = useForm<ClaimInputType>({
    defaultValues: claimDefaults,
    schema: schema
  })
  const {
    getValues,
    handleSubmit,
    formState: { dirtyFields, isSubmitSuccessful, isSubmitting },
    resetField,
    reset,
    watch,
    setError,
    setFocus,
    setValue
  } = formMethods

  const [ getPatientData, { loading: loadingPatientData}] = useLazyQuery(GET_PATIENT_LABEL, {
    onCompleted: data => {
      setValue('patient', data.tenant.patient.label, { shouldDirty: true})
      setValue('serviceDate', queryParams.get("serviceDate"), { shouldDirty: true })
      const billingProfileId = queryParams.get("bpId")
      if(billingProfileId) {
        setValue('billingProfile', { id:billingProfileId }, { shouldDirty: true, shouldValidate: true })
        setFocus("serviceCode")
      }
    }
  })

  useEffect(()=>{
    if(location.search){
      const patientId = queryParams.get("pId")

      if(patientId){
        getPatientData({
          variables:{
            tenantId: tenant_id,
            patientId: queryParams.get("pId")
          }
        })
      }
    }
  }, [location.search])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  const onSubmit = handleSubmit(async(data) => {
    if (loading) {
      return
    }
    let claimData = createClaimInputFromFormData(data)
    await saveClaim({
      variables: {
        tenantId: tenant_id,
        claimData: claimData,
      },
      update(cache, returnedData) {
        const data = cache.readQuery({
          query: LIST_UNSUBMITTEDCLAIMS,
          variables: { tenantId: tenant_id }
        })

        const currentUnsubmittedAbClaims = _get(data, 'tenant.abClaim.listUnsubmittedAbClaim', [])
        const newClaim = _get(returnedData, 'data.tenant.abClaim.createAbClaim')
        let list = [newClaim, ...currentUnsubmittedAbClaims]

        cache.writeQuery({
          query: LIST_UNSUBMITTEDCLAIMS,
          data: {
            tenant: {
              abClaim: {
                listUnsubmittedAbClaim: list
              },
            },
          },
          variables: { tenantId: tenant_id }
        })
      }
    })
  })

  const save = () => {
    setIsRepeating(false)
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  const saveAndRepeat = () => {
    setIsRepeating(true)
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  const shortcuts = useMemo(() => ({
    "d": () => {
      save()
    },
    "Enter": () => {
      saveAndRepeat()
    },
    "l": () => {
      saveAndRepeat()
    },
    "f": () => {
      setFocus("facility")
    },
    "r": () => {
      setFocus("referralPractitioner")
    },
    "t": () => {
      setFocus("text")
    },
    "p": () => {
      setFocus("patient")
    },
    "e": () => {
      setFocus("encounter")
    }
  }), [setFocus])

  const handleKeyPress = useCallback((event) => {
    if (event.altKey && event.key in shortcuts){
      event.preventDefault()
      shortcuts[event.key]()
    }
  }, [shortcuts])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      // Reset fee modifier input on SC change.
      if (name === "serviceCode" && type === "change") {
        resetField("serviceCodeFeeModifiers")
        resetField("feeMod1Units")
        resetField("feeMod2Units")
        resetField("feeMod3Units")
      }
      // Update patient for patient claims list
      let patientId = data?.patient?.id || ''
      setCurrentPatientId(patientId)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  // To properly clear the patient list when opening a new claim
  useEffect(() => {
    setCurrentPatientId('')
  }, [])

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <ClaimBase isAssessed={false} dataTestId={'newUnsubmittedClaim-base'} />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <ClaimOptions.Form dataTestId={'newUnsubmittedClaim-options'} />
      ),
    },
  ]

  const secondaryAction = <PermissionButton
    name="saveAndRepeat"
    variant={'outlined'}
    onClick={saveAndRepeat}
    requiredType={PermissionType.Billing}
    requiredPermission={Permission.READWRITE}
    loading={isSubmitting && isRepeating}
    disabled={isSubmitting && !isRepeating}
    dataTestId={"newUnsubmittedClaim-saveAndRepeat-button"}
  >
    SAVE AND REPEAT
  </PermissionButton>

  const primaryAction = <PermissionButton
    name="save"
    type={'submit'}
    onClick={save}
    requiredType={PermissionType.Billing}
    requiredPermission={Permission.READWRITE}
    loading={isSubmitting && !isRepeating}
    disabled={isSubmitting && isRepeating}
    dataTestId={"newUnsubmittedClaim-save-button"}
  >
    SAVE
  </PermissionButton>

  return (
    <FormProvider {...formMethods}>
      <NavigationHeader_V2
        title={'New claim'}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        dataTestId={'newUnsubmittedClaim'}
      />

      <Box display={'flex'} flexDirection={'column'} position={'relative'}>
        {loadingPatientData && <LoadingSpinner overlay={true}/>}

        <Form onSubmit={onSubmit} autoComplete={'off'} ref={formRef}>

          <Tabs options={options} sx={{ mb: 1 }} dataTestId={'newUnsubmittedClaim'} />
        </Form>
      </Box>
    </FormProvider>
  )
})





