import React from 'react'
import { Typography } from 'saga-library/src'
import { Box, Paper, SxProps, Theme } from '@mui/material'

type DashboardCardType = {
  label: number | string
  labelColor?: string
  description: string
  icon?: React.ReactNode
  onClick?: () => void
  sx?: SxProps<Theme>
  dataTestId: string
  children?: React.ReactNode
}

export const DashboardCard = ({
  label,
  labelColor,
  description,
  icon,
  onClick,
  sx = {},
  dataTestId,
  children
}: DashboardCardType) => {
  return(
    <Paper
      elevation={0}
      onClick={onClick}
      sx={{
        backgroundColor: 'tertiary.main',
        borderRadius: '8px',
        p: 1,
        flexGrow: 1,
        flexBasis: '100%',
        cursor: !!onClick ? 'pointer' : '',
        '&:hover': {
          backgroundColor: !!onClick ? 'backgrounds.hover' : ''
        },
        ...sx
      }}
      data-testid={`${dataTestId}-dashboardCard`}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'subtitle1'} color={labelColor} dataTestId={`${dataTestId}-text`}>{label}</Typography>
          <Typography color={'greys.medium'}>{description}</Typography>
          {children}
        </Box>
        {icon}
      </Box>
    </Paper>
  )
}