import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_LETTERS } from '../../../../../graphql-definitions/tenant/patient/LetterQueries'
import { parseLetterQueryResults } from '../../../../patients/providers/LetterProvider'
import { EmptyList } from 'saga-library/src'
import { LetterType } from '../../../../../types/patients'
import React from 'react'
import { DateListItem, ItemType, ListBox } from '../LinkItem'
import { LinkType } from '../../../../../types/tasks/TaskAssignment'

export const LetterList = ({patientId, onSelect}) => {
  const {tenant_id} = useParams()

  const {data} = useSuspenseQuery(GET_PATIENT_LETTERS ,{
    variables:{
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const letters = parseLetterQueryResults(data)

  if(letters.length > 0) {
    return (
      <ListBox>
        {letters.map( l => <LetterItem item={l} onClick={onSelect} key={`link_patient_letter_${l.isLinkedDocument ? "doc_"+l.id : "letter_"+l.id}`}/>)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{p:1, width:"200px"}} message={ `No associated forms`} />
  )
}

export const LetterItem = ({item, onClick, icon, ...itemTypeProps}:ItemType) => {
  const letter = item as LetterType

  return (
    <DateListItem
      date={letter.letterDate}
      label={letter.title}
      subLabel={letter.notes}
      onClick={onClick ? (e)=>onClick(e, {...letter, linkType:LinkType.LETTER}) : undefined}
      icon={icon}
      {...itemTypeProps}
    />
  )
}