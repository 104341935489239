import React from "react";
import Box from "@mui/material/Box";
import { NavigationHeader, NavigationTabs } from "saga-library/src";
import { NavigationTabRoute } from "../../components/NavigationTabRoute";
import { Outlet, useLocation } from "react-router-dom";
import { HasPermissionTemplate } from "../../components/HasPermissionTemplate";
import { Permission, PermissionType } from "../../types/settings/Permission";
import SubmitClaimsButton from "./components/SubmitClaimsButton";

export const BillingTabs = () => {
  const location = useLocation()

  const navRoutes = [
    <NavigationTabRoute
      route={''}
      label={'UNSUBMITTED'}
      applyToChildren={!location.pathname.includes('assessed')}
      dataTestId={'billing-unsubmitted'}
    />,
    <NavigationTabRoute
      route={'assessed'}
      label={'ASSESSED'}
      applyToChildren={true}
      dataTestId={'billing-assessed'}
    />,
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        height: "100%"
      }}
    >
      <HasPermissionTemplate requiredType={PermissionType.Billing} requiredPermission={Permission.READONLY} showAccessDenied={true}>
        <NavigationHeader
          title={'Alberta claims'}
          sx={{ mr: 1, width: 'auto' }}
          children={<NavigationTabs routes={navRoutes} sx={{flex:"1 1 auto"}}/>}
          actions={<SubmitClaimsButton />}
        />
        <Outlet />
      </HasPermissionTemplate>
    </Box>
  )
}
