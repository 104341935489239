import React from 'react'
import { TableListRowConfig } from 'saga-library/src'
import { Box } from '@mui/material'
import { AbClaimProblemSeverity } from "../../../../utils/ABClaimConstants";
import { ErrorIcon, HoldIcon, ReadyIcon } from "../ClaimStatusIcons";
import { AbClaim } from '../../../../types/billing';
import { Typography } from "saga-library/src";
import { NameAndIdentifier } from "saga-library/src/components/TableList/CellBlocks/NameAndIdentifier";
import { patientDisplayName } from "saga-library/src/util";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import moment from 'moment-timezone'


export const UnsubmittedClaimRow = (
  claim: AbClaim,
  dateFormat: string,
  onRowClick: (claim: AbClaim) => void
): TableListRowConfig => {
  const statusIcons: Record<string, React.ReactNode> = {
    READY: <ReadyIcon />,
    HOLD: <HoldIcon />,
    ERROR: <ErrorIcon />,
  }

  const renderStatusIcon = (claim) => {
    if (claim.problems?.some(p => p.severity === AbClaimProblemSeverity.ERROR)) {
      return statusIcons['ERROR']
    }
    return statusIcons[claim.claimStatus]
  }

  const claimRow = [
    {
      children: (
        <Box display={'flex'}>
          {renderStatusIcon(claim)}
        </Box>
      ),
    },
    {
      children: (
        <NameAndIdentifier
          name={patientDisplayName(claim.patient?.firstName!, claim.patient?.lastName!)}
          identifier={claim.patient?.primaryIdentifier}
        />
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{moment(claim.serviceDate).format(dateFormat)}</Typography>
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{claim.serviceCode?.serviceCode}</Typography>
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{claim.calls}</Typography>
      )
    },
    {
      children: (
        <Typography variant={'body2'}>{claim.diagnosticCodes?.map((dc) => {
          return dc?.diagnosticCode
        }).join(', ')}</Typography>
      )
    },
    {
      children:(
        <NameAndIdentifier
          name={practitionerDisplayName(claim.billingProfile?.practitionerFirstName, claim.billingProfile?.practitionerLastName)}
          identifier={claim.billingProfile?.name}
        />
      )
    },
    {
      children: (
        <NameAndIdentifier
          name={`${claim.facility?.description}`}
          identifier={claim.functionalCenter?.functionalCenterCode}
        />
      )
    },
    {
      children: (
        <Typography variant={'body2'} align={'right'} >{claim.claimAmount != null? `$${claim.claimAmount.toFixed(2)}` : ''}</Typography>
      )
    }
  ]

  return {
    onClick: () => { onRowClick(claim) },
    rowData: claimRow,
    key: claim.id
  }
}
