import React, { useEffect } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities';
import { useSagaAuthentication } from './Auth'
import { useConfigurationContext } from './Configuration'
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { useConcurrencyHandler } from "./ConcurrencyConflictProvider";
import { getVersionedEntity } from "../utils/findVersionedObject";
import { print } from 'graphql'
import { createClient, ClientOptions, Client } from 'graphql-sse'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';


const createApolloClient = ({ getConfigValue, clientName, showConcurrencyError }) => {
  const sseLink = new SSELink({
    url: getConfigValue('SAGA_GRAPHQL_ENDPOINT'),
    credentials: 'include',
    singleConnection: false
  })


  const httpLink = createHttpLink({
    uri: getConfigValue('SAGA_GRAPHQL_ENDPOINT'),
    credentials: 'include'
  })


  const splitLink = ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
      },
      sseLink,
      httpLink
  )


  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => {
        console.warn("[Apollo] Retrying " + _operation.operationName + " due to error:")
        console.warn(error)
        return true
      }
    }
  });


  const networkErrorLink = onError(({ networkError, operation, forward }) => {
    if (networkError) {
      // This is where we would do whatever we want to happen after all automatic retries fail.
      // For now, 'nothing' is the current behavior.
    }
    return forward(operation)
  })


  const gqlErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err?.extensions?.code) {

          case "UNAUTHENTICATED":
            // eslint-disable-next-line no-restricted-globals
            location.reload()
            return

          case "FORBIDDEN":
            // eslint-disable-next-line no-restricted-globals
            location.reload()
            return

          case "CONCURRENCY_ERROR":
            // @ts-ignore
            const user: string = String(concurrencyError.extensions.username)
            // @ts-ignore
            const version: string = String(concurrencyError.extensions.version)
            return new Observable(observer => {
              showConcurrencyError(user, version)
                  .then(value => {
                    if (value) {
                      const versionedEntity = getVersionedEntity(operation.variables)
                      versionedEntity.version = version

                      return forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                      })
                    }
                    observer.error(value)
                  })
            })
        }
      }
    }
    return forward(operation)
  })


  const removeTypenameLink = removeTypenameFromVariables()


  const removeAudit = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitAudit = (key, value) => (key === 'audit' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitAudit)
    }
    return forward(operation).map((data) => {
      return data
    })
  })


  const removeLinkedDocument = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitLinkedDocument = (key, value) => (key === 'isLinkedDocument' || key === 'linkedDocument' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitLinkedDocument)
    }
    return forward(operation).map((data) => {
      return data
    })
  })


  const versionMerge = (existing, incoming, { readField, mergeObjects }) => {
    if (existing) {
      const existingVersion = readField("version", existing);
      const incomingVersion = readField("version", incoming);
      const existingRef = readField("__ref", existing);
      const incomingRef = readField("__ref", incoming);
      if (existingRef && incomingRef && existingRef === incomingRef &&
        existingVersion && incomingVersion && incomingVersion <= existingVersion) {
        return existing;
      }
    }
    return mergeObjects(existing, incoming);
  }


  return new ApolloClient({
    devtools: {
      enabled: process.env.NODE_ENV === 'development',
      name: clientName,
    },
    name: clientName,
    link: from([networkErrorLink, retryLink, gqlErrorLink, removeTypenameLink, removeAudit, removeLinkedDocument, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        EncounterNoteTemplateQueries: { merge: true },

        Province: {
          keyFields: ["code"],
        },
        StaticQueries: { merge: true },
        UserQueries: { merge: true },
        TenantQueries: { merge: true },
        RoleQueries: { merge: true },
        ABClaimQueries: { merge: true },
        PractitionerQueries: { merge: true },
        SearchEngine: { merge: true },
        PatientSearchQueries: { merge: true },
        ScheduleQueries: { merge: true },
        AppointmentQueries: { merge: true },
        AppointmentStateQueries: { merge: true },
        AppointmentTypeQueries: { merge: true },
        AppointmentRoomQueries: { merge: true },
        AppointmentRoom: { merge: versionMerge },
        Appointment: { merge: versionMerge },
        ScheduleEvent: { merge: versionMerge },
        BookingPreference: { merge: versionMerge },
        Letter: {
          keyFields: ["id", "__typename", "isLinkedDocument"]
        },
        Form: {
          keyFields: ["id", "__typename", "isLinkedDocument"]
        },
        AbLabResult: {
          keyFields: ["id", "__typename", "isLinkedDocument"]
        },
        EncounterNote: {
          keyFields: ["id", "__typename", "isLinkedDocument"],
          fields: {
            fields: {
              merge: false
            }
          }
        },
        PatientProfile: {
          fields: {
            notes: {
              merge: false,
            },
          }
        },
        PatientRelationships: {
          fields: {
            familyRelationships: {
              merge: false,
            },
            referralPractitioners: {
              merge: false,
            },
            familyPractitioners: {
              merge: false,
            },
            clinicPractitioners: {
              merge: false,
            },
            pharmacies: {
              merge: false,
            }
          }
        },
        PatientQueries: { merge: true },
        PatientTimelineEvent: {
          keyFields: ["id", "__typename", "type"]
        },
        EncounterNoteQueries: { merge: true },
        PrescriptionQueries: { merge: true },
        LabAndInvestigationQueries: { merge: true },
        Report: {
          keyFields: ["id", "__typename", "isReference"]
        },

        SocialHistory: {
          keyFields: ["patientId", "__typename"]
        },
        TaskQueries: { merge: true },
        ReviewQueries: { merge: true },
        ReviewDocumentQueries: { merge: true },
        ReviewLabQueries: { merge: true },
      },
      possibleTypes: {
        Setting: ["IntSetting", "BoolSetting", "StringSetting", "IdSetting"],
        ScheduleItem: ["Appointment", "ScheduleEvent", "BookingPreference", "TemplateBookingPreference", "TemplateEvent"]
      },
    })
  })
}

interface ApolloClientContextInterface {
  mainClient: any
  patientClient: any
}

const defaultApolloClientContext: ApolloClientContextInterface = {
  mainClient: {} as any,
  patientClient: { } as any
}

const ApolloClientContext = React.createContext(defaultApolloClientContext)

export const ApolloClientProvider = ({ children }) => {

  const { getConfigValue } = useConfigurationContext()
  const { showConcurrencyError } = useConcurrencyHandler()

  const mainClient = React.useRef(null as any)
  const patientClient = React.useRef(null as any)

  if (mainClient.current == null) {
    mainClient.current = createApolloClient({
      getConfigValue,
      clientName: "main",
      showConcurrencyError
    })
  }

  if (patientClient.current == null) {
    patientClient.current = createApolloClient({
      getConfigValue,
      clientName: "patient",
      showConcurrencyError
    })
  }

  const values = {
    mainClient: mainClient.current,
    patientClient: patientClient.current
  }

  return (
    <ApolloClientContext.Provider value={values} >
      { children }
    </ApolloClientContext.Provider>
  )
}


export const useApolloClientContext = () => {
  return React.useContext(ApolloClientContext)
}


export const Apollo = ({ children, client }) => {
  const authenticated = useSagaAuthentication()

  useEffect(() => {
    if (!authenticated) {
      ; (async () => {
        //clear Apollo cache when user logs off
        await client.resetStore()
      })()
    }
  }, [authenticated, client])

  return (
    <ApolloProvider client={client} >
      { children }
    </ApolloProvider>
  )
}




class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public dispose() {
    this.client.dispose();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          // @ts-ignore
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}