import React from 'react'
import { Autocomplete } from 'saga-library/src'
import { SxProps, Theme } from '@mui/material'
import { useQuery } from '@apollo/client'
import { LIST_TASK_TEMPLATES } from '../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import { TaskTemplate } from '../types/tasks/TaskTemplate'
import { IdEqual } from 'saga-library/src/components/SearchControl/ControlledSearchControl'

interface TaskTemplateSelectProps {
  inputRef?: React.Ref<any>,
  label: string,
  name: string,
  disabled?: boolean,
  autoFocus?: boolean,
  onChange?: (value) => void,
  dataTestId: string,
  value?: TaskTemplate,
  sx?: SxProps<Theme>
}

export const TaskTemplateSelect = ({
  inputRef,
  label = 'Type',
  name,
  disabled = false,
  autoFocus = false,
  onChange,
  value,
  dataTestId,
  sx
}: TaskTemplateSelectProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()

  const { loading, data } = useQuery(LIST_TASK_TEMPLATES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onError: (error) => {
      showErrorAlert('Unable to retrieve task templates.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  return (
    <Autocomplete
      inputRef={inputRef}
      name={name}
      label={label}
      options={_get(data, "tenant.task.template.list", [])}
      loading={loading}
      onChange={(e, newValue) => {
        if (onChange) {
          onChange(newValue ?? null)
        }
      }}
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={IdEqual}
      value={value}
      sx={{ flex: '1 1 100%', ...sx }}
      disabled={disabled}
      autoFocus={autoFocus}
      onInputChange={(e, value, reason) => {
        if (reason === 'clear') {
          if (onChange) {
            onChange(null)
          }
        }
      }}
      dataTestId={dataTestId}
    />
  )
}