import React, { useCallback, useEffect, useState } from 'react'
import { Agenda, Section, Typography } from 'saga-library/src'
import { useTenantContext } from '../../../../providers/TenantContextProvider'
import { PractitionerDropdown } from '../../../../components/PractitionerDropdown'
import { useQuery } from '@apollo/client'
import { GET_AGENDA_ITEMS } from '../../../../graphql-definitions'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { AgendaAppointment } from './AgendaAppointment'
import { AgendaEvent } from './AgendaEvent'
import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/react'
import { AppointmentStateCategory } from '../../../../types/schedule/AppointmentState'
import { Box, Divider } from '@mui/material'
import { useAgendaContext } from '../../../../providers/AgendaContextProvider'

export const AppointmentsDashboard = ({ practitionerId = "*" }: { practitionerId: string }) => {
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])
  const {
    loading: loadingSchedules,
    schedules,
    rooms,
    scheduleIds,
    setScheduleIds
  } = useAgendaContext()
  const { tenant_id } = useParams()

  const [date, setDate] = useState<string|Date|number>(new Date().setHours(0, 0))
  const [selectedPractitionerId, setSelectedPractitionerId] = useState<string>(practitionerId)
  const [events, setEvents] = useState<any[]>([])

  const { loading: loadingScheduleItems, data: scheduleItemsData } = useQuery(GET_AGENDA_ITEMS, {
    variables: {
      tenantId: tenant_id,
      scheduleIdList: scheduleIds,
      date: moment(date).utc().toISOString(),
    },
    fetchPolicy: 'cache-and-network',
    skip: scheduleIds.length < 1
  })

  useEffect(() => {
    if (!selectedPractitionerId) {
      return
    }
    if (selectedPractitionerId === "*") {
      setScheduleIds(schedules.map((schedule) => schedule.id))
    } else {
      setScheduleIds(schedules.filter((schedule) => schedule.practitionerId === selectedPractitionerId).map((schedule) => schedule.id))
    }
  }, [schedules, selectedPractitionerId])

  const updateScheduleItems = useCallback((queryData) => {
    const scheduleItems = _get(queryData, 'tenant.dashboard.agendaItems', [])

    const availableScheduleIds = scheduleItems.filter((scheduleItem) => scheduleItem.value.length > 0).map((scheduleItem) => scheduleItem.key)
    const availableLocationIds = schedules.filter((schedule) => availableScheduleIds.includes(schedule.id)).map((schedule) => schedule.locationId)
    const hasMultipleLocations = Array.from(new Set(availableLocationIds)).length > 1

    const scheduleItemsWithLocations = scheduleItems
      .map(scheduleItem => {
        if (hasMultipleLocations) {
          const matchedLocation = schedules.find((schedule) => schedule.id === scheduleItem.key)?.location?.name
          return scheduleItem.value.map((v) => ({ ...v, locationName: matchedLocation }))
        }
        return scheduleItem.value
      })
      .flat()

    const filterEvents = scheduleItemsWithLocations.filter((event) => event.__typename === 'ScheduleEvent' || event.__typename === 'Appointment')

    const events = filterEvents.map((event) => {
      if (event.state?.category === AppointmentStateCategory.INPROGRESS) {
        const matchedRoom = rooms.find((room) => room.appointment?.id === event.id)
        if (matchedRoom) {
          return {
            ...event,
            room: matchedRoom
          }
        }
      }
      return event
    })

    setEvents(events)
  }, [date, timezone, rooms, schedules])

  useEffect(() => {
    if (scheduleItemsData) {
      updateScheduleItems(scheduleItemsData)
    }
  }, [scheduleItemsData, updateScheduleItems])

  const loading = loadingSchedules || loadingScheduleItems

  return (
    <Section.Container sx={{ maxWidth: '1259px', flexBasis: '100%' }}>
      <Section.Column width={'100%'} dataTestId={'appointmentsDashboard-column'}>
        <Agenda
          headerActions={
            <PractitionerDropdown
              selectedPractitionerId={selectedPractitionerId}
              onChange={setSelectedPractitionerId}
              size={'large'}
              dataTestId={'appointmentsDashboard-practitioner-dropdown'}
            />
          }
          timezone={timezone as string}
          date={date}
          events={events}
          onSelectedDateChanged={(event) => setDate(event.date)}
          renderEvent={AppointmentsAgendaEvent}
          loading={loading}
          paddingRight={1}
        />
        {!loading && <AppointmentsAgendaFooter events={events} />}
      </Section.Column>
    </Section.Container>
  )
}

const AppointmentsAgendaEvent = (data: MbscCalendarEventData) => {
  const eventData = data.original
  switch (eventData?.__typename) {
    case "Appointment":
      return <AgendaAppointment key={eventData?.id} event={eventData} dataTestId={'agenda-appointment'} />
    case "ScheduleEvent":
      return <AgendaEvent key={eventData?.id} event={eventData} />
    default:
      return <></>
  }
}

const AppointmentsAgendaFooter = ({ events }: { events: MbscCalendarEvent[] }) => {
  if (events.length === 0) {
    return <></>
  }

  const appointments = events.filter((event) => event.__typename === 'Appointment')
  const scheduleEvents = events.filter((event) => event.__typename === 'ScheduleEvent')

  return (
    <Box pr={1}>
      <Divider />
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={4} pt={1}>
        <Box display={"flex"}>
          <Typography variant={"h5"}>{appointments.length}</Typography>
          <Typography>&nbsp;appointment{appointments.length !== 1 ? "s" : ""}</Typography>
          {appointments.length > 0 && (
            <Typography color={(theme) => theme.palette.greys.medium}>
              <Box component={"span"}>&nbsp;(</Box>
              <AppointmentStateCount appointments={appointments} category={AppointmentStateCategory.PREVISIT} label={"before appointment"} />
              <AppointmentStateCount appointments={appointments} category={AppointmentStateCategory.INPROGRESS} label={"during appointment"} />
              <AppointmentStateCount appointments={appointments} category={AppointmentStateCategory.POSTVISIT} label={"after appointment"} />
              <AppointmentStateCount appointments={appointments} category={AppointmentStateCategory.DONE} label={"done"} appendSeparator={false} />
              <Box component={"span"}>)</Box>
            </Typography>
          )}
        </Box>
        <Box display={"flex"}>
          <Typography variant={"h5"}>{scheduleEvents.length}</Typography>
          <Typography>&nbsp;event{scheduleEvents.length !== 1 ? "s" : ""}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const AppointmentStateCount = ({ appointments, category, label, appendSeparator = true }: {
  appointments: MbscCalendarEvent[],
  category: AppointmentStateCategory,
  label: string,
  appendSeparator?: boolean
}) => {
  return (
    <>
      <Box component={"span"} fontWeight={"bold"}>{appointments.filter((appointment) => appointment.state.category === category).length}</Box>
      <Box component={"span"}>&nbsp;{label}</Box>
      {appendSeparator && <Box component={"span"}>,&nbsp;</Box>}
    </>
  )
}