import React from 'react'
import { Box } from '@mui/material'
import { Checkbox, TextField } from 'saga-library/src'
import { EncounterNoteFieldProps } from '../../../../../types/patients/EncounterNote'

export const EncounterNoteCheckboxWithTextField = ({
  name,
  label,
  isSigned,
  disabled,
  dataTestId
}: EncounterNoteFieldProps & { label: string }) => {
  return (
    <Box display={'flex'} alignItems={'center'} my={'12px'}>
      <Box flexShrink={0} width={'288px'}>
        <Checkbox
          label={label}
          name={`${name}.selected`}
          disabled={isSigned || disabled}
          formControlLabelSx={{ ml: 0 }}
          dataTestId={`${dataTestId}-checkbox`}
        />
      </Box>
      <TextField
        label={''}
        name={`${name}.text`}
        dataTestId={`${dataTestId}-text-area`}
        hideHelperText={true}
        fullWidth={true}
        disabled={disabled}
        InputProps={{
          readOnly: isSigned
        }}
        sx={{
          mt: 0
        }}
      />
    </Box>
  )
}