import React from "react";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { PatientLabel } from "saga-client/src/components/SearchControls/Patient/PatientLabel";
import { PatientSearchResult } from "saga-client/src/types/patients";
import { SxProps, Theme } from '@mui/material'
import { LoadingSpinner } from '../../LoadingScreen'

export interface PatientRefreshableProps {
  name: string
  patient: PatientSearchResult
  onRefreshDisplaySnackbar: (v) => void
  sx?: SxProps<Theme>
  dataTestId: string
}

export const PatientRefreshable = ({
  name,
  patient,
  onRefreshDisplaySnackbar,
  sx,
  dataTestId
} : PatientRefreshableProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value} }) => {
        if (!patient) {
          return <LoadingSpinner size={'sm'} label={null} />
        }
        return (
          <PatientLabel
            patient={patient}
            name={name}
            disabled={true}
            variant={'sm'}
            refresh={() => {
              onRefreshDisplaySnackbar(value)
              value = !value
              onChange()
            }}
            sx={sx}
            dataTestId={dataTestId}
          />
        )
      }}
    />
  )
}

export default PatientRefreshable