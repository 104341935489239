import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing";
import { ReferralPractitionerSearch } from "../../../../../components/SearchControls/Practitioner/ReferralPractitionerSearch";

interface BatchControlledReferralPractitionerSearchProps {
  name: string
  label: string
  dataTestId: string
}

export const BatchControlledReferralPractitionerSearch = ({
  name,
  label,
  dataTestId
}: BatchControlledReferralPractitionerSearchProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <ReferralPractitionerSearch
      label={label}
      name={name}
      sx={batchUpdateStyleSearch(dirtyFields.referralPractitioner) as any}
      dataTestId={dataTestId}
    />
  )
}