import MenuItem from '@mui/material/MenuItem'
import { RemoveButton, Typography } from 'saga-library/src'
import { ConditionAndProcedureType } from '../../../../types/ConditionAndProcedure'
import { Box, ListItemIcon, MenuItemProps } from '@mui/material'
import { AllergyType } from '../../../../types/Allergy'
import React from 'react'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import { PrescriptionType } from '../../../../types/Prescription'
import { styled } from "@mui/system"
import { AbLabResult, EncounterNote, FormType, LetterType } from '../../../../types/patients'
import { LinkedDocumentType } from '../../../../types/Document'
import { TaskAssignmentType } from '../../../../types/tasks/TaskAssignment'
import { string } from 'yup'
import { DeletedListItem } from '../Tasks/DeletedListItem'

export interface ItemType extends Omit<ListMenuItemProps, 'onClick'> {
  onClick?: (e, v) => void
  icon?: React.ReactNode
  item: ConditionAndProcedureType | AllergyType | AbLabResult | EncounterNote | LetterType | PrescriptionType | LinkedDocumentType | TaskAssignmentType | FormType
}

interface DateListItemProps extends ListMenuItemProps{
  date: Date
  label: string
  subLabel?: string
}

export const DateListItem = ({date, label, subLabel="", onClick, icon, ...itemTypeProps}: DateListItemProps) => {
  return(
    <ListMenuItem
      onClick={onClick}
      icon={icon}
      {...itemTypeProps}
    >
      <ListMenuItemDate date={date} />
      <Box display={'flex'} sx={{ flexDirection:"column", lineHeight: "16px", flex:"1 1 100%"}} gap={0}>
        <Typography lineclamp={1}>
          {label}
        </Typography>
        {subLabel && <Typography lineclamp={1} variant={'p2'} >
          {subLabel}
        </Typography>}
      </Box>
    </ListMenuItem>
  )
}

export const ListBox = styled(Box)`
  width: 360px;
  display: flex;
  flex-direction: column;
`

interface ListMenuItemProps extends MenuItemProps {
  icon?: React.ReactNode
  onClick?: (e:React.MouseEvent)=>void
  onDelete?: ()=>void
  onRestore?: ()=>void
  dataTestId?: string
  isDeleted?: boolean
}

export const ListMenuItem = ({onClick, onDelete, onRestore, isDeleted, children, sx, icon, dataTestId, ...MenuItemProps}: ListMenuItemProps) => {

  if(isDeleted){
    return (
      <DeletedListItem dataTestId={dataTestId} onRestore={onRestore} />
    )
  }

  return(
    <MenuItem
      sx={{
        height:40,
        gap:1,
        "&:hover": {
          "& .list-menu-actions":{
            display: "block"
          }
        },
        ...sx
      }}
      data-testid={`${dataTestId}_link_item`}
      onClick={onClick}
      {...MenuItemProps}
    >
      {icon &&
        <ListItemIcon
          sx={{minWidth:"auto !important"}}
        >
          <Box sx={theme => ({
            height:"40px",
            width:"40px",
            borderRadius:"20px",
            backgroundColor: theme.palette.greys.extraLight,
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
          })}>
            { icon }
          </Box>
        </ListItemIcon>
      }
      {children}
      <Box
        className={'list-menu-actions'}
        sx={{display:"none"}}
      >
        {onDelete && <RemoveButton
          onClick={onDelete}
          dataTestId={`${dataTestId}_delete_linked_item`}
          sx={{height: 24, width: 24, flex:"0 0 auto"}}
        />}
      </Box>
    </MenuItem>
  )
}

interface ListMenuItemDateProps {
  date: Date | undefined
  variant?: 'body1' | 'body2'
  color?: string
}

export const ListMenuItemDate = ({date, variant="body1", color="inherit"}:ListMenuItemDateProps) => {
  const formattedDate = useFormattedDate(date)

  return (
    <Box sx={{flex:"0 0 85px"}}>
      <Typography
        variant={variant}
        color={color}
      >
        {formattedDate}
      </Typography>
    </Box>
  )
}