import React, { useCallback } from 'react'
import { LinkedDocumentType } from "../../../../types/Document";
import { useReviewDocumentStateContext } from '../../providers/ReviewDocumentStateProvider'
import { DocumentViewer } from '../../../../components/StandaloneDocumentViewer'
import { Form } from 'saga-library/src'

interface DocumentResultFormProps {
  document?: LinkedDocumentType
  onSubmit: () => void
  formName: string
}
export const DocumentResultForm = ({
  document,
  formName,
  onSubmit,
}: DocumentResultFormProps) => {
  const documentStateContext  = useReviewDocumentStateContext()

  const View = useCallback(() => {
    if (document?.file) {
      return (
        <DocumentViewer
          fileRecord={document.file}
          documentStateContext={documentStateContext}
        />
      )
    }

    return <></>
  }, [document])

  return (
    <Form
      id={formName}
      name={formName}
      onSubmit={onSubmit}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}
    >
      <View />
    </Form>
  )
}
