import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { TaskForm } from './TaskForm'
import { taskDefaults } from './TaskDefaultValues'
import { schema } from './TaskValidationsSchema'
import { DialogV2 } from 'saga-library/src'
import { TaskAssignmentInput } from '../../../../types/tasks/TaskAssignment'
import { useTaskContext } from '../../../../providers/TaskProvider'
import { matchPath, useLocation } from 'react-router-dom'
import { usePatientTaskContext } from '../../../patients/providers/PatientTaskProvider'

const FORM_NAME = 'new_task_modal_form'

type NewTaskModalProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  fromChart?: boolean,
  patient?: any,
  linkedItem?: any[]
  markAsReviewedMethod?: () => Promise<void>
}

export const NewTaskModal = (
  {
    open,
    setOpen,
    fromChart = false,
    patient = undefined,
    linkedItem = undefined,
    markAsReviewedMethod = undefined
  }: NewTaskModalProps) => {
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { createTask } = useTaskContext()
  const { createTaskFromChart } = usePatientTaskContext()

  // Grab the params from matchPath instead of useParams since the params will be null where this modal is called
  const { pathname } = useLocation()
  const match =
    matchPath({ path: '/t/:tenant_id/inbox/u/:user_id', end: false }, pathname) ??
    matchPath({ path: '/t/:tenant_id/inbox/r/:role_id', end: false }, pathname)

  const formMethods = useForm<TaskAssignmentInput>({
    defaultValues: taskDefaults,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting, isValid},
    reset,
    setValue,
    trigger,
  } = formMethods

  const onModalClose = () => {
    setOpen(false)
    reset(taskDefaults)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onModalClose()
      }
    }
  }

  useEffect(() => {
    if (!patient) return
    setValue('patient', patient)
  }, [patient, open])

  useEffect(() => {
    if (!linkedItem) return
    setValue('linkedItems', linkedItem)
  }, [linkedItem, open])

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt])

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onModalClose()
    }
  }

  const onCreateComplete = () => {
    enableNavigationPrompt(false, FORM_NAME)
    onModalClose()
  }

  const onSave = handleSubmit(async (data) => {
    const params = match && match.params
    const updateParams: { tenant_id?: string, user_id?: string, role_id?: string } = {
      tenant_id: params?.tenant_id,
      user_id: params?.['user_id'],
      role_id: params?.['role_id']
    }

    if (!!fromChart) {
      await createTaskFromChart(data, onCreateComplete)
      return
    }

    await createTask(data, onCreateComplete, params ? updateParams : undefined)
  })

  const secondaryAction = async () => {
    await trigger().then(async () => {
      if(!isValid) return
      await onSave().then(async () => await markAsReviewedMethod?.())
    })
  }

  return (
    <DialogV2
      title={'New Task'}
      open={open}
      onClose={onCancel}
      formName={FORM_NAME}
      submitting={isSubmitting}
      secondaryLabel={'Save and mark as reviewed'}
      secondaryAction={markAsReviewedMethod ? secondaryAction : undefined}
      sx={{maxHeight:"940px"}}
    >
      <FormProvider {...formMethods}>
        <TaskForm
          formName={FORM_NAME}
          onSubmit={onSave}
          canClearPatient={!patient}
          fetchDetails={true}
          autoFocus={true}
          dataTestId={'task-editor-modal-form'}
        />
      </FormProvider>
    </DialogV2>
  )
}