import React from 'react'
import { TableList, TableListHeaderConfig, TableListRowConfig } from "saga-library/src";
import { AssessmentStatusBadge } from "./AssessedClaims/AssessedClaimRow";

const HistoryTable = ({ history }) => {
  const ClaimHistoryColumns: TableListHeaderConfig[] = [
    { name: 'Claim' },
    { name: 'PHN' },
    { name: 'Action' },
    { name: 'Outcome' },
    { name: 'Date Paid' },
    { name: 'Claimed' },
    { name: 'Assessed' },
    { name: 'Expl.' }
  ]

  return <TableList
      columns = { ClaimHistoryColumns }
      rows={history.map(claimHistory => HistoryRow(claimHistory))}
      dataTestId={'claimHistory'}
  />
}

const HistoryRow = (claim): TableListRowConfig => {
  return {
    key: claim.id,
    rowData: [
      {
        data: claim.claimIdentifier
      },
      {
        data: claim.claimPatientPHN
      },
      {
        data: "ACTION"
      },
      {
        data: claim.assessmentOutcome,
        children: (
          <AssessmentStatusBadge
            claim={claim}
          />
        )
      },
      {
        data: claim.expectedDatePaid || ""
      },
      {
        data: claim.claimAmount
      },
      {
        data: claim.assessmentAmount || ""
      },
      {
        data:getExplanatoryCodes(claim.explanatoryCodes)
      }
    ]
  }
}

const getExplanatoryCodes = (explanatoryCodes) => {
  if(explanatoryCodes){
    return `${explanatoryCodes.map((dc) => {
      return dc?.explanatoryCode
    })}`
  }

  return ""
}

export const ClaimHistory = {
  HistoryTable,
}
