import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Typography, TenantSelectionItem } from 'saga-library/src'
import { useAccountContext } from '../providers/AccountContext'
import { useNavigate } from 'react-router-dom'
import { LoadingScreen } from '../components/LoadingScreen'
import { getScheduleLocationStorageKey, getTenantLocationStorageKey, getTenantStorageKey } from '../config/LocalStorageKeys'
import { Paper } from "@mui/material";

export const Home = () => {
  const { tenants, buildTenantRoute, userHasTenant, userId, setLocationId } = useAccountContext()
  const navigate = useNavigate()

  useEffect(() => {
    const tenantKey = getTenantStorageKey(userId)
    const locationKey = getTenantLocationStorageKey(userId)
    const storedTenantId = localStorage.getItem(tenantKey)
    const storedLocationId = localStorage.getItem(locationKey)
    if(storedTenantId) {
      if(userHasTenant(storedTenantId)) {
        setLocationId(storedLocationId)
        navigate(buildTenantRoute('', storedTenantId))
      }
      else {
        localStorage.removeItem(tenantKey)
      }
    }
  }, [tenants])

  if (tenants.length === 0) {
    return (
      <Box padding={4}>
        <Typography variant={'h2'}>Saga Health</Typography>
        <Typography variant={'body1'}>
          Your account is not associated to an active Saga Health subscription.
        </Typography>
      </Box>
    )
  }

  if (tenants.length > 0) {
    return (
      <Paper sx={{
        height: 'fit-content',
        margin: '0 auto',
        marginTop: 12,
        boxShadow: '4px 4px 25px 3px rgba(0,0,0,0.25)'
      }}>
        <Box padding={4}>
          <div>
            <Typography variant={'h2'} style={{marginBottom: '16px'}}>
              Select a location
            </Typography>
            <Typography variant={'body1'}>
              You have access to the following locations:
            </Typography>
            <Box maxWidth={'400px'}>
              {tenants.map((tenant, index) => (
                <Box key={`${tenant.id}-tenant-root-box`}>
                  <Typography
                    variant={'h3'}
                    sx={ theme => ({
                      m: theme.spacing(1, 0, 0),
                      p: 1,
                      pb: 0.
                    })}
                  >
                    {tenant.name ? tenant.name : tenant.id}
                    <Box
                      key={`${tenant.id}-tenant-box`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                      }}
                    >
                      {
                        tenant?.locations?.map((location, index) => {
                          return (
                            <TenantSelectionItem
                              key={location.id}
                              id={location.id}
                              tenant={tenant as any}
                              display={location.name}
                              isActive={false}
                              onClick={() => {
                                if (!!tenant.id) {
                                  localStorage.setItem(getTenantStorageKey(userId), tenant.id)
                                }
                                if (!!location.id) {
                                  setLocationId(location.id)
                                  localStorage.setItem(getTenantLocationStorageKey(userId), location.id)
                                  localStorage.setItem(getScheduleLocationStorageKey(userId), location.id)
                                }
                                navigate(buildTenantRoute('', tenant.id))
                              }}
                              dataTestId={`tenantSelection-item-${index}`}
                            />
                          )
                        })
                      }
                    </Box>
                  </Typography>
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </Paper>
    )
  }

  return <LoadingScreen message={'One moment'} />
}
