import React from 'react'
import { Section, Typography, SimpleCheckbox, useForm } from 'saga-library/src'
import { PermissionType } from "../../../../../types/settings/Permission";
import { FormFragment } from "../../../../../components/PermissionForm";
import { Box, Tooltip } from "@mui/material";
import { useFieldArray, useFormContext } from "saga-library/src/components/Form";
import { ScheduleType } from '../../../../../types/schedule'
import { PractitionerInput, PractitionerLocation } from '../../../../../types/settings/Practitioner'
import {
  BillingProfileOption,
  ControlledBillingProfileSelect
} from '../../../../../components/BillingProfileSelect/BillingProfileSelect'
import { FormProvider } from 'react-hook-form'
import { Location } from '../../../../../types'
import { useLocationContext } from '../../../../../providers/LocationContextProvider'

const defaults = {
  scheduleLocationIds: [],
  locations: [],
  billingProfiles: []
}

const Form = () => {
  const PERMISSION_REQUIRED = PermissionType.Practitioner

  return (
    <Section.Container>
      <Section.ColumnNoElevation>
        <FormFragment requiredPermissionType={PERMISSION_REQUIRED} readOnlyOverride={true}>
          <Section.FormSection>
            <PractitionerSchedules />
          </Section.FormSection>
        </FormFragment>
      </Section.ColumnNoElevation>
    </Section.Container>
  )
}

export const PractitionerSchedules = ({disabled = false}) => {
  const { locations } = useLocationContext()
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "scheduleLocationIds",
    name: "schedules",
  })

  const { fields: locationFields, append: locationAppend, remove: locationRemove} = useFieldArray({
    control,
    name: "locations",
  })

  const { fields: billingProfileFields, append: billingProfileAppend, remove: billingProfileRemove } = useFieldArray({
    control,
    name: "billingProfiles",
  })

  const formMethods = useForm<PractitionerInput>({
    defaultValues: defaults,
  })

  const handleBillingProfileChange = (billingProfile: BillingProfileOption, location: Location) => {
    if (!location) return

    if(!billingProfile) {
      const existingLocationIndex = locationFields.findIndex(f => (f as any).locationId === location.id)
      if(existingLocationIndex === -1) return
      const locationClone = locationFields[existingLocationIndex] as any
      locationClone.defaultBillingProfile = null
      locationRemove(existingLocationIndex)
      locationAppend(locationClone)
    }

    let existingBillingProfile = billingProfileFields.find(f => (f as any)?.locationId === location.id)
    if(!!existingBillingProfile) {
      let index = billingProfileFields.findIndex(f => (f as unknown as DefaultBillingProfileType).billingProfileId === (existingBillingProfile as unknown as DefaultBillingProfileType)?.billingProfileId)
      billingProfileRemove(index)
    }

    billingProfileAppend({
      locationId: location.id,
      billingProfileId: billingProfile ? billingProfile.id : null
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Box>
        {
          locations?.map(location => {
            const existingSchedule = fields.find(f => (f as unknown as ScheduleType).locationId === location.id) as ScheduleType | undefined
            const existingLocation = locationFields.find(f => (f as any).locationId === location.id)
            const existingBillingProfile = (existingLocation as any)?.defaultBillingProfile
            return (
              <Box
                key={location.id}
                marginBottom={2}
                maxWidth={'500px'}
              >
                <Typography variant={'h3'}>{location.name}</Typography>
                <Box
                  padding={1}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <SimpleCheckbox
                    size={'small'}
                    label={'This practitioner works at this location.'}
                    value={!!existingLocation}
                    onChange={() => {
                      if (existingLocation) {
                        locationRemove(locationFields.findIndex(f => (f as any).locationId === (existingLocation as unknown as LocationType).locationId))
                        billingProfileRemove(billingProfileFields.findIndex(f => (f as unknown as DefaultBillingProfileType).locationId === existingLocation.id))
                      } else {
                        locationAppend({
                          locationId: location.id
                        })
                      }
                    }}
                    dataTestId={`practitionerSchedulesDetails-location-checkBox-${location.name}`}
                  />
                  <Tooltip
                    title={existingSchedule?.hasFutureAppointments ? 'This schedule contains future appointments and cannot be removed.' : ''}>
                    <SimpleCheckbox
                      size={'small'}
                      label={'This practitioner has a schedule at this location.'}
                      value={!!existingSchedule}
                      onChange={() => {
                        if (existingSchedule) {
                          remove(fields.findIndex(f => (f as unknown as ScheduleType).locationId === existingSchedule.locationId))
                        } else {
                          append({
                            locationId: location.id,
                            hasFutureAppointments: false
                          })
                        }
                      }}
                      disabled={existingSchedule?.hasFutureAppointments || disabled}
                      dataTestId={`practitionerSchedulesDetails-schedule-checkBox-${location.id}`}
                    />
                  </Tooltip>
                </Box>
                <Typography variant={'h5'}>{'Default billing profile'}</Typography>
                <Typography
                  variant={'p2'}>{'This billing profile will be automatically used for new claims created at this location.'}</Typography>
                <Box>
                  <ControlledBillingProfileSelect
                    disabled={!existingLocation}
                    label={'Linked billing profile'}
                    name={`billingProfile-${location.id}`}
                    onSelect={(billingProfile) => {
                      handleBillingProfileChange(billingProfile, location)
                    }}
                    autoFocus={false}
                    defaultValue={existingBillingProfile?.id}
                    dataTestId={`practitionerSchedulesDetails-${location.id}`}
                  />
                </Box>
              </Box>
            )
          })
        }
      </Box>
    </FormProvider>
  )
}

export type DefaultBillingProfileType = {
  billingProfileId: string,
  locationId?: string
}

type LocationType = {
  locationId: string,
}

export type PractitionerSchedulesDetailsType = {
  schedules?: ScheduleType[]
  locations: PractitionerLocation[]
  billingProfiles?: DefaultBillingProfileType[]
}

export const PractitionerSchedulesDetails = {
  Form: Form,
  defaults: defaults
}