import {useSagaAuthentication} from "../providers/Auth";
import {Navigate, Outlet, Route, useLocation} from "react-router-dom";
import {DestinationBeforeRedirect} from "../types";
import React from "react";
import {SignIn} from "./SignIn";
import {SupportSignIn} from "./SupportSignIn";
import {ForgotPassword} from "./ForgotPassword";


export const publicOnlyRoutes = () => {
  return (
    <Route element={<PublicOnlyRouteBoundary/>}>
      <Route path="signin" element={<SignIn />} />
      <Route path="support-signin" element={<SupportSignIn />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </Route>
  )
}


const PublicOnlyRouteBoundary = () => {
  const { isAuthenticated } = useSagaAuthentication()
  const location = useLocation()

  const savedDestination = (location.state as DestinationBeforeRedirect)?.from

  if (!isAuthenticated){
    return <Outlet/>
  } else {
    return <Navigate to={savedDestination ?? "/home"} replace />
  }
}

