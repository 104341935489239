import React, { useEffect, useState } from "react";
import { Section, Typography } from 'saga-library/src'
import { Box, SxProps, useTheme } from '@mui/material'
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { useQuery } from "@apollo/client";
import { GET_AB_LAB_RESULT_DASHBOARD_COUNTS } from "../../../../graphql-definitions";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../../../components/LoadingScreen";
import moment, { Moment } from "moment";
import Banner from "saga-library/src/components/Banner/Banner";
import { LabsAndInvestigationsDashboardExceptionModal } from "./LabsAndInvestigationsDashboardExceptionModal";
import { AbLabResultDashboardDto } from '../../../../types/patients'
import { DashboardCard } from '../DashboardCard'

const headerText = "Labs and investigations"
const viewExceptionsButtonText = "VIEW EXCEPTIONS"
const warningText = "Results haven't been processed in over 24 hours. Contact Saga Health technical support to investigate."
const exceptionText = "result(s) are not linked to a patient and/or practitioner."
const lastCheckText = "Last check at"
const nextCheckText = "Next check at"
const totalProcessedTodayText = "Total processed today"
const lastProcessedText = "Last processed"
const timeFormat = "h:mm A"

export const LabsAndInvestigationsDashboardException = ({ sx = {} }: { sx?: SxProps }) => {
  const theme = useTheme();
  const { tenant_id } = useParams();

  const [lastProcessedDateColor, setLastProcessedDateColor] = useState(theme.typography.subtitle1.color);

  const startDateTime = moment().startOf('day')
  const endDateTime = moment().endOf('day')

  const [processedCount, setProcessedCount] = useState(0);
  const [exceptionCount, setExceptionCount] = useState(0);
  const [showWarningText, setShowWarningText] = useState(false);

  const [lastProcessedDate, setLastProcessedDate] = useState<Moment | null>(null);
  const [lastCheckTime, setLastCheckTime] = useState<string | null>(null);
  const [nextCheckTime, setNextCheckTime] = useState<string | null>(null);

  const formattedLastProcessedDate =  useFormattedDate(lastProcessedDate, true, true)

  const [isModalOpen, setIsModalOpen] = useState(false);

  const countResults = (result : AbLabResultDashboardDto) => {
    setProcessedCount(result.processedCount)
    setExceptionCount(result.exceptionCount)
  }

  const setDates = (result : AbLabResultDashboardDto) => {
    setLastProcessedDate(result.lastProcessed)
    setLastCheckTime(moment(result.lastCheck).format(timeFormat))
    setNextCheckTime(moment(result.nextCheck).format(timeFormat))
  }

  useEffect(() => {
    const yesterdayDate = moment().subtract(24, 'hours');
    if(yesterdayDate.isSameOrAfter(moment(lastProcessedDate)))
    {
      setLastProcessedDateColor(theme.palette.error.main)
      setShowWarningText(true)
    }
  }, [lastProcessedDate, theme.palette.error.main]);

  const {
    loading: initialLoading,
    refetch
  } = useQuery(GET_AB_LAB_RESULT_DASHBOARD_COUNTS, {
    variables: {
      tenantId: tenant_id,
      startDate: startDateTime,
      endDate: endDateTime
    },
    onCompleted: (data) => {
      const result = _get(data, 'tenant.dashboard.abLabResultCounts', null)
      countResults(result)
      setDates(result)
    },
    onError: (error) => {
      setShowWarningText(true)
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const handleClose = async () => {
    const { data } = await refetch();
    const result = _get(data, 'tenant.dashboard.abLabResultCounts', null);
    countResults(result);
    setDates(result);
  }

  const FlexRowBox = ({children, sx}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', ...sx}}>
        {children}
      </Box>
    )
  }

  const customMessage = () => {
    return(
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={0.5}
      >
        <Typography
          variant="h5"
          color={theme.palette.primary.main}
          paddingRight={0}
        >
          {exceptionCount}
        </Typography>
        <Typography variant="body1" color={theme.palette.primary.main}>{exceptionText}</Typography>
      </Box>
    )
  }

  const handleViewExceptionsClick = () => {
    if(!isModalOpen){
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <Section.Column
        rightPadding={1}
        height={'fit-content'}
        sx={sx}
        dataTestId={'labsAndInvestigationsDashboardException-column'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Section.SubHeader variant={"h3"} sx={{ mb: 1 }}>
            {headerText}
          </Section.SubHeader>
          {initialLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {showWarningText &&
                <Banner
                  messageText={warningText}
                  dataTestId={'warning-banner'}
                  Icon={InfoOutlinedIcon}
                  sx={{ borderRadius: 2 }}
                  backgroundColor={theme.palette.error.main}
                  textColor={theme.palette.common.white}
                />
              }
              {(exceptionCount > 0) &&
                <Banner
                  message={customMessage()}
                  dataTestId={'exception-banner'}
                  Icon={InfoOutlinedIcon}
                  actionTextColor={theme.palette.primary.main}
                  sx={{ borderRadius: 2 }}
                  actionText={viewExceptionsButtonText}
                  action={handleViewExceptionsClick}
                />
              }
              <FlexRowBox sx={{ gap: 1 }}>
                <DashboardCard
                  label={processedCount}
                  description={totalProcessedTodayText}
                  dataTestId={'processedCount-text'}
                />
                <DashboardCard
                  label={formattedLastProcessedDate}
                  labelColor={lastProcessedDateColor}
                  description={lastProcessedText}
                  dataTestId={'lastProcessedDate'}
                />
              </FlexRowBox>
              <FlexRowBox sx={{ pt: 1 }}>
                <FlexRowBox sx={{ pr: 2 }}>
                  <AutorenewIcon fontSize={'small'} sx={{ color: theme.palette.greys.medium}}/>
                  <Typography variant={'body1'} color={theme.palette.greys.medium} dataTestId={'lastCheck-text'}>
                    {lastCheckText + " " + lastCheckTime}
                  </Typography>
                </FlexRowBox>
                <FlexRowBox sx={{ pr: 2 }}>
                  <AutorenewIcon fontSize={'small'} sx={{ color: theme.palette.greys.medium}}/>
                  <Typography variant={'body1'} color={theme.palette.greys.medium} dataTestId={'nextCheck-text'}>
                    {nextCheckText + " " + nextCheckTime}
                  </Typography>
                </FlexRowBox>
              </FlexRowBox>
            </>
          )}
        </Box>
      </Section.Column>
        <LabsAndInvestigationsDashboardExceptionModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          onClose={handleClose}
        />
    </>
  )
}