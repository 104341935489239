import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { PatientLookup } from "../../../../../components/PatientLookup/PatientLookup";

interface BatchControlledPHNSearchProps {
  label: string,
  name: string,
  dataTestId: string
}

export const BatchControlledPHNSearch = ({
  label,
  name,
  dataTestId
}: BatchControlledPHNSearchProps) => {
  const { formState: { dirtyFields } } = useFormContext();
  return (
    <PatientLookup
      name={name}
      label={label}
      sx={batchUpdateStyleSearch(dirtyFields.patient) as any}
      patientLabelSx={{ mb: 2 }}
      dataTestId={dataTestId}
    />
  )
}