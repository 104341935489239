import React, { Suspense, useEffect, useState } from 'react'
import { FormSection } from './components/Tasks/FormSection'
import { useReviewContext } from './providers/ReviewProvider'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { DocumentsList } from './components/Documents/DocumentsList'
import { DocumentsHeader } from './components/Documents/DocumentsHeader'
import { EmptyList, Section } from 'saga-library/src'
import { LoadingSpinner } from '../../components/LoadingScreen'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { DocumentCategory } from '../../types/Document'
import { DocumentsMultipleSelected } from './components/Documents/DocumentsMultipleSelected'
import { useAccountContext } from '../../providers/AccountContext'

interface DocumentsProps {
  dataTestId?: string
}

export const Documents = ({
  dataTestId = 'inbox'
}: DocumentsProps) => {
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { user_id, tenant_id } = useParams()
  const { loadUserDocuments, userDocumentsRef } = useReviewContext()

  const [nextDocumentId, setNextDocumentId] = useState<string | undefined>(undefined)
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([])
  const [clearSelectedDocuments, setClearSelectedDocuments] = useState<boolean>(false)

  const [ filters, setFilters ] = useState<{reviewed: string, category: DocumentCategory | string}>({
    category: '*',
    reviewed: 'unreviewed',
  })

  useEffect(() => {
    if(!tenant_id || !user_id) return

    loadUserDocuments({
      tenantId: tenant_id,
      userId: user_id,
    })
  }, [user_id, tenant_id])

  useEffect(() => {
    setClearSelectedDocuments(true)
  }, [filters])

  const navigateToNextDocument = () => {
    if (!!nextDocumentId) {
      navigate(buildTenantRoute(`inbox/u/${user_id}/documents/d/${nextDocumentId}`, tenant_id))
    } else {
      navigate(buildTenantRoute(`inbox/u/${user_id}/documents`, tenant_id))
    }
  }

  const onSuccess = () => {
    setClearSelectedDocuments(true)
    navigateToNextDocument()
  }

  const view = () => {
    if (selectedDocuments.length > 0 && filters.reviewed === 'unreviewed') {
      return (
        <DocumentsMultipleSelected
          onSuccess={() => onSuccess()}
          selectedDocuments={selectedDocuments}
          clearSelected={() => setClearSelectedDocuments(true)}
        />
      )
    }

    return <Outlet />
  }

  return (
    <>
      <Section.Column
        width={'50%'}
        sx={{ pr: 1 }}
        header={
          <DocumentsHeader
            setFilters={setFilters}
            dataTestId={dataTestId}
          />
        }
      >
        <Suspense fallback={<LoadingSpinner />}>
          { userDocumentsRef && (
              <DocumentsList
                setSelectedDocuments={setSelectedDocuments}
                clearSelectedDocuments={clearSelectedDocuments}
                setClearSelectedDocuments={setClearSelectedDocuments}
                setNextDocumentId={setNextDocumentId}
                filters={filters}
                dataTestId={dataTestId}
              />
            )
          }
        </Suspense>
      </Section.Column>
      <FormSection>
        {view()}
      </FormSection>
    </>
  )
}


export const EmptyDocumentSelection = () => {
  return (
    <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
      <EmptyList icon={DescriptionOutlinedIcon} message={'Select a document to view it.'} />
    </Section.Column>
  )
}
