import { Outlet } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import React, { Suspense } from 'react'
import { Section } from 'saga-library/src'
import { UnsubmittedClaimList } from './components/UnsubmittedClaims/UnsubmittedClaimList'
import { PatientClaimsList } from './components/PatientClaimsList'
import { LoadingSpinner } from '../../components/LoadingScreen'


export const UnsubmittedClaimsLayout = () => {
  const theme = useTheme()

  return (
    <Section.Container
      sx={ theme => ({
        width: '100%',
        mr: theme.spacing(2)
      })}
    >
      <Section.Column
        width={'40%'}
        sx={{
          flex: "0 0 40%",
          minWidth: "650px",
          maxWidth: "730px",
          mt: 0,
        }}
        rightPadding={1}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Section.Column>

      <Box
        sx={{
          height: 'auto',
          flex: '1 1 auto',
          minWidth: '880px',
          minHeight: '632px',
          overflow: "hidden",
          display:"flex",
          flexDirection: "column",
          mr: 1,
          pb: "3px"
        }}
      >
        <Section.Column
          width={'auto'}
          sx={{
            flex:"1 1 auto",
            minHeight: '65%',
            maxHeight: '65%',
            m: theme.spacing(0,0,1)
          }}
          rightPadding={1}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <UnsubmittedClaimList dataTestId={'unsubmittedClaimList'} />
          </Suspense>
        </Section.Column>
        <Section.Column
          width={'auto'}
          height={"60%"}
          sx={{
            overflowY:"auto",
            mr: 0,
          }}
          rightPadding={1}
        >
          <PatientClaimsList />
        </Section.Column>
      </Box>
    </Section.Container>
  )
}

