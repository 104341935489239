import React from 'react'
import { Box, DialogContentText } from '@mui/material'
import { DialogV2, Typography } from 'saga-library/src'
import { TenantSelectionItem } from '../TenantSelectionItem'

interface TenantSelectionDialogProps {
  tenants: any[]
  activeTenant: string | null
  open: boolean
  onClose: () => void
  selectionHandler: (tenantId: string | null, locationId: string | undefined) => void
  dataTestId: string
}

export const TenantSelectionDialog = ({
  tenants,
  activeTenant,
  open,
  onClose,
  selectionHandler,
  dataTestId,
}: TenantSelectionDialogProps) => {

  const onItemClick = (tenantId: string | null, locationId: string | undefined) => {
    onClose()
    selectionHandler(tenantId, locationId)
  }

  return (
    <DialogV2
      open={open}
      onClose={onClose}
      size={"xs"}
      title={"Select a location"}
      subtitle={'You have access to the following locations:'}
      subtitleVariant={'body1'}
      showActions={false}
      dataTestId={dataTestId}
    >
      <Box>
        {tenants.map((tenant, index) => (
          <Box key={`${tenant.id}-tenant-root-box`}>
            <Typography
              variant={'h3'}
              sx={ theme => ({
                m: theme.spacing(1, 0, 0),
                p: 1,
                pb: 0.
              })}
            >
              {tenant?.name ? tenant.name : tenant.id}
              <Box
                key={`${tenant?.id}-tenant-box`}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                {
                  tenant?.locations?.map((location, index) => {
                    return (
                      <TenantSelectionItem
                        key={location.id}
                        id={location.id}
                        tenant={tenant}
                        display={location.name}
                        isActive={activeTenant === location.id}
                        onClick={() => onItemClick(tenant?.id, location.id)}
                        dataTestId={`${dataTestId}-item-${index}`}
                      />
                    )
                  })
                }
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>
      <DialogContentText
        sx={{
          textAlign: 'center',
          fontSize: '14px',
          color: 'greys.medium',
          mt: 2,
          mb: 4
        }}
      >
        If a clinic is missing from this list, contact that clinic.
      </DialogContentText>
    </DialogV2>
  )
}

export default TenantSelectionDialog
