import React from 'react'
import { Banner, Section, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { DashboardCard } from '../DashboardCard'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useQuery } from '@apollo/client'
import { GET_CLAIM_COUNTS } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { ABClaimAssessmentOutcome } from '../../../../utils/ABClaimConstants'
import { AbClaimCounts } from '../../../../types/billing'
import { LoadingSpinner } from '../../../../components/LoadingScreen'

export const AbClaimsDashboard = ({ practitionerId }: { practitionerId?: string }) => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()

  const { loading, data } = useQuery(GET_CLAIM_COUNTS, {
    variables: { tenantId: tenant_id, practitionerId: practitionerId },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const claimCounts: AbClaimCounts = _get(data, 'tenant.abClaim.claimCounts', {})

  return (
    <Section.Column rightPadding={1} height={'fit-content'} dataTestId={'abClaimsDashboard-column'}>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Section.SubHeader variant={'h3'} sx={{ mb: 1 }}>Alberta claims</Section.SubHeader>
        {loading
          ? <LoadingSpinner />
          : <>
              {claimCounts.unsubmittedOverEightyDays > 0 && (
                <Banner
                  message={<Typography color={'primary.main'}>There are unsubmitted claims older than 80 days.</Typography>}
                  Icon={InfoOutlinedIcon}
                  actionTextColor={'primary.main'}
                  actionText={'View claims'}
                  action={() => navigate(buildTenantRoute('billing', tenant_id))}
                  sx={{ borderRadius: 2 }}
                  dataTestId={'unsubmitted-over-eighty-days-banner'}
                />
              )}
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box display={'flex'} flexDirection={'row'} gap={1}>
                  <DashboardCard
                    label={claimCounts.submittedThisWeek}
                    description={'Claims submitted this week'}
                    dataTestId={'claimsSubmitted'}
                  />
                  <DashboardCard
                    label={claimCounts.appointmentsWithNoClaimToday}
                    description={'Appointments today with no claim'}
                    dataTestId={'appointments-noClaim'}
                  />
                </Box>
                <Box>
                  <Section.SubHeader sx={{ mb: 0 }}>Unsubmitted</Section.SubHeader>
                  <Box display={'flex'} flexDirection={'row'} gap={1}>
                    <DashboardCard
                      label={claimCounts.ready}
                      description={'Ready to submit'}
                      icon={<CheckCircleOutlineIcon sx={{ color: 'primary.main', fontSize: '36px' }} />}
                      onClick={() => navigate(buildTenantRoute('billing?filter=READY', tenant_id))}
                      dataTestId={'readyToSubmit'}
                    />
                    <DashboardCard
                      label={claimCounts.heldByUser}
                      description={'Held by user'}
                      icon={<PauseCircleOutlineIcon sx={{ color: 'greys.medium', fontSize: '36px' }} />}
                      onClick={() => navigate(buildTenantRoute('billing?filter=HOLD', tenant_id))}
                      dataTestId={'heldByUser'}
                    />
                    <DashboardCard
                      label={claimCounts.errors}
                      description={'Contains errors'}
                      icon={<WarningAmberIcon sx={{ color: 'tertiary.orange', fontSize: '36px' }} />}
                      onClick={() => navigate(buildTenantRoute('billing?filter=ERROR', tenant_id))}
                      dataTestId={'containsErrors'}
                    />
                  </Box>
                </Box>
                <Box>
                  <Section.SubHeader sx={{ mb: 0 }}>Last assessment</Section.SubHeader>
                  <Box display={'flex'} flexDirection={'row'} gap={1}>
                    <DashboardCard
                      label={claimCounts.accepted}
                      description={'Accepted'}
                      onClick={() => navigate(buildTenantRoute('billing/assessed?filter=ACCEPTED', tenant_id))}
                      dataTestId={'accepted'}
                    />
                    <DashboardCard
                      label={claimCounts.heldByAHCIP}
                      description={'Held by AHCIP'}
                      onClick={() => navigate(buildTenantRoute(`billing/assessed?filter=${ABClaimAssessmentOutcome.HELD}`, tenant_id))}
                      dataTestId={'heldByAHCIP'}
                    />
                    <DashboardCard
                      label={claimCounts.adjusted}
                      description={'Adjusted'}
                      onClick={() => navigate(buildTenantRoute(`billing/assessed?filter=${ABClaimAssessmentOutcome.APPLIED}`, tenant_id))}
                      dataTestId={'adjusted'}
                    />
                    <DashboardCard
                      label={claimCounts.refused}
                      description={'Refused'}
                      onClick={() => navigate(buildTenantRoute(`billing/assessed?filter=${ABClaimAssessmentOutcome.REFUSED}`, tenant_id))}
                      dataTestId={'refused'}
                    />
                  </Box>
                </Box>
              </Box>
            </>
        }
      </Box>
    </Section.Column>
  )
}