import { gql } from "@apollo/client";


export const GET_ACCOUNT_QUERY = gql`
  query Account($keys: [String!]!) {
    user {
      tenants {
        id
        name
        province {
          code
          name
        }
        permissions {
          admin
          billing
          patient
          schedule
          practitioner
          chart
          reportingBilling
          reportingPatientAndAppointment
          labsReview
        }
        locations {
          id
          name
        }
      }
      profile {
          id
          firstName
          lastName
          emailAddress
      }
      settings(keys: $keys) {
          name
          ... on StringSetting {
              string: value
          }
          ... on BoolSetting {
              bool: value
          }
          ... on IntSetting {
              int: value
          }
      }
    }
  }
`


export const GET_USER_PROFILE = gql`
  query GetUserProfile($keys: [String!]!) {
    user {
      profile {
        id
        firstName
        lastName
        contactPhone
        version
      }
      settings(keys: $keys) {
        name
        ... on StringSetting {
            string: value
        }
        ... on BoolSetting {
            bool: value
        }
        ... on IntSetting {
            int: value
        }
      }
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($profile: ProfileInput!, $settings: UserSettingsInput!) {
    user {
      updateProfile(profile: $profile)
      updateSettings(settings: $settings)
    }
  }
`

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($settings: UserSettingsInput!) {
    user {
      updateSettings(settings: $settings)
    }
  }
`


export const GET_USER_SETTINGS = gql`
  query GetUserSetting($tenantId: ID!, $keys: [String!]!){
    user {
      settings(keys: $keys) {
        name
      }
    }
  }
`