import React, { useState } from 'react'
import { useLoadableQuery, useMutation } from '@apollo/client'
import {
  GET_USER_DOCUMENTS,
  LIST_TASK_ASSIGNMENTS, MARK_DOCUMENTS_REVIEWED
} from '../../../graphql-definitions'
import _get from 'lodash/get'
import { ReviewDocument } from '../../../types/inbox/ReviewDocument'
import { saveDocumentChanges } from '../../tasks/documents/components/ImportedDocumentsActionProvider'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { useDocumentContext } from '../../../providers/DocumentProvider'
import { useReviewDocumentStateContext } from './ReviewDocumentStateProvider'
import { TaskAssignmentType } from '../../../types/tasks/TaskAssignment'
import { TaskCategory } from '../../../types/tasks/TaskState'
import { useParams } from 'react-router-dom'
import { forEach } from 'lodash'

type TaskAssignmentFilters = {
  type: string
  state: TaskCategory | string
}

const getUserDocumentQueryResults = (data) => {
  return [..._get(data, 'tenant.review.document.list', []) as Array<ReviewDocument>]
}

const parseUserDocumentQueryResults = (data) => {
  return getUserDocumentQueryResults(data)
}


const getTaskQueryResults = (data) => {
  return [..._get(data, 'tenant.task.assignment.list', []) as Array<TaskAssignmentType>]
}

const parseTaskQueryResults = (data) => {
  return getTaskQueryResults(data)
}

interface ReviewContextInterface {
  loadUserDocuments: any,
  refetchUserDocuments: any,
  userDocumentsRef: any,
  markDocumentsAsReviewed: (documentIds: string[], onSuccess: () => void) => void,
  parseUserDocumentQueryResults: (any) => ReviewDocument[],
  saveDocumentEdits: (onSuccess: () => void) => Promise<void>,
  tasksRef: any,
  loadTasks: any,
  refetchTasks: any,
  taskFilters: TaskAssignmentFilters,
  trySetTaskFilters: (filters: Partial<TaskAssignmentFilters>, taskId?: string) => void,
  pendingTaskFilters: boolean,
  handlePendingTaskFilters: (approve: boolean) => void,
  parseTaskQueryResults: (any) => TaskAssignmentType[],
}

const defaultReviewContext: ReviewContextInterface = {
  loadUserDocuments: () => {},
  refetchUserDocuments: () => {},
  userDocumentsRef: null,
  markDocumentsAsReviewed: (documentIds: string[], onSuccess: () => void) => {},
  parseUserDocumentQueryResults: parseUserDocumentQueryResults,
  tasksRef: null,
  loadTasks: () => {},
  refetchTasks: () => {},
  parseTaskQueryResults: parseTaskQueryResults,
  taskFilters: {
    type: '*',
    state: TaskCategory.INCOMPLETE
  },
  trySetTaskFilters: (filters, taskId) => {},
  pendingTaskFilters: false,
  handlePendingTaskFilters: (approve) => {},
  saveDocumentEdits: async (onSuccess) => {},
}

const ReviewContext = React.createContext(defaultReviewContext)

export const ReviewProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { uploadToAzure, updateFile } = useDocumentContext()
  const { pdfDocRef, documentModified, selectedFile } = useReviewDocumentStateContext()

  const [ taskFilters, setTaskFilters ] = useState<TaskAssignmentFilters>({
    type: '*',
    state: TaskCategory.INCOMPLETE
  })
  const [ pendingTaskFilters, setPendingTaskFilters ] = useState<Partial<TaskAssignmentFilters>>()

  const [markDocumentsAsReviewedMutation] = useMutation(MARK_DOCUMENTS_REVIEWED)
  const [loadUserDocuments, userDocumentsRef,  { refetch: refetchUserDocuments }] = useLoadableQuery(GET_USER_DOCUMENTS,
    {fetchPolicy: 'cache-and-network'}
  )

  const markDocumentsAsReviewed = async (documentIds: string[], onSuccess: () => void) => {
    markDocumentsAsReviewedMutation({
      variables: {
        documentIds: documentIds,
        tenantId: tenant_id
      },
      update: (cache, data) => {
        forEach(documentIds, (documentId) => {
          cache.modify({
            id: cache.identify({ __typename: 'ReviewDocument', id: documentId }),
            fields: {
              isReviewed: () => true
            }
          })
        })
      }
    }).then(() => {
      onSuccess()
      refetchUserDocuments()
    }).catch((error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Documents couldn't be marked as reviewed.")
    })

  }

  const [loadTasks, tasksRef, { refetch: refetchTasks }] = useLoadableQuery(LIST_TASK_ASSIGNMENTS, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const saveDocumentEdits = async(onSuccess: () => void) => {
    const result = await saveDocumentChanges(documentModified, pdfDocRef, selectedFile, updateFile, uploadToAzure)
    if (!result) {
      showErrorAlert("Document changes couldn't be saved.")
      return
    }
    showSuccessAlert("Document has been saved.")
    if (onSuccess) {
      onSuccess()
    }
  }

  const trySetTaskFilters = (filters: Partial<TaskAssignmentFilters>, taskId?: string) => {
    if (!taskId) {
      setTaskFilters({ ...taskFilters, ...filters })
    } else {
      setPendingTaskFilters(filters)
    }
  }

  const handlePendingTaskFilters = (approve: boolean) => {
    if (approve) {
      setTaskFilters({ ...taskFilters, ...pendingTaskFilters })
    }
    setPendingTaskFilters(undefined)
  }

  const providerValues = {
    userDocumentsRef,
    loadUserDocuments,
    refetchUserDocuments,
    markDocumentsAsReviewed,
    parseUserDocumentQueryResults,
    saveDocumentEdits,
    tasksRef,
    loadTasks,
    refetchTasks,
    taskFilters,
    trySetTaskFilters,
    pendingTaskFilters: !!pendingTaskFilters,
    handlePendingTaskFilters,
    parseTaskQueryResults,
  }

  return (
    <ReviewContext.Provider value={providerValues}>
      { children }
    </ReviewContext.Provider>
  )
}

export const useReviewContext = () => {
  return React.useContext(ReviewContext)
}