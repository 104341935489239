import React from "react";
import { useParams } from "react-router-dom";
import { useBackgroundQuery, QueryReference, useMutation } from '@apollo/client'
import {
  GET_PATIENT_LAB_AND_INVESTIGATIONS,
  UPDATE_LAB_AND_INVESTIGATION, UPDATE_REVIEW_LAB
} from '../../../graphql-definitions'
import { AbLabResult, AbLabResultUpdate } from '../../../types/patients'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import {
  filterResultsForList,
  LabAndInvestigationSortPredicate
} from '../components/labAndInvestigations/LabAndInvestigationsUtil'
import { DocumentCategory } from '../../../types/Document'
import { ReviewLab, ReviewLabInput } from '../../../types/inbox/ReviewLab'

const getLabAndInvestigationQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.labAndInvestigation.list', []) as Array<AbLabResult>]
    .map(filterResultsForList)
}

export const parseLabAndInvestigationQueryResults = (data) => {
  return getLabAndInvestigationQueryResults(data).sort(LabAndInvestigationSortPredicate)
}

interface LabAndInvestigationContextInterface {
  labAndInvestigationQueryRef: QueryReference | null,
  getLabAndInvestigationQueryResults: (any) => AbLabResult[],
  parseLabAndInvestigationQueryResults: (any) => AbLabResult[],
  updateLabAndInvestigation: (labAndInvestigation: AbLabResultUpdate, onSuccess?:(labAndInvestigation: AbLabResult) => void) => Promise<void>,
  markAsReviewed: (
    labAndInvestigation: AbLabResult | undefined | null,
    reviewLabId: string | null,
    userId: string | null | undefined,
    labNotes: string,
    onComplete: (data, firstName: string, lastName: string) => Promise<void>,
    markingAllReviewed?: boolean
  ) => Promise<void>,
  loadingMarkAsReviewed: boolean,
  markAsReviewedAll: (
    labAndInvestigation: AbLabResult | undefined | null,
    userId: string | null | undefined,
    labNotes: string,
    onComplete: (data, firstName: string, lastName: string) => Promise<void>
  ) => Promise<void>,
  markAsUnreviewed: (
    labAndInvestigation: AbLabResult | undefined | null,
    reviewLabId: string | null,
    userId: string | null | undefined,
    labNotes: string,
    onComplete: (data, firstName: string, lastName: string) => Promise<void>
  ) => Promise<void>
}

const defaultLabAndInvestigationContext: LabAndInvestigationContextInterface = {
  labAndInvestigationQueryRef: null,
  getLabAndInvestigationQueryResults: getLabAndInvestigationQueryResults,
  parseLabAndInvestigationQueryResults: parseLabAndInvestigationQueryResults,
  updateLabAndInvestigation: () => new Promise<void>(() => {}),
  markAsReviewed: () => new Promise<void>(() => {}),
  loadingMarkAsReviewed: false,
  markAsReviewedAll: () => new Promise<void>(() => {}),
  markAsUnreviewed: () => new Promise<void>(() => {}),
}

const LabAndInvestigationContext = React.createContext(defaultLabAndInvestigationContext)

export const LabAndInvestigationProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [markAsReviewedMutation, {loading: loadingMarkAsReviewed}] = useMutation(UPDATE_REVIEW_LAB, { fetchPolicy: 'no-cache' })
  const markAsReviewed = async (
    labAndInvestigation: AbLabResult | undefined | null,
    reviewLabId: string | null,
    userId: string | null | undefined,
    labNotes: string,
    onComplete: (data, firstName: string, lastName: string, markingAllReviewed?: boolean) => Promise<void>,
    markingAllReviewed?: boolean,
    ) => {
    if (!labAndInvestigation || !userId) return

    let reviewLab

    if(reviewLabId === null) {
      reviewLab = labAndInvestigation?.reviewLabs?.find(r => r.practitioner.userId === userId)
    }
    else {
      reviewLab = labAndInvestigation?.reviewLabs?.find(r => r.id === reviewLabId)
    }

    if (!reviewLab) return

    const reviewLabInput: ReviewLabInput = {
      id: reviewLab.id,
      userId: userId,
      reviewUserId: userId,
      isReviewed: true,
      labNotes: labNotes,
      abLabResultVersion: labAndInvestigation?.version || '0',
      version: reviewLab?.version || '0'
    }

    await markAsReviewedMutation({
      variables: {
        tenantId: tenant_id,
        input: reviewLabInput
      },
      onCompleted: async (data) => {
        let firstName = reviewLab.practitioner.firstName
        let lastName = reviewLab.practitioner.lastName
        await onComplete(data, firstName, lastName, !!markingAllReviewed ?? false)
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      },
      update: (cache, data) => {
        const updatedLab = _get(data, 'data.tenant.review.lab.update') as unknown as ReviewLab
        if (!!updatedLab) {
          cache.modify({
            id: cache.identify(updatedLab),
            fields: {
              isReviewed: () => true,
              version: () => updatedLab.version
            }
          })
        }
      }
    })
  }

  const [markAsUnreviewedMutation] = useMutation(UPDATE_REVIEW_LAB, { fetchPolicy: 'no-cache' })
  const markAsUnreviewed = async (
    labAndInvestigation: AbLabResult | undefined | null,
    reviewLabId: string | null,
    userId: string | null | undefined,
    labNotes: string,
    onComplete: (data, firstName: string, lastName: string) => Promise<void>,
  ) => {
    if (!labAndInvestigation || !userId) return

    let reviewLab

    if(reviewLabId === null) {
      reviewLab = labAndInvestigation?.reviewLabs?.find(r => r.practitioner.userId === userId)
    }
    else {
      reviewLab = labAndInvestigation?.reviewLabs?.find(r => r.id === reviewLabId)
    }

    if (!reviewLab) return

    const reviewLabInput: ReviewLabInput = {
      id: reviewLab.id,
      userId: userId,
      reviewUserId: userId,
      isReviewed: false,
      labNotes: labNotes,
      abLabResultVersion: labAndInvestigation?.version || '0',
      version: reviewLab?.version || '0'
    }

    await markAsUnreviewedMutation({
      variables: {
        tenantId: tenant_id,
        input: reviewLabInput
      },
      onCompleted: async (data) => {
        let firstName = reviewLab.practitioner.firstName
        let lastName = reviewLab.practitioner.lastName
        await onComplete(data, firstName, lastName)
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      },
      update: (cache, data) => {
        const updatedLab = _get(data, 'data.tenant.review.lab.update') as unknown as ReviewLab
        if (!!updatedLab) {
          cache.modify({
            id: cache.identify(updatedLab),
            fields: {
              isReviewed: () => false,
              version: () => updatedLab.version
            }
          })
        }
      }
    })
  }

  const markAsReviewedAll = async (
    labAndInvestigation: AbLabResult | undefined | null,
    userId: string | null | undefined,
    labNotes: string,
    onComplete: (data, firstName: string, lastName: string) => Promise<void>,
  ) => {
    if (!labAndInvestigation || !labAndInvestigation.reviewLabs) return
    await Promise.all(labAndInvestigation.reviewLabs.map(async (reviewLab) => {
      if(reviewLab.isReviewed) return
      await markAsReviewed(labAndInvestigation, reviewLab.id, userId, labNotes, onComplete, true)
    })).catch(() => {
      showErrorAlert('Lab and investigation couldn\'t be saved')
    }).then(() => {
      showSuccessAlert('Lab has been marked as reviewed for all practitioners.')
    })
  }

  const [ updateLabAndInvestigationMutation ] = useMutation(UPDATE_LAB_AND_INVESTIGATION)

  const [labAndInvestigationQueryRef] = useBackgroundQuery(GET_PATIENT_LAB_AND_INVESTIGATIONS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const updateLabAndInvestigation = async (labAndInvestigationInput, onSuccess) => {
    const id = labAndInvestigationInput.id
    delete labAndInvestigationInput.id

    await updateLabAndInvestigationMutation({
      variables: {
        tenantId: tenant_id,
        id: id,
        input: labAndInvestigationInput
      },
      onCompleted: (data) => {
        showSuccessAlert("Lab and investigation has been saved")
        if (onSuccess) {
          const labAndInvestigation = _get(data, 'tenant.patient.labAndInvestigation.update', null)
          onSuccess(labAndInvestigation)
        }
      },
      onError: () => {
        showErrorAlert("Lab and investigation couldn't be saved")
      }
    })
  }

  const providerValues = {
    labAndInvestigationQueryRef,
    getLabAndInvestigationQueryResults,
    parseLabAndInvestigationQueryResults,
    updateLabAndInvestigation,
    markAsReviewed,
    loadingMarkAsReviewed,
    markAsReviewedAll,
    markAsUnreviewed
  }

  return (
    <LabAndInvestigationContext.Provider value={providerValues}>
      { children }
    </LabAndInvestigationContext.Provider>
  )
}

export const useLabAndInvestigationContext = () => {
  return React.useContext(LabAndInvestigationContext)
}

const updateLabsAndInvestigationsCache = async(cache, newLab, tenantId, patientId) => {
  await cache.updateQuery({
    query: GET_PATIENT_LAB_AND_INVESTIGATIONS,
    variables: {
      tenantId,
      patientId
    }
  }, (data) => {
    const existingLabs = _get(data, 'tenant.patient.labAndInvestigation.list', []).filter(d => d.id !== newLab.id || d.isLinkedDocument !== newLab.isLinkedDocument)

    return {
      tenant: {
        patient: {
          labAndInvestigation: {
            list: [...existingLabs, newLab]
          }
        }
      }
    }
  })
}

const transformLinkedDocument = (document) => {
  const isLab = document.category === DocumentCategory.LAB_RESULT
  const description = !!document.description || document.description.trim().length > 0
    ? document.description
    : isLab ? "Lab result" : "Investigation"

  return {
    id: document.id,
    date: document.documentDate,
    description: description,
    isLinkedDocument: true,
    linkedDocument: document,
    __typename: 'AbLabResult',
    abLabResultObservationResults: [],
    isLab: isLab,
    notes: "",
    audit: document.audit,
    isAbnormal: null,
    version: document.file.version
  }
}

export const moveDocumentToLabAndInvestigation = async(cache, document, tenantId, patientId) => {
  const newLab = transformLinkedDocument(document)

  await updateLabsAndInvestigationsCache(cache, newLab, tenantId, patientId)
}
