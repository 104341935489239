import React, { useState } from 'react'
import { FilterSelect } from 'saga-library/src'
import _get from 'lodash/get'
import { useQuery } from '@apollo/client'
import { LIST_PRACTITIONERS } from '../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'

type PractitionerDropdownProps = {
  selectedPractitionerId?: string
  size?: "small" | "large"
  onChange: (id: string) => void
  dataTestId: string
}

const defaultOption = {
  label: "All practitioners",
  value: "*"
}

export const PractitionerDropdown = ({
  selectedPractitionerId,
  size,
  onChange: parentOnChange,
  dataTestId
}: PractitionerDropdownProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()

  const [options, setOptions] = useState<{label: string | null, value: string | null}[]>([])

  useQuery(LIST_PRACTITIONERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data ) => {
      setOptions([defaultOption, ...(data ? getPractitionerOptions(data) : [])])
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Practitioner list couldn\'t be retrieved.')
      setOptions([defaultOption])
    }
  })

  const getPractitionerOptions = (practitionersData) => {
    return _get(practitionersData, 'tenant.practitioner.list', []).map(practitioner => {
      return {
        value: practitioner.id,
        label: practitionerDisplayName(practitioner.firstName, practitioner.lastName)
      }
    })
  }

  const onChange = (e) => {
    parentOnChange(e.value)
  }

  return (
    <FilterSelect
      options={options}
      onSelect={onChange}
      defaultSelectedValue={selectedPractitionerId || defaultOption.value}
      size={size}
      sx={{ flexShrink: 0 }}
      dataTestId={dataTestId}
    />
  )
}
