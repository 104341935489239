import React from 'react'
import { useParams } from 'react-router-dom'
import { QueryReference, useBackgroundQuery, useMutation } from '@apollo/client'
import {
  CREATE_ALLERGY,
  DELETE_ALLERGY,
  GET_PATIENT_ALLERGIES,
  UPDATE_ALLERGY
} from '../../../graphql-definitions/tenant/patient/AllergyQueries'
import { AllergyType } from '../../../types/Allergy'
import _get from 'lodash/get'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { AllergySortPredicate } from '../components/allergies/components/AllergyUtil'

const getAllergyQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.allergy.list', []) as Array<AllergyType>]
}

export const parseAllergyQueryResults = (data) => {
  return getAllergyQueryResults(data).sort(AllergySortPredicate)
}

interface AllergyContextInterface {
  createAllergy: (allergy: AllergyType, onComplete?: () => void) => Promise<void>
  updateAllergy: (allergy: AllergyType, onComplete: () => void) => Promise<void>
  deleteAllergy: (allergy: AllergyType, onComplete: () => void) => Promise<void>
  allergyQueryRef: QueryReference | null
  getAllergyQueryResults: (any) => AllergyType[]
  parseAllergyQueryResults: (any) => AllergyType[]
}

const defaultAllergyContext: AllergyContextInterface = {
  createAllergy: (allergy: AllergyType, onComplete?: () => void) => Promise.resolve(),
  updateAllergy: (allergy: AllergyType, onComplete: () => void) => Promise.resolve(),
  deleteAllergy: (allergy: AllergyType, onComplete: () => void) => Promise.resolve(),
  allergyQueryRef: null,
  getAllergyQueryResults: getAllergyQueryResults,
  parseAllergyQueryResults: parseAllergyQueryResults
}

const AllergyContext = React.createContext(defaultAllergyContext)

export const AllergyProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert, showWarningAlert } = useAlerts()

  const [createAllergyGQL, {error: createAllergyError, loading: createAllergyLoading}] = useMutation(CREATE_ALLERGY)
  const [updateAllergyGQL, {error: updateAllergyError, loading: updateAllergyLoading}] = useMutation(UPDATE_ALLERGY)
  const [deleteAllergyGQL, {error: deleteAllergyError, loading: deleteAllergyLoading}] = useMutation(DELETE_ALLERGY)

  const [allergyQueryRef] = useBackgroundQuery(GET_PATIENT_ALLERGIES, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const createAllergy = async(allergy, onComplete) => {
    await createAllergyGQL({
      variables: {
        input: {
          ...allergy,
          patientId: patient_id,
          version: "0",
        },
        tenantId: tenant_id,
      },
      update: (cache, data) => {
        const newAllergy = _get(data, 'data.tenant.patient.allergy.create')
        cache.updateQuery({
          query: GET_PATIENT_ALLERGIES,
          variables: { tenantId: tenant_id, patientId: patient_id }
        }, (data) => {
          return {
            tenant: {
              patient: {
                allergy: {
                  list: [...data.tenant.patient.allergy.list, newAllergy]
                }
              }
            }
          }
        })
      },
      onCompleted: (data) => {
        if (onComplete) {
          onComplete()
        }
        showSuccessAlert('Allergy has been added.')
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Allergy couldn\'t be created.')
      }
    })
  }

  const updateAllergy = async(allergy, onComplete) => {
    const allergyId = allergy.id
    delete allergy.id
    await updateAllergyGQL({
      variables: {
        tenantId: tenant_id,
        id: allergyId,
        input: allergy
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Allergy has been saved.`)
      },
      onError:(error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Allergy couldn\'t be saved.')
      }
    })
  }

  const deleteAllergy = async(allergy, onComplete) => {
    await deleteAllergyGQL({
      variables: {
        tenantId: tenant_id,
        id: allergy.id,
        version: allergy.version
      },
      update: (cache, data) => {
        const normalizedId = cache.identify({ id: allergy.id, __typename: 'Allergy' })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Allergy has been removed.`)
      },
      onError:(error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Allergy couldn\'t be removed.')
      }
    })
  }

  const providerValues = {
    createAllergy,
    updateAllergy,
    deleteAllergy,
    allergyQueryRef,
    getAllergyQueryResults,
    parseAllergyQueryResults
  }

  return (
    <AllergyContext.Provider value={providerValues}>
      { children }
    </AllergyContext.Provider>
  )
}

export const useAllergyContext = () => {
  return React.useContext(AllergyContext)
}