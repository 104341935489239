import { ControlledSearchControlProps } from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React from "react";
import {
  PrescriptionSearchResult,
  ResultTypes
} from "./PrescriptionSearchResult";
import {
  GET_SEARCH_PRESCRIPTIONS_AND_DRUGS,
  LIST_FAVOURITE_AND_RECENT_PRESCRIPTIONS
} from "saga-client/src/graphql-definitions";
import { useParams } from "react-router-dom";
import {
  Drug, PrescriptionFavouriteAndRecentResultType,
  PrescriptionFavouriteType,
  PrescriptionType,
  PrescriptionUserType
} from "../../../../../types/Prescription";
import omitDeep from "omit-deep-lodash";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import {
  Box,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "@mui/material/utils";
import { DEBOUNCE_WAIT_TIME } from "saga-library/src/components/SearchControl/types";
import {
  DUMMY_SEARCH,
  NoOptions
} from "saga-library/src/components/SearchControl/SearchControl";
import _get from "lodash/get";
import { ConfirmationDialog, SimpleTextField, useSchemaRequired } from "saga-library/src";
import { usePrescriptionContext } from "../../../providers/PrescriptionProvider";

const FAVOURITE_TAB = "FAVOURITES"
const RECENT_TAB = "FREQUENTLY USED"

type ResultType = {
  resultType: string
  comparator: string
}

type PrescriptionSearchResultType = PrescriptionFavouriteAndRecentResultType & {
  prescriptionUserTypes?: PrescriptionUserType[]
}

export type PrescriptionResultType = (Drug | PrescriptionType | PrescriptionFavouriteAndRecentResultType) & ResultType
export type PrescriptionFreeSoloType = string | PrescriptionResultType

export interface DrugSearchProps<
  T = PrescriptionFreeSoloType,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = true
> extends Omit<
  ControlledSearchControlProps<T, Multiple, DisableClearable, FreeSolo>,
  "queries" | "render" | "transform" | "isOptionEqualToValue"
> {
  onChange?: (value: T) => void;
  setFavouriteSelection: (value: string) => void;
}

export const DrugSearch = ({
  name = "drug",
  label = "Drug",
  onChange,
  setFavouriteSelection,
  inputRef,
  dataTestId,
  ...props
}: DrugSearchProps) => {
  const { tenant_id } = useParams();
  const { control, reset, getValues } = useFormContext()
  const required = useSchemaRequired()
  const { unfavouritePrescription } = usePrescriptionContext()

  const [tabValue, setTabValue] = React.useState(0)
  const [inputValue, setInputValue] = React.useState<string>("")
  const [searchOptions, setSearchOptions] = React.useState<readonly PrescriptionResultType[]>([])
  const [initialOptions, setInitialOptions] = React.useState<{favourites: readonly PrescriptionResultType[], recents: readonly PrescriptionResultType[]}>({favourites: [], recents: []})
  const [favouritePrescriptionId, setFavouritePrescriptionId] = React.useState<string | null>(null)

  const { watch, setValue } = useFormContext()

  //GQL search values
  const [initial, { loading: iLoading }] = useLazyQuery(
    LIST_FAVOURITE_AND_RECENT_PRESCRIPTIONS || DUMMY_SEARCH,
    { fetchPolicy: "cache-and-network" }
  )
  const [search, { loading }] = useLazyQuery(GET_SEARCH_PRESCRIPTIONS_AND_DRUGS, {
    fetchPolicy: "network-only"
  })

  const cleanData = (data, resultType) => {
    return data.map((obj) => {
      if (resultType === ResultTypes.drug) {
        return {
          ...obj,
          resultType: resultType,
          comparator: (obj as Drug).name
        }
      }
      if (resultType === ResultTypes.favouriteAndRecent) {
        let prescriptionUserTypes = (obj as PrescriptionSearchResultType).prescriptionUserTypes
        if (prescriptionUserTypes && prescriptionUserTypes.length === 1) {
          resultType = prescriptionUserTypes[0]
        }
        delete obj.prescriptionUserTypes
        return {
          ...obj,
          resultType: resultType,
          comparator: (obj as PrescriptionType).id
        }
      }
      return {
        ...obj,
        resultType: resultType,
        comparator: (obj as PrescriptionType).id
      }
    })
  }

  //Main search query
  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PrescriptionResultType[]) => void
        ) => {
          setSearchOptions([]);
          search({
            variables: {
              searchParam: request.input,
              tenantId: tenant_id
            },
            onCompleted: (data) => {
              let drugs = omitDeep(
                _get<any, string, Drug[]>(
                  data,
                  "tenant.search.reference.drugSearchResults",
                  []
                ) as PrescriptionResultType[],
                "__typename"
              );
              drugs = cleanData(drugs, ResultTypes.drug)

              let prescriptions = omitDeep(
                _get<any, string, PrescriptionSearchResultType[]>(
                  data,
                  "tenant.search.reference.prescriptionFavouriteAndRecentSearchResults",
                  []
                ) as PrescriptionSearchResultType[],
                "__typename"
              );
              prescriptions = cleanData(prescriptions, ResultTypes.favouriteAndRecent)
              callback([...prescriptions, ...drugs])
            },
            onError: (err) => {
              console.error(err);
            }
          });
        },
        DEBOUNCE_WAIT_TIME
      ),
    []
  );

  //Perform search on value, or input value change
  React.useEffect(() => {
    let active = true;
    if (inputValue) {
      fetch(
        { input: inputValue },
        (results?: readonly PrescriptionResultType[]) => {
          if (active) {
            setSearchOptions(results || []);
          }
        }
      );
    }
    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  //Load initial options on first render and when variables change
  React.useEffect(() => {
    initial({
      variables: {
        tenantId: tenant_id
      },
      onCompleted: (data) => {
        let favouritePrescriptions = omitDeep(
          _get<any, string, PrescriptionFavouriteType[]>(
            data,
            "tenant.user.favourite.list",
            []
          ) as PrescriptionResultType[],
          "__typename"
        );
        favouritePrescriptions = cleanData(favouritePrescriptions, ResultTypes.favourite);

        let recentPrescriptions = omitDeep(
          _get<any, string, PrescriptionType[]>(
            data,
            "tenant.user.recent.list",
            []
          ) as PrescriptionResultType[],
          "__typename"
        );
        recentPrescriptions = cleanData(recentPrescriptions, ResultTypes.recent);

        setInitialOptions({
          favourites: favouritePrescriptions,
          recents: recentPrescriptions
        });
      },
      onError: (err) => {
        console.error(err);
      }
    });
  }, [tenant_id]);

  const handleTabChange = (event, tabValue) => {
    event.preventDefault();
    setTabValue(tabValue);
  };

  const getOptionsForTab = () => {
    // Return Recents or Favourites depending on the tab selected
    return tabValue === 0 ? initialOptions.recents : initialOptions.favourites;
  };

  const getOptions = () => {
    // If there is a search text, return the search results
    if (inputValue) {
      return searchOptions as PrescriptionFreeSoloType[];
    }
    // No search text, return the tab specific options
    return getOptionsForTab();
  };

  const getOptionLabel = (option: PrescriptionFreeSoloType) => {
    if(typeof option === 'string'){
      return option;
    }
    else if (option?.resultType === ResultTypes.drug)
    {
      return (option as Drug)?.name;
    }
    else if (option?.resultType === ResultTypes.favourite
      || option?.resultType === ResultTypes.recent
      || option?.resultType === ResultTypes.favouriteAndRecent)
    {
      return (option as PrescriptionType)?.drug?.name;
    }
    else
    {
      return (option as any)?.name;
    }
  };

  const CustomDropdown = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper
        {...containerProps}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        { inputValue === "" &&
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={RECENT_TAB} />
            <Tab label={FAVOURITE_TAB} />
          </Tabs>
        }
        <Box>{children}</Box>
      </Paper>
    );
  };

  const handleUnfavouritePrescription = () => {
    if(favouritePrescriptionId) {
      unfavouritePrescription(favouritePrescriptionId, removeFavourite)
      setFavouritePrescriptionId(null)
    }
  }

  const removeFavourite = () => {
    const updatedFavourites = initialOptions.favourites.filter((favourite) => (favourite as any).id !== favouritePrescriptionId)
    setInitialOptions(prevState => {
      return {
        ...prevState,
        favourites: updatedFavourites
      }
    })
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: formOnChange },
        fieldState: { error }
      }) => {
        return (
          <>
            <Autocomplete
              data-testid={dataTestId}
              value={watch(name)}
              disablePortal
              freeSolo
              disabled={props.disabled}
              loading={loading || iLoading}
              PaperComponent={CustomDropdown}
              options={getOptions()}
              getOptionLabel={getOptionLabel}
              sx={props.sx}
              onChange={(event, newValue) => {
                setValue(name, newValue);
                let anyValue = newValue as any;

                if (anyValue?.resultType === ResultTypes.drug)
                {
                  setFavouriteSelection('')
                  formOnChange(newValue as Drug);
                }
                else if (anyValue?.resultType === ResultTypes.favourite
                          || anyValue?.resultType === ResultTypes.recent
                          || anyValue?.resultType === ResultTypes.favouriteAndRecent)
                {
                  if (anyValue?.resultType === ResultTypes.recent ) {
                    setFavouriteSelection('')
                  } else {
                    setFavouriteSelection((newValue as PrescriptionType).id || '')
                  }
                  formOnChange((newValue as PrescriptionType).drug);
                  reset({
                    ...(newValue as PrescriptionType),
                    id: getValues("id"),
                    startDate: getValues("startDate"),
                    refills: getValues("refills"),
                    refillsExpire: getValues("refillsExpire"),
                    practitionerId: getValues("practitionerId"),
                    historical: getValues("historical")
                  });
                }
                else if (typeof anyValue === 'string')
                {
                  if (value && typeof value !== 'string' && anyValue !== value.name) {
                    setFavouriteSelection('')
                  }
                  formOnChange({name: newValue})
                }
                else
                {
                  setFavouriteSelection('')
                  formOnChange(null)
                }
              }}
              filterOptions={(x) => x}
              noOptionsText={<NoOptions inputValue={inputValue} />}
              onInputChange={(event, newInputValue, reason) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                  <SimpleTextField
                    {...params}
                    fullWidth={true}
                    label={label}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      ...params.InputProps
                    }}
                    inputRef={(e) => {
                      if(inputRef){
                        inputRef.current = e
                      }
                    }}
                    autoFocus={props.autoFocus}
                    required={required}
                    disabled={props.disabled}
                  />
              )}
              renderOption={(props, option, state) => {
                return (
                  <li {...props} key={option?.id || option?.code}>
                    <PrescriptionSearchResult
                      prescriptionResult={option}
                      searchText={state.inputValue}
                      onUnfavourite={() => {
                        setFavouritePrescriptionId((option as PrescriptionType)?.id || null)
                      }}
                    />
                  </li>
                )
              }}
              isOptionEqualToValue={(option, value) => {
                if (typeof value === 'string') {
                  return (option as PrescriptionResultType)?.comparator === value;
                }
                return (option as PrescriptionResultType)?.comparator === (value as PrescriptionResultType)?.comparator;
              }}
              {...props}
            />
            <UnfavouriteDialog open={!!favouritePrescriptionId} setOpen={setFavouritePrescriptionId} onProceed={handleUnfavouritePrescription}/>
          </>
        )
      }}
    />
  );
};

const UnfavouriteDialog = ({open, setOpen, onProceed}) => {
  return (
    <ConfirmationDialog
      open={open}
      title={'Delete prescription favourite'}
      message={"This action cannot be undone"}
      primaryAction={onProceed}
      primaryLabel={'Delete'}
      onClose={()=>setOpen(false)}
    />
  )
}