import React from "react";
import { useQuery } from '@apollo/client'
import { Box, Chip, SxProps } from "@mui/material";
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { AutocompleteTextField } from 'saga-library/src/components/TextField/TextField'
import { Theme } from "@mui/system";
import { GET_SKILLS } from "../../graphql-definitions";
import ClearIcon from "@mui/icons-material/Clear";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { IdEqual } from 'saga-library/src/components/SearchControl/ControlledSearchControl'


export type SkillValue = {
  label: string
  id: string
  description: string
}

interface SkillSelectProps {
  provinceCode: string | null | undefined
  searchBySkillCode?: boolean
  name?: string
  multiple?: boolean
  label?: string
  sx?: SxProps<Theme>
  autoFocus?: boolean
  disabled?: boolean
  dataTestId?: string
}

export const SkillSelect = ({
  provinceCode,
  searchBySkillCode = false,
  name = "skillIds",
  multiple = false,
  label = "Skills",
  sx,
  autoFocus,
  disabled=false,
  dataTestId
}: SkillSelectProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: {error} }) => {
        return (
          <UncontrolledSkillSelect
            dataTestId={dataTestId}
            value={value}
            provinceCode={provinceCode}
            error={error}
            helperText={error?.message}
            searchBySkillCode={searchBySkillCode}
            name={name}
            multiple={multiple}
            label={label}
            onChange={onChange}
            sx={sx}
            autoFocus={autoFocus}
            inputRef={ref}
            disabled={disabled}
          />
        )
      }}
    />
  )
}

const UncontrolledSkillSelect = ({
  label,
  name,
  value,
  error,
  multiple = false,
  provinceCode,
  searchBySkillCode,
  helperText,
  onChange,
  sx,
  autoFocus,
  inputRef,
  limitTags = 3,
  disabled=false,
  dataTestId
}) => {


  const handleOnChange = (data) => {
    onChange(data)
  }

  const { data, loading } = useQuery(GET_SKILLS, {
    fetchPolicy: 'cache-first',
    variables: { provinceCode: provinceCode }
  })

  let options: SkillValue[] = []
  if(data) {
    options = data.static.skillsByProvince
  }

  return (
    options && <FormControl fullWidth sx={{ margin: '8px 0' }}>
      <InputLabel data-testid={`${dataTestId}-label`} error={error} />
      <Autocomplete
        multiple={multiple}
        loading={loading}
        limitTags={limitTags}
        data-testid={dataTestId}
        options={options}
        value={value}
        getOptionLabel={(value) => searchBySkillCode ? value.skillCode : value.description}
        disabled={disabled}
        renderInput={(params) => {
          return <AutocompleteTextField
            dataTestId={`${dataTestId}-textField`}
            params={params}
            name={name}
            label={label}
            error={error}
            size={'small'}
            autoFocus={autoFocus}
            inputRef={inputRef}
            disabled={disabled}
          />
        }}
        renderOption={(props, option, { index }) => (
          <Box component="li" data-testid={`${dataTestId}-option-${index}`} {...props}>
            <Box>
              {searchBySkillCode ? option.skillCode : option.description}
              <Box style={{ fontSize: '12px' }}>
                {searchBySkillCode ? option.description : option.skillCode}
              </Box>
            </Box>
          </Box>)
        }
        renderTags={(tagValues, getTagProps) =>
          tagValues.map((option, index) => (
            <Chip
              label={option.skillCode}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(_, data) => {
          handleOnChange(data)
        }}
        isOptionEqualToValue={IdEqual}
        sx={sx}
        clearIcon={<ClearIcon fontSize="small" data-testid={`${dataTestId}-clearIcon`} />}
        popupIcon={<ArrowDropDownIcon data-testid={`${dataTestId}-dropDownIcon`} />}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default SkillSelect

