import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_FORMS } from '../../../../../graphql-definitions/tenant/patient/FormQueries'
import { parseFormQueryResults } from '../../../../patients/providers/PatientFormProvider'
import { EmptyList } from 'saga-library/src'
import { FormType } from '../../../../../types/patients'
import React from 'react'
import { DateListItem, ItemType, ListBox } from '../LinkItem'
import { LinkType } from '../../../../../types/tasks/TaskAssignment'

export const FormList = ({patientId, onSelect}) => {
  const {tenant_id} = useParams()

  const {data} = useSuspenseQuery(GET_PATIENT_FORMS ,{
    variables:{
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const forms = parseFormQueryResults(data)

  if(forms.length > 0) {
    return (
      <ListBox>
        {forms.map( f => <FormItem onClick={onSelect} item={f} key={`link_patient_encounter_note_${f.id}`} />)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{p:1, width:"200px"}} message={ `No associated forms`} />
  )
}

export const FormItem = ({item, onClick, icon, ...itemTypeProps }:ItemType) => {
  const form = item as FormType
  return (
    <DateListItem
      date={form.formDate}
      label={form.name}
      subLabel={form.description}
      onClick={onClick ? (e)=>onClick(e, {...form, linkType:LinkType.FORM}) : undefined}
      icon={icon}
      {...itemTypeProps}
    />
  )
}