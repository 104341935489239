import React, { useCallback, useEffect, useState } from 'react'
import { TextField, Select, IconButton } from 'saga-library/src'
import {
  EncounterNoteField,
  EncounterNoteFieldProps,
  EncounterNoteTemplateFieldTree
} from '../../../../../types/patients/EncounterNote'
import { Box, InputAdornment, Menu, MenuItem } from '@mui/material'
import { LengthHeightUnitList, LengthHeightUnits, WeightUnitList, WeightUnits } from '../../vitals/VitalTypes'
import { AddVitalIcon } from '../../ChartIcons'
import { VitalTypesList } from '../EncounterNoteUtil'
import { EncounterNoteContainer } from './EncounterNoteContainer'

const VitalAction = ({disabled, dataTestId, onClick}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleAddButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    handleMenuClose(event)
    onClick(type)
  }

  return (
    <>
      <IconButton
        icon={<AddVitalIcon />}
        dataTestId={`${dataTestId}-add-vital-button`}
        onClick={handleAddButtonClick}
        disabled={disabled}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={(e) => handleMenuItemClick(e, 'bloodPressure')}>Additional blood pressure measurement</MenuItem>
        <MenuItem onClick={(e) => handleMenuItemClick(e, 'heartRate')}>Additional heart rate measurement</MenuItem>
      </Menu>
    </>
  )
}

const UnitSelect = ({ units, name, dataTestId, ...props }) => {
  return (
    <Select
      label={''}
      name={name}
      dataTestId={dataTestId}
      sx={{
        color: 'greys.medium',
        border: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        px: 0,
      }}
      {...props}
    >
      {units.map((unit) => (
        <MenuItem key={unit.value} value={unit.value}>
          {unit.label}
        </MenuItem>
      ))}
    </Select>
  )
}


const unitsSelector = (unitType, name, disabled, dataTestId) => {
  switch (unitType) {
    case 'lengthHeight':
      return (
        <UnitSelect
          name={`${name}.lengthHeightUnit`}
          units={LengthHeightUnitList}
          defaultValue={LengthHeightUnits.CM}
          disabled={disabled}
          dataTestId={`${dataTestId}-lengthHeightUnit`}
        />
      )
    case 'weight':
      return (
        <UnitSelect
          name={`${name}.weightUnit`}
          units={WeightUnitList}
          defaultValue={WeightUnits.KG}
          disabled={disabled}
          dataTestId={`${dataTestId}-weightUnit`}
        />
      )
    case 'headCircumference':
      return (
        <UnitSelect
          name={`${name}.headCircumferenceUnit`}
          units={LengthHeightUnitList}
          defaultValue={LengthHeightUnits.CM}
          disabled={disabled}
          dataTestId={`${dataTestId}-headCircumferenceUnit`}
        />
      )
    case 'bloodPressure':
      return 'mm Hg'
    case 'heartRate':
      return 'bpm'
    default:
      return ''
  }
}

function sortFields (a: EncounterNoteField, b: EncounterNoteField): number {
  return a.additionalFieldOrder! - b.additionalFieldOrder!
}

interface EncounterNoteVitalsProps extends EncounterNoteFieldProps {
  templateField: EncounterNoteTemplateFieldTree
  additionalFields: EncounterNoteField[]
  hasInitialVitalData?: boolean
}

export const EncounterNoteVitals = ({
  name,
  isSigned,
  disabled,
  dataTestId,
  templateField,
  additionalFields,
  hasInitialVitalData
}: EncounterNoteVitalsProps) => {
  const [addVital, setAddVital] = useState<'bloodPressure' | 'heartRate' | null>(null)
  const [vitalEntries, setVitalEntries] = useState<React.ReactNode[]>([])
  const [additionalVitalCount, setAdditionalVitalCount] = useState<number>(
    additionalFields.reduce((maxOrder, field) => Math.max(maxOrder, field.additionalFieldOrder ?? 0), 0)
  )
  const [bloodPressureTemplateFieldId, setBloodPressureTemplateFieldId] = useState<string>('')
  const [heartRateTemplateFieldId, setHeartRateTemplateFieldId] = useState<string>('')

  const BasicVitalEntry = useCallback(({ label, name, dataTestId, units }) => {
    return (
      <TextField
        label={label}
        name={name}
        dataTestId={dataTestId}
        hideHelperText={true}
        disabled={disabled}
        type={'number'}
        regexPattern={/^[1-9]\d*$/}
        sx={{
          flex: '1 0 140px'
        }}
        InputProps={{
          readOnly: isSigned,
          endAdornment: <InputAdornment position="end" sx={{ marginRight: typeof units ==='string' ? '-8px' : '-16px' }}>{units}</InputAdornment>
        }}
      />
    )
  }, [isSigned, disabled])

  const BloodPressureEntry = ({id, label, order} : {id: string, label: string, order: number}) => {
    return (
      <React.Fragment key={id}>
        <BasicVitalEntry
          key={`${id}-systolic-${order}`}
          label={`Systolic ${label}`}
          name={`vitals-${id}-${order}.bloodPressureSystolic`}
          dataTestId={`${dataTestId}-bloodPressureSystolic-${order}`}
          units={'mm Hg'}
        />
        <BasicVitalEntry
          key={`${id}-diastolic-${order}`}
          label={`Diastolic ${label}`}
          name={`vitals-${id}-${order}.bloodPressureDiastolic`}
          dataTestId={`${dataTestId}-bloodPressureDiastolic-${order}`}
          units={'mm Hg'}
        />
      </React.Fragment>
    )
  }

  useEffect(() => {
    if (!templateField.subFields) return

    const heartRateId = templateField.subFields.find((field) => field.name === 'heartRate')?.id || ''
    const bloodPressureId = templateField.subFields.find((field) => field.name === 'bloodPressure')?.id || ''
    setHeartRateTemplateFieldId(heartRateId)
    setBloodPressureTemplateFieldId(bloodPressureId)

    if (templateField.subFields.length > 0) {
      const additionalEntries = additionalFields.sort(sortFields).map((field) => {
        switch (field.templateFieldId){
          case heartRateId:
            return <BasicVitalEntry
              key={`${field.templateFieldId}-${field.additionalFieldOrder}`}
              label={'Heart Rate'}
              name={`vitals-${field.templateFieldId}-${field.additionalFieldOrder}.heartRate`}
              dataTestId={`${dataTestId}-heartRate-${field.additionalFieldOrder}`}
              units={'bpm'}
            />
          case bloodPressureId:
            return <BloodPressureEntry key={`${field.templateFieldId}-${field.additionalFieldOrder}`} id={field.templateFieldId!} label={'BP'} order={field.additionalFieldOrder!}/>
          default:
            return <></>
        }
      })
      const originalEntries = templateField.subFields.map((field) => {
        const label = VitalTypesList.find((vital) => vital.value === field.name)!.label
        if (field.name === 'bloodPressure') {
          return <BloodPressureEntry key={`${field.id}`} id={`${field.id}`} label={label} order={0}/>
        }
        return (
          <BasicVitalEntry
            key={`${field.id}-0`}
            label={label}
            name={`vitals-${field.id}-0.${field.name}`}
            dataTestId={`${dataTestId}-${field.name}-0`}
            units={unitsSelector( field.name, `vitals-${field.id}-0`, disabled, dataTestId)}
          />
        )
      })
      setVitalEntries([...originalEntries, ...additionalEntries])
    }
  }, [templateField.subFields, additionalFields])


  useEffect(() => {
    if (!addVital) return

    const newIndex = additionalVitalCount + 1

    setVitalEntries((prev) => {
      const newEntries = [...prev]
      switch (addVital) {
        case 'bloodPressure':
          newEntries.push(
            <BloodPressureEntry
              key={`${bloodPressureTemplateFieldId}-${newIndex}`}
              id={bloodPressureTemplateFieldId!}
              label={'BP'}
              order={newIndex}
            />
          )
          break
        case 'heartRate':
          newEntries.push(
            <BasicVitalEntry
              key={`${heartRateTemplateFieldId}-${newIndex}`}
              label={'Heart Rate'}
              name={`vitals-${heartRateTemplateFieldId}-${newIndex}.heartRate`}
              dataTestId={`${dataTestId}-heartRate-${newIndex}`}
              units={'bpm'}
            />
          )
          break
        default:
          break
      }
      return newEntries
    })

    setAdditionalVitalCount(newIndex)
    setAddVital(null)
  }, [addVital, setAddVital, additionalVitalCount, setAdditionalVitalCount, heartRateTemplateFieldId, bloodPressureTemplateFieldId])


  return (
    <EncounterNoteContainer
      key={templateField.id}
      templateField={templateField}
      dataTestId={dataTestId}
      defaultStyle={'controlled'}
      collapsed={!hasInitialVitalData}
      actions={(
        <VitalAction
          disabled={disabled}
          dataTestId={dataTestId}
          onClick={setAddVital}
        />
      )}
    >
      <Box
        key={name}
        display={'flex'}
        sx={{
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: 2
        }}
      >
        {vitalEntries}
      </Box>
    </EncounterNoteContainer>
  )
}