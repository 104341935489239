import React from "react";
import { NavLink } from "react-router-dom";
import { SubsectionMenu, SubsectionMenuItem } from "saga-library/src";

export const SmartTypeMenu = () => {
  return (
    <SubsectionMenu
      routes={[
        <SmartTypeRoute
          route={'text'}
          text={'Text shortcuts'}
        />
      ]}
    />
  )
}

const SmartTypeRoute = ({ route, text }) => {
  return (
    <NavLink to={route}>
      {({ isActive }) => (
        <SubsectionMenuItem
          dataTestId={`smartTypeMenu-menuItem-${text}`}
          isActive={isActive}
          primaryText={text}
        />
      )}
    </NavLink>
  )
}

export default SmartTypeMenu