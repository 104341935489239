import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { ConfirmationDialog, DialogV2, RemoveButton } from 'saga-library/src'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { useMutation } from '@apollo/client'
import { UPDATE_TASK_TYPE } from '../../../../graphql-definitions'
import { TaskTypeInput } from '../../../../types/tasks/TaskType'
import { TaskTypeForm } from './TaskTypeForm'
import { taskTypeDefaults } from './TaskTypeDefaultValues'
import { schema } from './TaskTypeValidationsSchema'
import { DELETE_TASK_TYPE } from '../../../../graphql-definitions/tenant/inbox/TaskQueries'

const FORM_NAME = "edit_task_type_form"

export const EditTaskTypeDialog = ({ taskType, setTaskType }) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showWarningAlert, showErrorAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()
  const [ openConfirmation, setOpenConfirmation ] = useState<boolean>(false)
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)

  const [updateTaskType] = useMutation(UPDATE_TASK_TYPE)
  const [deleteTaskType] = useMutation(DELETE_TASK_TYPE, {
    onCompleted: (data) => {
      showSuccessAlert('Task type has been deleted.')
      setOpenConfirmation(false)
      onClose()
    },
    onError: (error: any) => {
      console.error(JSON.stringify(error, null, 2))
      const errors = error.networkError?.result?.errors || []
      if (errors.length > 0 && errors[0].extensions?.userError === true) {
        showWarningAlert(errors[0].message)
      } else {
        showErrorAlert("Task type couldn't be deleted.")
      }
    }
  })

  const formMethods = useForm<TaskTypeInput>({
    defaultValues: taskType,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  useEffect(() => {
    if (taskType) {
      reset(taskType)
    }
  }, [taskType])

  const onClose = () => {
    setTaskType(null)
    reset(taskTypeDefaults)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (taskType) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [taskType, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = handleSubmit(async(data: TaskTypeInput) => {
    const typeId = data.id
    const input = { type: data.type, version: data.version }

    await updateTaskType({
      variables: {
        tenantId: tenant_id,
        id: typeId,
        input: input
      },
      onCompleted: () => {
        showSuccessAlert("Task type has been saved.")
        onClose()
      },
      onError: (error: any) => {
        const errors = error.networkError?.result?.errors || []
        if (errors.length > 0 && errors[0].extensions?.userError === true) {
          showErrorAlert(errors[0].message)
        } else {
          showErrorAlert("Task type couldn't be saved.")
        }
      }
    })
  })

  const onDelete = async() => {
    deleteTaskType({
      variables: {
        tenantId: tenant_id,
        taskTypeId: taskType?.id,
        version:  taskType?.version,
      }
    })
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  return (
    <DialogV2
      dataTestId={'edit-task-type-dialog'}
      formName={FORM_NAME}
      size={'xs'}
      title={'Edit task type'}
      submitting={isSubmitting}
      onClose={onCancel}
      open={!!taskType}
      preventScroll={true}
      headerActions={[
        <RemoveButton key={'edit-task-type-delete-button'} onClick={() => setOpenConfirmation(true)} dataTestId={`delete_type_${taskType?.id}`} />
      ]}
    >
      <FormProvider {...formMethods}>
        <TaskTypeForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"edit-task-type-form"}
        />
      </FormProvider>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={()=> setOpenConfirmation(false)}
        title={"Delete task type?"}
        message={"Once deleted, this task type can’t be used in future tasks. Existing tasks using this task type won’t be affected. This action can’t be undone."}
        primaryAction={onDelete}
        primaryLabel={"Delete"}
        dataTestId={`delete_type_${taskType?.id}-deleteDialog`}
      />
    </DialogV2>
  )
}