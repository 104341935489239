import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import SkillSelect from "../../../../../components/SkillSelect/SkillSelect";

interface BatchControlledSkillSelectProps {
  autoFocus?: boolean
  dataTestId: string
}

export const BatchControlledSkillSelect = ({
   autoFocus,
  dataTestId
 }: BatchControlledSkillSelectProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <SkillSelect
      autoFocus={autoFocus}
      provinceCode={'AB'}
      sx={batchUpdateStyleSearch(dirtyFields['skillIds'])}
      dataTestId={dataTestId}
    />
  )
}
