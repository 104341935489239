import moment from 'moment-timezone'
import { PayToCode } from '../../../utils/ABClaimConstants'


export const claimDefaults = {
  version: "0",
  actionCode: null,
  billingProfile: null,
  facility: null,
  functionalCenter: null,
  referralPractitioner: null,
  serviceCode: null,
  diagnosticCodes: [],
  serviceCodeFeeModifiers: [],
  patient: null,
  locationCode: "",
  text: "",
  serviceDate: moment().format(),
  calls: 1,
  encounter: 1,
  paperDocumentation: false,
  confidential: false,
  originalEncounterDate: null,
  originalFacility: null,
  originalLocationCode: "",
  newBornCode: null,
  guardianUli: null,
  guardianRegNumber: "",
  skill: null,
  payToCode: PayToCode.BAPY,
  payToUli: null,
  interceptReason: "",
  patientFirstName: "",
  patientLastName: "",
  patientPHN: "",
  patientDOB: null,
  patientGenderId: "",
  feeMod1Units: null,
  feeMod2Units: null,
  feeMod3Units: null,
  hospitalAdmissionDate: moment().format(),
  customClaimAmount: null
}

export default claimDefaults