import { Checkbox, ConfirmationDialog, Form, Province } from 'saga-library/src'
import { AutocompleteValue, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { SectionSubHeader } from 'saga-library/src/components/Section/Section'
import {
  AbLabResult,
  AbLabResultException,
  AbLabResultPractitionerIdentifier, AbLabResultPractitionerIdentifierInput,
  PatientSearchResult
} from '../../../../types/patients'
import { PatientLookup } from '../../../../components/PatientLookup/PatientLookup'
import { getNewPatientDefaults } from '../../../patients/components/labAndInvestigations/LabAndInvestigationsUtil'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import { PatientDetails } from './LabsAndInvestigationsDashboardExceptionModal'
import { useTenantContext } from '../../../../providers/TenantContextProvider'
import { useMutation, useQuery } from '@apollo/client'
import { GET_GENDERS, GET_PATIENT_LAB_AND_INVESTIGATIONS, LINK_AB_LAB_RESULT } from '../../../../graphql-definitions'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { LIST_AB_LAB_RESULT_EXCEPTION_PRACTITIONERS } from '../../../../graphql-definitions/tenant/DashboardQueries'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { useFieldArray, useFormContext } from 'saga-library/src/components/Form'
import {
  AbLabResultPractitionerIdentifierInputs
} from '../../../../types/patients/labAndInvestigation/LabAndInvestigation'

const NO_PRACTITIONER_WARNING = "This lab result won't be linked to a practitioner."

interface LabsAndInvestigationsDashboardExceptionModalFormProps {
  formName: string
  labResult?: AbLabResultException
  dataTestId: string
  onLinked: (any) => void
}

export const LabsAndInvestigationsDashboardExceptionModalForm = ({
  formName,
  labResult,
  dataTestId,
  onLinked
} : LabsAndInvestigationsDashboardExceptionModalFormProps) => {
  const { tenantIdentifiers } = useTenantContext()
  const { getTenantProvince } = useAccountContext()
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()

  const [defaultTValue, setDefaultTValue] = useState<AutocompleteValue<PatientSearchResult, false, false, false> | undefined>();
  const [practitionerWarning, setPractitionerWarning] = useState<string|undefined>(undefined)
  const [practitionerIdentifiers, setPractitionerIdentifiers] = useState<AbLabResultPractitionerIdentifier[]|null>(null)

  const province: Province = getTenantProvince(tenant_id)

  const { data: genderData } = useQuery(GET_GENDERS, {
    fetchPolicy: 'cache-first',
    variables: { provinceCode: province?.code ?? "AB" },
  })

  const { loading: isLoadingPractitioners } = useQuery(LIST_AB_LAB_RESULT_EXCEPTION_PRACTITIONERS, {
    skip: !labResult || !labResult.practitionerException,
    variables: {
      tenantId: tenant_id,
      abLabResultId: labResult?.abLabResult?.id
    },
    onCompleted: (data) => {
      const practitionerIdentifiers = _get(data, 'tenant.dashboard.listAbLabResultExceptionPractitioners', [])
      setPractitionerIdentifiers(practitionerIdentifiers)
      resetForm(practitionerIdentifiers)
    }
  })

  const [ linkAbLabResult ] = useMutation(LINK_AB_LAB_RESULT,{
    onCompleted: async (data: AbLabResultException) => {
      showSuccessAlert('lab result has been linked')
      const linkedAbLabResult: any = _get(data, 'tenant.dashboard.linkAbLabResult')
      onLinked(linkedAbLabResult)
    },
    onError: async (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Lab result could not be linked.')
    },
  })

  const { reset, handleSubmit, getValues } = useFormContext();

  const resetForm = (identifiers) => {
    const practitionerIdentifiers = (!identifiers || identifiers.length === 0) && labResult?.practitionerException
      ? [ { id: '', identifier: '', orderingFacilityId: "", savePractitionerLink: false} ]
      : identifiers?.map(i => {
          return {
            id: i.id,
            identifier: i.identifier,
            orderingFacilityId: i.orderingFacilityId,
            savePractitionerLink: true
          }
        })

    reset({
      patient: labResult?.abLabResult?.patientId ? { id: labResult?.abLabResult?.patientId } : undefined,
      practitionerIdentifiers: practitionerIdentifiers
    })
  }

  useEffect(() => {
    resetForm(null)
    const patientIdentifier = labResult?.abLabResult.patientIdentifiers?.[0]?.label;
    setDefaultTValue({
      id: '',
      active: true,
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      email: '',
      primaryPhoneNumber: '',
      primaryIdentifier: '',
      primaryIdentifierLabel: patientIdentifier || '',
      resultType: '',
      patientNotes: [],
      identifiers: [],
      province: ''
    } as AutocompleteValue<PatientSearchResult, false, false ,false>);
  }, [labResult]);

  const PatientRowDetails = (result: AbLabResultException|undefined, key) => {
    const showPatientSelect = result ? !Boolean(result.abLabResult.patientId) : false

    if(!showPatientSelect) return <></>
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
      >
        <SectionSubHeader
          variant={'h3'}
          dataTestId={`${dataTestId}-sectionSubHeader-patient`}
          sx={{ m: 0 }}
        >
          Patient
        </SectionSubHeader>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            gap: '16px',
            flex: '1 1 auto',
          }}
        >
          <Box
            sx={{
              pt: 1,
              minWidth: '208px'
            }}
          >
            <PatientDetails result={result} highlightText={false}/>
          </Box>
          <PatientLookup
            key={'patient-select' + key}
            defaultTValue={defaultTValue ? defaultTValue : undefined}
            getNewDefaults={result ? () => getNewPatientDefaults(result?.abLabResult, tenantIdentifiers, genderData?.static?.gendersByProvince) : undefined}
            name={"patient"}
            label={"Patient"}
            sx={{ width: '408px' }}
            patientLabelSx={{ flexGrow: 1, width: '360px' }}
            patientLabelVariant={'md'}
            dataTestId={`${dataTestId}-patient`}
          />
        </Box>
      </Box>
    )
  }

  const PractitionerRowDetails = (practitionerIdentifierInput: AbLabResultPractitionerIdentifierInput | null, index: number) => {
    const showPractitionerSelect = labResult?.practitionerException
    const practitionerIdentifier = practitionerIdentifiers?.find(i => i.identifier === practitionerIdentifierInput?.identifier)
    const practitionerName = practitionerIdentifier ? practitionerDisplayName(practitionerIdentifier.firstName ?? "", practitionerIdentifier.lastName ?? "") : ""

    return (
      showPractitionerSelect && (
        <Box
          key={`practitionerRow-${labResult?.abLabResult?.id}-${index}`}
          display = { 'flex' }
          sx = {{
            gap: '16px',
            height: '96px',
            alignItems: 'center',
            alignSelf: 'stretch'
          }}
        >
          <Box
            key={`practitionerRowName-${labResult?.abLabResult?.id}-${index}`}
            sx = {{
              minWidth: '208px',
              display: practitionerIdentifier ? 'auto' : 'none',
              mb: '48px'
            }}
          >
            { practitionerIdentifier && practitionerName }
          </Box>
          <Box sx={{ minWidth: '408px', flexGrow: 1 }}>
            <PractitionerSelect
              key={`${practitionerIdentifierInput?.practitionerId ? '' : 'practitionerId'}-${labResult?.abLabResult?.id}-${index}`}
              name={`practitionerIdentifiers.${index}.practitionerId`}
              label={"Practitioner"}
              dataTestId={`${dataTestId}-${index}-practitionerSelect`}
            />
            { practitionerIdentifier &&
              <Checkbox
                key={`savePractitionerLink-${labResult?.abLabResult?.id}-${index}`}
                dataTestId={`${dataTestId}-${index}-savePractitionerLink`}
                name={`practitionerIdentifiers.${index}.savePractitionerLink`}
                label={'This link will be remembered and used in the future.'}
                formControlLabelSx={{ ml: 0, mt: -1 }}
              />
            }
          </Box>
        </Box>
      )
    )
  }

  const linkAsync = async (data) => {
    setPractitionerWarning(undefined)

    const input = {
      version: labResult?.abLabResult.version,
      abLabResultId: labResult?.abLabResult.id,
      patientId: data.patient ? data.patient.id : labResult?.abLabResult.patientId,
      practitionerIdentifiers: data.practitionerIdentifiers
    }

    await linkAbLabResult({
      variables: {
        tenantId: tenant_id,
        abLabResultLink: input
      },
      update: (cache) => {
        if (!input) return
        cache.updateQuery({
            query: GET_PATIENT_LAB_AND_INVESTIGATIONS,
            variables: { tenantId: tenant_id, patientId: input.patientId },
            returnPartialData: true
          },
          (data) => {
            const currentList = _get(data, 'tenant.patient.labAndInvestigation.list', null) as AbLabResult[] | null
            if (!currentList) return data
            return {
              tenant: {
                ...data.tenant,
                patient: {
                  ...data.tenant.patient,
                  labAndInvestigation: {
                    ...data.tenant.patient.labAndInvestigation,
                    list: [
                      ...currentList.filter(l => l.id !== input.abLabResultId),
                      { ...labResult?.abLabResult, patientId: input.patientId}
                    ]
                  }
                }
              }
            }
          }
        )
      }
    })
  }

  const onSubmit = handleSubmit(
    async (data) => {
      if (labResult?.practitionerException && !data.practitionerIdentifiers.find(i => !!i.practitionerId)) {
        setPractitionerWarning(NO_PRACTITIONER_WARNING)
      }
      else {
        await linkAsync(data)
      }
    },
    async (errors) => {
      console.log(errors)
      const errorMessage = errors.patient
        ? "Patient is required."
        : "There was an error linking patient and/or practitioner.";

      showErrorAlert(errorMessage)
    }
  )

  const PatientIdentifierSection = () => {
    return PatientRowDetails(labResult, labResult?.abLabResult?.patientId)
  }

  const PractitionerIdentifiersSection = () => {
    const { control } = useFormContext<AbLabResultPractitionerIdentifierInputs>();
    const { fields} = useFieldArray<AbLabResultPractitionerIdentifierInputs>({
      name: 'practitionerIdentifiers',
      control,
    })

    if(isLoadingPractitioners) return <LoadingSpinner />
    if(labResult?.practitionerException && !!practitionerIdentifiers) {
      return (
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'8px'}
          key={`practitionerSection-${labResult?.abLabResult?.id}`}
        >
          <SectionSubHeader
            variant={'h3'}
            dataTestId={`${dataTestId}-sectionSubHeader-practitioner`}
            sx={{ m: 0 }}
          >
            Practitioners
          </SectionSubHeader>
          {
            fields.map((item, index) => PractitionerRowDetails(item, index))
          }
        </Box>
      )
    }
    else {
      return <></>
    }
  }

  const PractitionerConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={practitionerWarning !== undefined}
        title={'No practitioners linked'}
        message={practitionerWarning}
        primaryLabel={'OK'}
        primaryAction={async () => {
          await linkAsync(getValues())
        }}
        onClose={() => {
          setPractitionerWarning(undefined)
        }}
      />
    )
  }

  return (
    <>
      { practitionerWarning && <PractitionerConfirmationDialog /> }
      <Form
        id={formName}
        name={formName}
        onSubmit={onSubmit}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'16px'}
        >
          <PatientIdentifierSection />
          <PractitionerIdentifiersSection />
        </Box>
      </Form>
    </>
  )
}