import React from 'react'
import { Box, Paper } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Typography } from "../Typography";

type TenantSelectionItemProps = {
  id: string | null | undefined,
  isActive: boolean,
  onClick: (id: string | null | undefined) => void,
  tenant: any,
  display?: string,
  dataTestId?: string
}

export const TenantSelectionItem = ({
  id,
  isActive,
  onClick,
  tenant,
  display,
  dataTestId
}: TenantSelectionItemProps) => {
  return (
    <Paper
      data-testid={dataTestId}
      elevation={3}
      sx={ theme => ({
        width: `calc(100% - ${theme.spacing(2)})`,
        m: theme.spacing(1, 0),
        p: 1,
        cursor: 'pointer',
        borderColor: 'primary.main',
        backgroundColor: isActive ? 'primary.main' : 'white' ,
        borderWidth: '3px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        color: isActive ? 'white': 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        "&:hover": {
          backgroundColor: 'backgrounds.hover'
        }
      })}
      onClick={() => onClick(id)}
      key={id}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              mr: -3,
              textAlign: 'center'
            }}
            className={'text-wrapper'}
          >
            <Typography fontWeight={"bold"} >
              {display ? display : tenant?.name || tenant?.id}
            </Typography>
            <Typography fontWeight={"bold"} >
              {tenant?.province?.name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            visibility: isActive ? 'visible' : 'hidden',
            width: '24px',
            maxWidth: '24px',
          }}
        >
          <CheckCircleIcon sx={{ alignSelf: 'center' }} />
        </Box>
      </Box>
    </Paper>
  )
}

export default TenantSelectionItem
