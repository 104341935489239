import { gql } from "@apollo/client";

export const GET_TENANT_IDENTIFIERS = gql`
  query ListTenantIdentifiers($tenantId: ID!) {
    tenant(id: $tenantId) {
      identifier {
        list {
          id
          name
          label
          province
          isDefault
          isPrimary
          formatMask
          alwaysDisplayField
          formatMask
          version
        }
      }
    }
  }
`


export const UPDATE_IDENTIFIER = gql`
  mutation UpdateIdentifier(
    $tenantId: ID!
    $identifierId: ID!
    $alwaysDisplayField: Boolean!
    $version: String!
  ) {
    tenant(id: $tenantId) {
      identifier {
        update(
            id: $identifierId
            input: { alwaysDisplayField: $alwaysDisplayField, version: $version }
        ) {
          id
          name
          label
          province
          isDefault
          isPrimary
          formatMask
          alwaysDisplayField
          formatMask
          version
        }
      }
    }
  }
`
