import React from 'react'
import { BatchUpdateClaimBaseDetails } from './BatchUpdateClaimBaseDetails'

export const BatchUpdateBase = ({
  control,
  resetField,
  getValues,
  setValue,
  batchUpdateMultiple,
  register,
  dataTestId
}) => {
  return (
      <BatchUpdateClaimBaseDetails.Form
        control={control}
        resetField={resetField}
        getValues={getValues}
        setValue={setValue}
        batchUpdateMultiple={batchUpdateMultiple}
        register={register}
        dataTestId={dataTestId}
      />
  )
}
