import React, { ReactNode } from "react";
import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { FormHelperText, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export interface CheckboxProps {
  name: string
  label: string | ReactNode
  rules?: object
  sx?: SxProps<Theme>,
  formControlLabelSx?: SxProps<Theme>,
  inputProps?: object
  onSelect?: () => void
  disabled?: boolean
  errors?: any
  size?: 'small' | 'medium'
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start' | undefined
  dataTestId?: string
}

export default function Checkbox({
  name,
  label,
  rules,
  sx,
  formControlLabelSx,
  inputProps,
  onSelect = () => {},
  disabled = false,
  errors,
  labelPlacement = 'end',
  size = 'medium',
  dataTestId
}: CheckboxProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <SimpleCheckbox
            dataTestId={dataTestId}
            label={label}
            sx={sx}
            formControlLabelSx={formControlLabelSx}
            inputProps={inputProps}
            onChange={(event) => {
              onChange(event.target.checked)
              onSelect()
            }}
            disabled={disabled}
            errors={errors}
            value={value}
            inputRef={ref}
            size={size}
            labelPlacement={labelPlacement}
          />
        )
      }}
    />
  )
}

export interface SimpleCheckboxProps {
  label: string | ReactNode
  sx?: SxProps<Theme>
  formControlLabelSx?: SxProps<Theme>
  inputProps?: object
  onChange?: (e) => void
  disabled?: boolean
  errors?: any
  value?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  size?: 'small' | 'medium'
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start' | undefined
  dataTestId?: string
}
export const SimpleCheckbox = ({
  label,
  sx,
  formControlLabelSx,
  inputProps,
  onChange,
  disabled,
  errors,
  value,
  inputRef,
  size,
  labelPlacement = 'end',
  dataTestId
} : SimpleCheckboxProps ) => {
  return (
    <>
      <FormControlLabel
        disabled={disabled}
        control={
          <MuiCheckbox
            data-testid={`${dataTestId}-checkbox`}
            sx={{ color: 'primary', ...sx }}
            checked={value || false}
            onChange={onChange}
            inputRef={inputRef}
            inputProps={inputProps}
            size={size}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        sx={formControlLabelSx}
        data-testid={dataTestId}
      />
      {errors && <FormHelperText data-testid={`${dataTestId}-helperText`} error sx={{marginLeft: '40px'}}>
        {errors}
      </FormHelperText>}
    </>
  )
}
