import React from 'react'
import { useParams } from 'react-router-dom'
import { Permission, PermissionType } from '../types/settings/Permission'
import { useAccountContext } from '../providers/AccountContext'
import { usePageTitleContext } from '../providers/PageTitleContextProvider'
import { Box, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { Section } from 'saga-library/src'
import {
  LabsAndInvestigationsDashboardException
} from '../apps/dashboard/components/LabsAndInvestigations/LabsAndInvestigationsDashboardException'
import { AppointmentsDashboard } from '../apps/dashboard/components/Appointments/AppointmentsDashboard'
import { useTenantContext } from '../providers/TenantContextProvider'
import { AbClaimsDashboard } from '../apps/dashboard/components/AbClaims/AbClaimsDashboard'

export const Dashboard = React.memo(() => {
  const { tenant_id } = useParams()

  const { getLocationName, userId } = useAccountContext()
  const { setAreaTitle } = usePageTitleContext()
  const locationName = getLocationName()

  const { userHasPermission } = useAccountContext()
  const hasChartPermissions = (!!tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))
  const hasBillingPermissions = (!!tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READONLY))

  const { practitioners } = useTenantContext()
  const userPractitionerId = practitioners.find(p => p.userId === userId)?.id

  React.useEffect(() => {
    setAreaTitle('Dashboard')
  }, [setAreaTitle])

  const hasPermission = !!userPractitionerId || hasChartPermissions || hasBillingPermissions

  if (hasPermission) {
    return (
      <Box display={'flex'} flexDirection={'row'} height={'100%'}>
        {!!userPractitionerId && <AppointmentsDashboard practitionerId={userPractitionerId} />}
        <Section.Container sx={{ flexDirection: "column", gap: 1, maxWidth: '780px' }}>
          {hasChartPermissions && <LabsAndInvestigationsDashboardException />}
          {hasBillingPermissions && <AbClaimsDashboard practitionerId={userPractitionerId} />}
        </Section.Container>
      </Box>
    )
  }

  return (
    <Box>
      <Typography variant={"h2"}>{generateGreeting()}</Typography>
      <Box sx={{ mt: 2, textAlign: "left" }}>{locationName}</Box>
    </Box>
  )
})

const generateGreeting = () => {
  const hour = moment().hour()

  if (hour > 16) {
    return 'Good evening'
  }

  if (hour > 11) {
    return 'Good afternoon'
  }

  return 'Good morning'
}
