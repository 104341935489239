import { Box } from '@mui/material'
import { FilterSelect, LoadingButton, Section } from 'saga-library/src'
import React from 'react'
import { FilterType } from './LabsList'
import { AbLabResult } from '../../../../types/patients'
import {
  LabAndInvestigationLabel,
  LabAndInvestigationPatientLabel
} from '../../../patients/components/labAndInvestigations/components/LabAndInvestigationLabel'
import AddTask from '@mui/icons-material/AddTask'
import IconButton from '@mui/material/IconButton'

interface LabsHeaderProps {
  setFilters: (event: any, filterType: FilterType) => void,
  dataTestId: string,
}

export const LabsHeader = ({
  setFilters,
  dataTestId
}: LabsHeaderProps) => {
  const filterLabsOptions = [
    { label: 'Labs and investigations', value: '*' },
    { label: 'Labs', value: 'labs' },
    { label: 'Investigations', value: 'investigations' }
  ]

  const filterLabsReviewOptions = [
    { label: 'Unreviewed', value: 'unreviewed' },
    { label: 'Reviewed', value: 'reviewed' }
  ]

  const filterLabsNormalOptions = [
    { label: 'Normal and abnormal', value: '*' },
    { label: 'Normal', value: 'normal' },
    { label: 'Abnormal', value: 'abnormal' }
  ]

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
    >
      <FilterSelect
        options={filterLabsOptions}
        onSelect={(event) => setFilters(event, 'labsAndInvestigations')}
        defaultSelectedValue={'*'}
        dataTestId={`${dataTestId}-list-labs-filter`}
      />
      <FilterSelect
        options={filterLabsReviewOptions}
        onSelect={(event) => setFilters(event, 'unreviewed')}
        defaultSelectedValue={'unreviewed'}
        dataTestId={`${dataTestId}-list-labs-review-filter`}
      />
      <FilterSelect
        options={filterLabsNormalOptions}
        onSelect={(event) => setFilters(event, 'normalAndAbnormal')}
        defaultSelectedValue={'*'}
        dataTestId={`${dataTestId}-list-labs-normal-filter`}
      />
    </Box>
  )
}

interface LabResultHeaderProps {
  lab: AbLabResult,
  formName: string,
  onMarkAsReviewedClicked: () => void,
  onMarkAsUnreviewedClicked: () => void,
  loading: boolean,
  dataTestId: string,
  isReviewed?: boolean | undefined,
  setTaskModalOpen: (open: boolean) => void,
}

export const LabsResultHeader = ({
  lab,
  formName,
  onMarkAsReviewedClicked,
  onMarkAsUnreviewedClicked,
  loading,
  dataTestId,
  isReviewed,
  setTaskModalOpen,
}: LabResultHeaderProps) => {
  const handleOnClick = () => {
    setTaskModalOpen(true)
  }

  const getHeaderButtons = () => {
    return (
      !isReviewed && !loading
      ? <LoadingButton
        form={formName}
        type={'button'}
        name={'markAsReviewedButton'}
        variant={'contained'}
        disabled={loading}
        loading={loading}
        onClick={onMarkAsReviewedClicked}
        data-testid={`${dataTestId}-mark-reviewed-button`}
      >
        MARK AS REVIEWED
      </LoadingButton>
      : <LoadingButton
        form={formName}
        type={'button'}
        name={'markAsUnreviewedButton'}
        variant={'contained'}
        disabled={loading}
        loading={loading}
        onClick={onMarkAsUnreviewedClicked}
        data-testid={`${dataTestId}-mark-unreviewed-button`}
      >
        MARK AS UNREVIEWED
      </LoadingButton>
    )
  }

  const headerButtons = () => {
    return (
      <>
        <IconButton
          type={'submit'}
          form={formName}
          color={'primary'}
          disabled={loading}
          onClick={handleOnClick}
          data-testid={'inbox-lab-create-task-modal-button'}
        >
          <AddTask />
        </IconButton>
        <LoadingButton
          form={formName}
          type={'submit'}
          name={'SaveButton'}
          variant={'outlined'}
          disabled={loading}
          loading={loading}
          data-testid={`${dataTestId}-save-button`}
        >
          SAVE
        </LoadingButton>
        {getHeaderButtons()}
      </>
    )
  }


  return (
    lab && (
      <>
        <Box display={'flex'} alignItems={'center'} gap={1} mb={1}>
          <Section.Header
            dataTestId={`${dataTestId}-sectionLabel`}
            sx={{ mb: 0, flex: '1 1 100%' }}
          >
            <LabAndInvestigationLabel labAndInvestigation={lab} />
          </Section.Header>
          {headerButtons()}
        </Box>
        <LabAndInvestigationPatientLabel
          labAndInvestigation={lab}
          sx={{
            mt: 1,
            display: 'flex'
          }}
        />
      </>
    )
  )
}