import { gql } from "@apollo/client";

export const ADD_NEW_PATIENT = gql`
  mutation AddNewPatient($tenantId: ID!, $patientData: PatientInput!) {
    tenant(id: $tenantId) {
      patient {
        createPatient(patientData: $patientData)
      }
    }
  }
`

const PATIENT_ADDRESS_DETAILS_FRAGMENT = gql`
  fragment PatientResultAddressDetails on PatientResult {
    street
    street2
    street3
    city
    province
    postalCode
    country
  }
`

const PATIENT_PERSONAL_DETAILS_FRAGMENT = gql`
  fragment PatientResultPersonalDetails on PatientResult {
    lastName
    firstName
    middleName
    gender {
      name
    }
    dob
  }
`

export const PATIENT_LABEL_FRAGMENT = gql`
  ${PATIENT_PERSONAL_DETAILS_FRAGMENT}
  ${PATIENT_ADDRESS_DETAILS_FRAGMENT}
  fragment PatientLabelFragment on PatientResult {
    id
    primaryIdentifier
    primaryIdentifierLabel
    primaryPhoneNumber
    primaryPractitioner {
      id
      firstName
      lastName
    }
    email
    phones {
      id
      number
      extension
      type
    }
    patientNotes {
      id
      noteText
    }
    lastAppointmentDate
    nextAppointmentDate
    ...PatientResultPersonalDetails
    ...PatientResultAddressDetails
  }
`

export const GET_PATIENT_LABEL = gql`
    ${PATIENT_LABEL_FRAGMENT}
    query GetPatientLabel($tenantId: ID!, $patientId: ID!) {
        tenant(id: $tenantId) {
            patient(id: $patientId) {
                label {
                    ...PatientLabelFragment
                }
            }
        }
    }
`

export const GET_PATIENT_IDENTIFIER = gql`
  query GetPatientIdentifier($tenantId: ID!, $patientIdentifierId: ID!) {
    tenant(id: $tenantId) {
      patientIdentifier {
        get(patientIdentifierId: $patientIdentifierId) {
          id
          typeId
          value
          label
        }
      }
    }
  }
`