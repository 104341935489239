import { LinkedDocumentType } from "../Document";
import { Audit } from '../Audit'
import { Practitioner } from '../settings'
import { Appointment } from '../schedule'
import {
  PatientVitalsBloodPressure, PatientVitalsHeadCircumference,
  PatientVitalsHeartRate, PatientVitalsLengthHeight,
  PatientVitalsWeight,
} from '../../apps/patients/components/vitals/VitalTypes'
import { User } from '../account'

export type EncounterNoteInput = {
  id?: string
  patientId?: string
  templateId?: string,
  appointmentId?: string | null,
  practitionerId?: string | null,
  encounterNoteDate?: Date,
  signOffUserId?: string | null,
  fields?: EncounterNoteField[]
  version?: string
}

export type EncounterNote = EncounterNoteInput & {
  encounterNoteDisplayDate: Date,
  template?: EncounterNoteTemplate,
  appointment?: Appointment,
  practitioner?: Practitioner,
  signOffPit?: string | null,
  signOffUser?: User | null,
  description?: string,
  isLinkedDocument?: boolean,
  linkedDocument?: LinkedDocumentType,
  audit?: Audit
}

export type EncounterNoteField = {
  id?: string,
  templateFieldId: string,
  data?: string,
  isChecked?: boolean,
  additionalFieldOrder?: number,
  patientVitalsWeight?: PatientVitalsWeight,
  patientVitalsHeartRate?: PatientVitalsHeartRate,
  patientVitalsLengthHeight?: PatientVitalsLengthHeight,
  patientVitalsBloodPressure?: PatientVitalsBloodPressure,
  patientVitalsHeadCircumference?: PatientVitalsHeadCircumference
  version?: string
}

export type EncounterNoteFieldProps = {
  name: string
  isSigned?: boolean
  disabled?: boolean
  dataTestId: string
}

export type EncounterNoteTemplate = {
  id: string,
  name: string,
  summaryFieldId?: string | null,
  fields?: EncounterNoteTemplateField[],
  fieldTree?: EncounterNoteTemplateFieldTree[],
  summaryField?: string
}

export type EncounterNoteTemplateField = {
  id: string,
  name: string,
  type: EncounterNoteTemplateFieldType,
  order: number,
  parentGroupId: string | null
}

export type EncounterNoteTemplateFieldTree = EncounterNoteTemplateField & {
  subFields: EncounterNoteTemplateField[]
  encounterNoteField?: EncounterNoteField
}

export enum EncounterNoteTemplateFieldType {
  GROUP = "GROUP",
  VITALS = "VITALS",
  TEXTBOX = "TEXTBOX",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
  CHECKBOX_TEXTBOX = "CHECKBOX_TEXTBOX",
  HEADER = "HEADER"
}