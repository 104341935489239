import React, { useState } from "react";
import { AddButton, RemoveButton, Spinner, TextField, Typography } from "saga-library/src";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import {  useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from "@apollo/client";
import { Box, InputAdornment, SxProps } from "@mui/material";
import _debounce from "lodash/debounce"
import { Theme } from "@mui/system";
import { CALCULATE_PRICE, GET_SKILLS } from "../../graphql-definitions";


interface ClaimAmountProps {
  name?: string
  label?: string
  sx?: SxProps<Theme>
  provinceCode?: string
  disabled?: boolean
  dataTestId: string
}

export const ClaimAmount = ({
  provinceCode = 'AB',
  name = 'customClaimAmount',
  label = 'Custom claim amount',
  disabled = false,
  dataTestId
}: ClaimAmountProps) => {
  const { tenant_id } = useParams()
  const [skillDict, setSkillDict] = useState({})
  const [estimatedAmount, setEstimatedAmount] = useState(0)

  const { control, setValue } = useFormContext();

  const { data: skillsData } = useQuery(GET_SKILLS, {
    fetchPolicy: 'cache-first',
    variables: { provinceCode: provinceCode }
  })

  React.useEffect(() => {
    if (!skillsData) {
      return
    }
    const skillDict = skillsData.static.skillsByProvince.reduce((dict, skill) => {
      dict[skill.id] = skill.skillCode
      return dict
    }, {})
    setSkillDict(skillDict)
  }, [skillsData])

  const [calculatePrice, { loading: loadingPrice }] = useLazyQuery(
    CALCULATE_PRICE,
    {
      onCompleted: (data)=> {
        setEstimatedAmount(data.tenant.abClaim.calculatePrice ?? 0)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      },
      fetchPolicy: 'cache-first'
    }
  )

  let [
    serviceCode,
    serviceDate,
    patient,
    calls,
    serviceCodeFeeModifiers,
    facility,
    skillId,
    feeMod1Units,
    feeMod2Units,
    feeMod3Units,
    hospitalAdmissionDate,
    patientDOB,
    customClaimAmount
  ] = useWatch({
    name: [
      "serviceCode",
      "serviceDate",
      "patient",
      "calls",
      "serviceCodeFeeModifiers",
      "facility",
      "skillId",
      "feeMod1Units",
      "feeMod2Units",
      "feeMod3Units",
      "hospitalAdmissionDate",
      "patientDOB",
      "customClaimAmount"
    ], control})

  const getPrice = React.useRef(
    _debounce(async (data) => {
      await calculatePrice({
        variables: {
          tenantId: tenant_id,
          claimData: data,
        }
      })
    }, 1000)
  ).current

  React.useEffect(() => {
    return () => {
      getPrice.cancel()
    }
  }, [getPrice])

  React.useEffect(() => {
    if(serviceCode){
      const claimSkillCode = skillId != null ? skillDict[skillId] : null
      const priceCalcData = {
        serviceCodeId: serviceCode?.id,
        serviceDate: serviceDate,
        calls: parseInt(calls),
        serviceCodeFeeModifierIds: serviceCodeFeeModifiers?.map(fm => fm.id),
        feeMod1Units: parseInt(feeMod1Units),
        feeMod2Units: parseInt(feeMod2Units),
        feeMod3Units: parseInt(feeMod3Units),
        hospitalAdmissionDate: hospitalAdmissionDate,
        patientDOB: patient != null ? patient.dob : patientDOB,
        facilityType: facility?.facilityType,
        claimSkillCode: claimSkillCode
      }
      getPrice(priceCalcData)
    } else {
      setEstimatedAmount(0)
    }
  }, [
    serviceCode,
    serviceDate,
    patient,
    calls,
    serviceCodeFeeModifiers,
    facility,
    skillId,
    feeMod1Units,
    feeMod2Units,
    feeMod3Units,
    hospitalAdmissionDate,
    patientDOB
  ])

  const showCustomInput = () => {
    setValue(name, "")
  }

  const hideCustomInput = () => {
    setValue(name, null)
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} mb={2}>
        <Typography variant={'h4'} fontWeight={'400'} color={(theme) => theme.palette.greys.medium}>Estimated total:</Typography>
        <Typography variant={'h4'} fontWeight={'700'}>
          {loadingPrice ? (
            <Spinner size={"xs"} />
          ) : (
            <>${estimatedAmount.toFixed(2)}</>
          )}
        </Typography>
      </Box>
      {customClaimAmount != null ? (
        <Box display={'flex'} flexDirection={'row'} gap={'4px'}>
          <TextField
            autoFocus={true}
            name={name}
            label={label}
            type={'number'}
            disabled={disabled}
            fullWidth={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            dataTestId={dataTestId}
          />
          {!disabled && <RemoveButton
            onClick={hideCustomInput}
            sx={{my:1}}
            dataTestId={dataTestId}
          />}
        </Box>
      ) : (
        !disabled && <AddButton label={'Claim custom amount'} onClick={showCustomInput} dataTestId={dataTestId} />
      )}
    </Box>
  )
}

export default ClaimAmount

