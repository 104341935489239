import React from 'react'
import { Box, MenuItem } from "@mui/material";
import {
  Checkbox,
  Section,
  Select,
  TextField
} from "saga-library/src";
import { InterceptReason, PayToCode, PayToOptions } from "../../../../../utils/ABClaimConstants";
import { InterceptReasonSelect } from "../../../../../components/InterceptReasonSelect/InterceptReasonSelect";
import { useWatch } from "saga-library/src/components/Form";
import { MultipleValuesField } from "../components/MultipleValuesField";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import { BatchControlledNewbornCodeSelect } from "../components/BatchControlledNewbornCodeSelect";
import { BatchControlledTextFieldMask } from "../components/BatchControlledTextFieldMask";
import {
  batchUpdateStyleSearch,
  batchUpdateStyleSelect,
  batchUpdateStyleSelectLabel
} from "../components/BatchUpdateStyle";
import { BatchControlledSkillSelect } from "../components/BatchUpdateSkillSelect";


const Form = ({ control, errors, resetField, dirtyFields, batchUpdateMultiple, setValue, register, dataTestId }) => {
  const newBornCode = useWatch({
    control,
    name: 'newBornCode',
  })

  return (
    <Box display={'flex'} flexDirection={'row'} gap={2}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        sx={{ width: '100%' }}
      >
        <Section.FormSection label={'Options'}>
          <Checkbox
            name={'paperDocumentation'}
            dataTestId={`${dataTestId}-paperDocumentation`}
            label={'Paper documentation to follow'}
          />
          <Checkbox
            name={'confidential'}
            dataTestId={`${dataTestId}-confidential`}
            label={'Confidential'}
          />
        </Section.FormSection>

        <Section.FormSection label={'Original encounter information'}>
          <MultipleValuesField
            dataTestId={`${dataTestId}-originalEncounterDate`}
            label={'Original encounter date'}
            multipleValuesState={batchUpdateMultiple.originalEncounterDate}
            setUpdatedFields={() => setValue('originalEncounterDate', '', { shouldDirty: true })}
          >
            <BatchControlledDatePicker
              label={'Original encounter date'}
              name={'originalEncounterDate'}
              dataTestId={`${dataTestId}-originalEncounterDate`}
            />
          </MultipleValuesField>
          <MultipleValuesField
            dataTestId={`${dataTestId}-originalFacility`}
            label={'Facility'}
            multipleValuesState={batchUpdateMultiple.originalFacilityId}
            setUpdatedFields={() => {
              setValue('originalFacility', '', { shouldDirty: true })
            }}
          >
            <BatchControlledFacilityInput
              name={'originalFacility'}
              label={'Facility'}
              dataTestId={`${dataTestId}-originalFacility`}
            />
          </MultipleValuesField>
          <MultipleValuesField
            dataTestId={`${dataTestId}-originalLocationCode`}
            label={'Original location'}
            multipleValuesState={batchUpdateMultiple.originalLocationCode}
            setUpdatedFields={() => {
              setValue('originalLocationCode', '', { shouldDirty: true })
            }}
          >
            <BatchControlledLocationCodeSelect
              label={'Original location'}
              name={'originalLocationCode'}
              dataTestId={`${dataTestId}-originalLocationCode`}
            />
          </MultipleValuesField>
        </Section.FormSection>

        <Section.FormSection label={'Patient'}>
          <MultipleValuesField
            dataTestId={`${dataTestId}-newBornCode`}
            label={'Newborn'}
            multipleValuesState={batchUpdateMultiple.newBornCode}
            setUpdatedFields={() => {
              setValue('newBornCode', '', { shouldDirty: true })
            }}
          >
            <BatchControlledNewbornCodeSelect
              label={'Newborn'}
              name={'newBornCode'}
              dataTestId={`${dataTestId}-newBornCode`}
            />
          </MultipleValuesField>
          {
            newBornCode !== null && (
              <>
                <MultipleValuesField
                  dataTestId={`${dataTestId}-guardianUli`}
                  label={'Guardian/parent ULI'}
                  multipleValuesState={batchUpdateMultiple.guardianUli}
                  setUpdatedFields={() => {
                    setValue('guardianUli', '', { shouldDirty: true })
                  }}
                >
                  <BatchControlledTextFieldMask
                    label={'Guardian/parent ULI'}
                    name={'guardianUli'}
                    dataTestId={`${dataTestId}-guardianUli`}
                  />
                </MultipleValuesField>
                <MultipleValuesField
                  dataTestId={`${dataTestId}-guardianRegNumber`}
                  label={'Guardian/parent registration number'}
                  multipleValuesState={batchUpdateMultiple.guardianRegNumber}
                  setUpdatedFields={() => {
                    setValue('guardianRegNumber', '', { shouldDirty: true })
                  }}
                >
                  <TextField
                    name={'guardianRegNumber'}
                    label={'Guardian/parent registration number'}
                    type={"number"}
                    sx={batchUpdateStyleSearch(dirtyFields.guardianRegNumber)}
                    autoFocus={true}
                    dataTestId={`${dataTestId}-guardianRegNumber`}
                  />
                </MultipleValuesField>
              </>
            )
          }
        </Section.FormSection>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        sx={{
          width: '100%',
          overflowX: 'hidden'
        }}
      >
        <Section.FormSection label={'Practitioner'} sx={{ flex: '0 1 auto' }}>
          <MultipleValuesField
            dataTestId={`${dataTestId}-skill`}
            label={'Skill'}
            multipleValuesState={batchUpdateMultiple.skillId}
            setUpdatedFields={() => {
              setValue('skillId', '', { shouldDirty: true })
            }}
          >
            <BatchControlledSkillSelect dataTestId={`${dataTestId}-skill`} />
          </MultipleValuesField>
        </Section.FormSection>

        <Section.FormSection label={'Payment'}>
          <MultipleValuesField
            dataTestId={`${dataTestId}-payToCode`}
            label={'Pay to'}
            multipleValuesState={batchUpdateMultiple.payToCode}
            setUpdatedFields={() => {
              setValue('payToCode', PayToCode.BAPY, { shouldDirty: true })
            }}
          >
            <Select
              label={'Pay to'}
              name={'payToCode'}
              sx={batchUpdateStyleSelect(dirtyFields.payToCode)}
              inputLabelStyle={batchUpdateStyleSelectLabel(dirtyFields.payToCode)}
              dataTestId={`${dataTestId}-payToCode`}
            >
              {PayToOptions.map(({label, value}) =>
                <MenuItem key={value} value={value}>{label}</MenuItem>
              )}
            </Select>
          </MultipleValuesField>
          <MultipleValuesField
            dataTestId={`${dataTestId}-payToUli`}
            label={'Pay to ULI'}
            multipleValuesState={batchUpdateMultiple.payToUli}
            setUpdatedFields={() => {
              setValue('payToUli', '', { shouldDirty: true })
            }}
          >
            <TextField
              name={'payToUli'}
              label={'Pay to ULI'}
              type={'number'}
              sx={batchUpdateStyleSearch(dirtyFields.payToUli)}
              InputProps={register('payToUli', { valueAsNumber: true })}
              dataTestId={`${dataTestId}-payToUli`}
            />
          </MultipleValuesField>
          <MultipleValuesField
            dataTestId={`${dataTestId}-interceptReason`}
            label={'Intercept reason'}
            multipleValuesState={batchUpdateMultiple.interceptReason}
            setUpdatedFields={() => {
              setValue('interceptReason', InterceptReason.HOLD, { shouldDirty: true })
            }}
            >
          <InterceptReasonSelect
            name={'interceptReason'}
            label={'Intercept reason'}
            sx={batchUpdateStyleSelect(dirtyFields.interceptReason)}
            inputLabelStyle={batchUpdateStyleSelectLabel(dirtyFields.interceptReason)}
            dataTestId={`${dataTestId}-interceptReason`}
          />
          </MultipleValuesField>
        </Section.FormSection>
      </Box>
    </Box>
  )
}

export const BatchUpdateClaimOptions = {
  Form: Form,
}
