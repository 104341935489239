import { Controller, useFormContext } from "saga-library/src/components/Form";
import { useTenantContext } from "../providers/TenantContextProvider";
import React, { useEffect } from "react";
import { Autocomplete } from "saga-library/src";
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import { PractitionerListType, PractitionerSelectOption } from '../types/settings/Practitioner'
import { useAccountContext } from '../providers/AccountContext'

interface PractitionerSelectWithLocationProps {
  label: string,
  name: string,
  getOptionDisabled?: (option) => boolean
  disabled?: boolean,
  onChange?: (value) => void,
  dataTestId: string,
  sx?: SxProps<Theme>
}

export const PractitionerSelectWithLocation = (
{
  label='Practitioner',
  name,
  getOptionDisabled,
  disabled=false,
  onChange,
  dataTestId,
  sx
}:PractitionerSelectWithLocationProps) => {
  const { control } = useFormContext()
  const { practitioners } = useTenantContext()
  const { locationId: selectedLocationId } = useAccountContext()
  const [practitionerOptions, setPractitionerOptions] = React.useState<PractitionerSelectOption[]>([])
  const [seeMorePractitionersOptions, setSeeMorePractitionersOptions] = React.useState<PractitionerSelectOption[]>([])
  const [ isShowingMore, setIsShowingMore ] = React.useState(false)
  const [ showMoreHasBeenClicked, setShowMoreHasBeenClicked ] = React.useState(false)

  const getOptionStringFormat = (option: PractitionerSelectOption) => {
    return `${option.practitionerName}${option.locationId ? ` (${option.locationName})` : ''}`
  }

  const getOptionEqualToValue = (option: PractitionerSelectOption, value: PractitionerSelectOption) => {
    return option.practitionerId === value.practitionerId &&
      (option.locationId ? option.locationId === value.locationId : true)
  }

  const sortPractitionerList = (practitioners: PractitionerSelectOption[]) => {
    return practitioners.sort((a, b) => {
      if (a?.locationId === selectedLocationId && b?.locationId !== selectedLocationId) {
        return -1
      }
      if (b?.locationId === selectedLocationId && a?.locationId !== selectedLocationId) {
        return 1;
      }
      if (!a.locationId) {
        return 1
      }
      if (!b.locationId) {
        return -1
      }
      return a.locationId.localeCompare(b.locationId);
    });
  }

  useEffect(()=>{
    const options: PractitionerSelectOption[] = practitioners.flatMap((practitioner: PractitionerListType) => {
      if (practitioner.locations && practitioner.locations.length > 0) {
        return practitioner.locations.map(location => ({
          locationId: location.locationId,
          locationName: location.name,
          practitionerId: practitioner.id,
          practitionerName: practitionerDisplayName(practitioner.firstName, practitioner.lastName),
        }));
      } else {
        return [{
          practitionerId: practitioner.id,
          practitionerName: practitionerDisplayName(practitioner.firstName, practitioner.lastName),
        }];
      }
    });

    const practitionersAtCurrentLocation = options.filter(practitioner => practitioner?.locationId === selectedLocationId)
    setPractitionerOptions(practitionersAtCurrentLocation)

    const otherPractitioners = options.filter(practitioner => practitioner?.locationId !== selectedLocationId)
    setSeeMorePractitionersOptions(otherPractitioners)
  }, [practitioners])

  const handleLoadMore = () => {
    setIsShowingMore(true)
    setShowMoreHasBeenClicked(true)
    setPractitionerOptions((currentPractitioners) => {
      return sortPractitionerList(currentPractitioners.concat(seeMorePractitionersOptions))
    })
    setSeeMorePractitionersOptions([])
    return
  }

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field: { onChange: controlledOnChange, value = null }, fieldState: { error } }) => {
        if (value === '') {
          value = null
        }
        return (
          <Autocomplete
            name={name}
            value={value}
            label={label}
            loadMore={isShowingMore ? undefined : handleLoadMore}
            options={practitionerOptions}
            filterOptions={(options, state) => {
              if (state.inputValue === '' && !showMoreHasBeenClicked) {
                setIsShowingMore(false)
                return practitionerOptions
              }
              if(!showMoreHasBeenClicked) {
                setIsShowingMore(true)
              }
              return practitionerOptions
                .concat(seeMorePractitionersOptions)
                .filter(prac => prac.practitionerName.toLowerCase().includes(state.inputValue.toLowerCase()))
            }}
            onChange={(e, newValue) => {
              controlledOnChange(newValue ?? null)
              onChange?.(newValue ?? null)
            }}
            getOptionLabel={(option: PractitionerSelectOption) => {
              return getOptionStringFormat(option)
            }}
            isOptionEqualToValue={(option: PractitionerSelectOption, value: PractitionerSelectOption) => {
              return getOptionEqualToValue(option, value)
            }}
            getOptionDisabled={getOptionDisabled}
            OptionComponent={PractitionerOptionWithLocation}
            sx={{flex: '1 1 100%', ...sx}}
            disabled={disabled}
            onInputChange={(e, value, reason) => {
              if(reason === 'clear') {
                controlledOnChange('')
                onChange?.('')
              }
            }}
            error={error}
            helperText={error?.message}
            dataTestId={`practitionersSelectWithLocation-${dataTestId}`}
          />
        )
      }}
    />
  )
}


const PractitionerOptionWithLocation = ({props, data}) => {
  const theme = useTheme()
  return <Box
    {...props}
    display={'flex'}
    flexDirection={'column'}
    key={`${data.practitionerId}-${data?.locationId}`}
    sx={{
      alignItems:'flex-start !important'
    }}
  >
    <Typography variant={'body1'} color={theme.palette.greys.dark}>{data.practitionerName}</Typography>
    <Typography variant={'body2'} color={theme.palette.greys.medium}>{data?.locationName}</Typography>
  </Box>
}