import { gql } from "@apollo/client";
import { FILE_FRAGMENT } from '../file/FileQueries'
import { LAB_AND_INVESTIGATION_FRAGMENT } from '../patient/LabAndInvestigationQueries'
import { TASK_ASSIGNMENT_FRAGMENT } from './TaskQueries'

export const GET_REVIEW_COUNTS = gql`
  query GetReviewCounts($tenantId: ID!, $userId: ID, $roleId: ID) {
    tenant(id: $tenantId) {
      review {
        inboxCounts(userId: $userId, roleId: $roleId) {
          tasks
          labs
          documents
        }
      }
    }
  }
`

const REVIEW_DOCUMENT_FRAGMENT = gql`
  ${FILE_FRAGMENT}
  fragment ReviewDocumentFragment on ReviewDocument {
    id
    date
    isReviewed
    linkedDocument {
      id
      fileId
      category
      documentDate
      description
      file {
        ...FileDetails
      }
      audit {
        crePit
      }
      version
    }
    patient {
      id
      firstName
      lastName
    }
    user {
      id
      firstName
      lastName
    }
    version
  }
`

export const REVIEW_LAB_FRAGMENT = gql`
  ${LAB_AND_INVESTIGATION_FRAGMENT}
  fragment ReviewLabFragment on ReviewLab {
    id
    date
    isReviewed
    reviewUser {
      id
      firstName
      lastName
    }
    reviewDate
    abLabResult {
      ...LabAndInvestigationFragment
      patientId
    }
    version
  }
`

export const GET_PRACTITIONER_LAB_AND_INVESTIGATIONS = gql`
  ${REVIEW_LAB_FRAGMENT}
  query GetPractitionerLabAndInvestigations($tenantId: ID!, $userId: ID!) {
    tenant(id: $tenantId) {
      review {
        lab {
          list(id: $userId) {
            ...ReviewLabFragment
          }
        }
      }
    }
  }
`

export const UPDATE_REVIEW_LAB = gql`
  ${REVIEW_LAB_FRAGMENT}
  mutation UpdateReviewLab($tenantId: ID!, $input: ReviewLabInput!) {
    tenant(id: $tenantId) {
      review {
        lab {
          update(input: $input) {
            ...ReviewLabFragment
          }
        }
      }
    }
  }
`

export const GET_REVIEW_LAB_AND_INVESTIGATION = gql`
  ${REVIEW_LAB_FRAGMENT}
  query GetReviewLabAndInvestigation($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      review {
        lab {
          get(id: $id) {
            ...ReviewLabFragment
          }
        }
      }
    }
  }
`

export const GET_USER_DOCUMENTS = gql`
  ${REVIEW_DOCUMENT_FRAGMENT}
  query GetUserDocuments($tenantId: ID!, $userId: ID!) {
    tenant(id: $tenantId) {
      review {
        document {
          list(id: $userId) {
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
`

export const CREATE_REVIEW_DOCUMENT = gql`
  ${REVIEW_DOCUMENT_FRAGMENT}
  mutation CreateReviewDocument($tenantId: ID!, $input: ReviewDocumentInput!) {
    tenant(id: $tenantId) {
      review {
        document {
          create(input: $input) {
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
`

export const GET_REVIEW_DOCUMENT = gql`
  ${REVIEW_DOCUMENT_FRAGMENT}
  query GetReviewDocument($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      review {
        document {
          get(id: $id) {
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
`

export const MARK_DOCUMENT_REVIEWED = gql`
  ${REVIEW_DOCUMENT_FRAGMENT}
  mutation MarkDocumentReviewed($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      review {
        document {
          markReviewed(id: $id, version: $version) {
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
`

export const MARK_DOCUMENTS_REVIEWED = gql`
  mutation MarkDocumentsReviewed($tenantId: ID!, $documentIds: [ID!]!) {
    tenant(id: $tenantId) {
      review {
        document {
          markDocumentsAsReviewed(documentIds: $documentIds)            
        }
      }
    }
  }
`

export const MARK_LABS_REVIEWED = gql`
  mutation MarkLabsReviewed($tenantId: ID!, $labIds: [ID!]!) {
    tenant(id: $tenantId) {
      review {
        lab {
          markLabsAsReviewed(labIds: $labIds)
        }
      }
    }
  }
`


export const REFETCH_CURRENT_INBOX = gql`
  ${TASK_ASSIGNMENT_FRAGMENT}
  ${REVIEW_DOCUMENT_FRAGMENT}
  ${REVIEW_LAB_FRAGMENT}
  query RefetchInbox($tenantId: ID!, $roleId: ID, $userId: ID!, $getTasks: Boolean!, $getDocuments: Boolean!, $getLabs: Boolean!) {
    tenant(id: $tenantId) {
      task {
        assignment {
          list(roleId: $roleId, userId: $userId) @include(if: $getTasks){
            ...TaskAssignmentFragment
          }
        }
      }
      review {
        document {
          list(id: $userId) @include(if: $getDocuments) {
            ...ReviewDocumentFragment
          }
        }
        lab {
          list(id: $userId) @include(if: $getLabs){
            ...ReviewLabFragment
          }
        }
        inboxCounts(userId: $userId, roleId: $roleId) {
          tasks
          labs
          documents
        }
      }
    }
  }
`