import React, { useMemo, useState } from 'react'
import { LetterTemplateType } from '../types/patients/Letters'

interface EditorImageContextInterface {
  imageFilesInEditor: string[]
  setImageFilesInEditor: (imageFiles: string[]) => void
  disableSave: boolean
  addImageToEditor: (imageId: string) => void
  removeImageFromEditor: (imageId: string) => void
  setImageUploading: (loading: boolean) => void
}

const defaultEditorImageContext: EditorImageContextInterface = {
  imageFilesInEditor: [],
  setImageFilesInEditor: () => {},
  disableSave: false,
  addImageToEditor: () => {},
  removeImageFromEditor: () => {},
  setImageUploading: () => {},
}

const EditorImageContext = React.createContext(defaultEditorImageContext)

export const EditorImageProvider = ({ children, letterTemplate }:
  {
    children: React.ReactNode,
    letterTemplate?: LetterTemplateType | null
  }) => {
  const [disableSave, setDisableSave] = useState<boolean>(false)
  const [imageFilesInEditor, setImageFilesInEditor] = useState(
    [...(
      !!letterTemplate?.imageFileIds
        ? letterTemplate.imageFileIds
        : []
    )]
  )

  const setImageUploading = (loading: boolean) => {
    setDisableSave(loading)
  }

  const addImageToEditor = (imageId: string) => {
    setImageFilesInEditor((prev) => prev.includes(imageId) ? prev : [...prev, imageId])
  }

  const removeImageFromEditor = (imageId: string) => {
    setImageFilesInEditor((prev) => prev.filter((id) => id !== imageId))
  }

  const providerValues = useMemo(() => ({
    imageFilesInEditor,
    setImageFilesInEditor,
    disableSave,
    addImageToEditor,
    removeImageFromEditor,
    setImageUploading,
  }), [imageFilesInEditor, disableSave])

  return (
    <EditorImageContext.Provider value={providerValues}>
      { children }
    </EditorImageContext.Provider>
  )
}

export const useEditorImageContext = () => {
  return React.useContext(EditorImageContext)
}