import React from 'react'
import { useBackgroundQuery, useMutation } from '@apollo/client'
import _get from 'lodash/get'
import { ASSIGN_TASK, GET_PATIENT_TASK_ASSIGNMENTS, UPDATE_TASK } from '../../../graphql-definitions'
import { TaskAssignmentInput, TaskAssignmentType } from '../../../types/tasks/TaskAssignment'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _cloneDeep from 'lodash/cloneDeep'
import { cleanupTaskAssignmentData } from '../../../utils/TaskUtils'
import { DELETE_TASK } from '../../../graphql-definitions/tenant/inbox/TaskQueries'
import { TaskCategory } from '../../../types/tasks/TaskState'
import { TaskPriority, TaskPriorityMap } from '../../../types/tasks/TaskPriority'

const getPatientTaskQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.task.list', []) as Array<TaskAssignmentType>]
}

export const parsePatientTaskQueryResults = (data) => {
  return getPatientTaskQueryResults(data)
}

interface PatientTaskContextInterface {
  patientTasksRef: any,
  parsePatientTaskQueryResults: (any) => TaskAssignmentType[],
  createTaskFromChart: (TaskAssignmentInput: TaskAssignmentInput, onComplete) => Promise<void>,
  updateTaskFromChart: (TaskAssignmentInput: TaskAssignmentInput, onComplete) => Promise<void>,
  deleteTaskFromChart: (taskId: string, version: string, onComplete) => Promise<void>
}

const defaultPatientTaskContext: PatientTaskContextInterface = {
  patientTasksRef: null,
  parsePatientTaskQueryResults: parsePatientTaskQueryResults,
  createTaskFromChart: async () => {},
  updateTaskFromChart: async () => {},
  deleteTaskFromChart: async () => {}
}

const PatientTaskContext = React.createContext(defaultPatientTaskContext)

export const PatientTaskProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()

  const [ createTaskAssignment ] = useMutation(ASSIGN_TASK)
  const [ updateTaskAssignment ] = useMutation(UPDATE_TASK)
  const [ deleteTaskAssignment ] = useMutation(DELETE_TASK)
  const [ patientTasksRef ] = useBackgroundQuery(GET_PATIENT_TASK_ASSIGNMENTS, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const createTaskFromChart = async (taskInput: TaskAssignmentInput, onComplete) => {
    let input: TaskAssignmentInput = cleanupTaskAssignmentData(taskInput)

    await createTaskAssignment({
      variables:{
        tenantId: tenant_id,
        input: input
      },
      onCompleted: (data) => {
        showSuccessAlert("Task has been created.")
        if(onComplete){
          onComplete(_get(data, 'tenant.task.assignment.create'))
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Failed to create task")
      },
      update: (cache, { data }) => {
        updateCache(cache, data)
      }
    })
  }

  const updateTaskFromChart = async (taskInput: TaskAssignmentInput, onComplete) => {
    let input: TaskAssignmentInput = cleanupTaskAssignmentData(taskInput)
    const taskId = input?.id
    delete input.id
    delete input.state
    delete input.type

    await updateTaskAssignment({
      variables:{
        id: taskId,
        tenantId: tenant_id,
        input: input
      },
      onCompleted: (data) => {
        showSuccessAlert("Task has been saved.")
        if(onComplete){
          onComplete(_get(data, 'tenant.task.assignment.update'))
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Task couldn't be saved.")
      },
      update: (cache, { data }) => {
        updateCache(cache, data)
      }
    })
  }

  const deleteTaskFromChart = async (taskId: string, version: string, onComplete) => {
    await deleteTaskAssignment({
      variables: {
        tenantId: tenant_id,
        id: taskId,
        version: version
      },
      onCompleted: () => {
        showSuccessAlert("Task has been deleted.")
        if (onComplete) {
          onComplete()
        }
      },
      onError: () => {
        showErrorAlert("Task couldn't be deleted.")
      },
      update: (cache, { data }) => {
        const normalizedId = cache.identify({
          id: taskId,
          __typename: "TaskAssignment"
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const updateCache = (cache, data) => {
    const newTask = _get(data, 'tenant.task.assignment.create', null)
    if(!tenant_id || !patient_id) return
    cache.updateQuery({
      query: GET_PATIENT_TASK_ASSIGNMENTS,
      variables: {
        tenantId: tenant_id,
        patientId: patient_id
      }
    }, (cachedata) => {
      const tempData = _cloneDeep(cachedata)
      const existingTasks: TaskAssignmentType[] = _get(cachedata, 'tenant.patient.task.list', [])
      tempData.tenant.patient.task.list = sortPatientTaskList(!newTask ? existingTasks : [...existingTasks, newTask])
      return tempData
    })
  }

  const providerValues = {
    patientTasksRef,
    parsePatientTaskQueryResults,
    createTaskFromChart,
    updateTaskFromChart,
    deleteTaskFromChart
  }

  return (
    <PatientTaskContext.Provider value={providerValues}>
      { children }
    </PatientTaskContext.Provider>
  )
}

const sortPatientTaskList = (taskList: TaskAssignmentType[]) => {
  return [...taskList].sort((a, b) => {
    if (a.state.category === TaskCategory.INCOMPLETE && b.state.category === TaskCategory.DONE) {
      return -1
    } else if (a.state.category === TaskCategory.DONE && b.state.category === TaskCategory.INCOMPLETE) {
      return 1
    }

    if (a.dueDate < b.dueDate) {
      return -1
    } else if (a.dueDate > b.dueDate) {
      return 1
    }

    const aPriority = TaskPriorityMap.get(a.priority!)!.order
    const bPriority = TaskPriorityMap.get(b.priority!)!.order
    if (aPriority < bPriority) {
      return -1
    } else if (aPriority > bPriority) {
      return 1
    }

    return 0
  })
}

export const usePatientTaskContext = () => {
  return React.useContext(PatientTaskContext)
}