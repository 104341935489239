import React, { useTransition } from 'react'
import { useParams } from "react-router-dom";
import { useBackgroundQuery, QueryReference, useMutation } from '@apollo/client'
import _get from 'lodash/get'
import {
  CREATE_ENCOUNTER_NOTE,
  DELETE_ENCOUNTER_NOTE,
  GET_PATIENT_ENCOUNTER_NOTES,
  UPDATE_ENCOUNTER_NOTE
} from '../../../graphql-definitions'
import { EncounterNote, EncounterNoteInput } from '../../../types/patients'
import { EncounterNoteSortPredicate } from '../components/encounterNotes/EncounterNoteUtil'
import { useAlerts } from "saga-library/src/providers/Alerts";
import { usePatientProfileContext } from './PatientProfileProvider'

const getEncounterNoteQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.encounterNote.list', []) as Array<EncounterNote>]
}

export const parseEncounterNoteQueryResults = (data) => {
  return getEncounterNoteQueryResults(data).sort(EncounterNoteSortPredicate)
}

interface EncounterNoteContextInterface {
  encounterNoteQueryRef: QueryReference | null
  createEncounterNote: (inputEncounterNote: EncounterNoteInput, onSuccess: (returnData: EncounterNote) => void, message?: string) => Promise<void>
  updateEncounterNote: (inputEncounterNote: EncounterNoteInput, onSuccess: (returnData: EncounterNote) => void, message?: string) => Promise<void>
  deleteEncounterNote: (encounterNote: EncounterNoteInput, onSuccess: () => void, onError: () => void) => Promise<void>
  getEncounterNoteQueryResults: (any) => EncounterNote[]
  parseEncounterNoteQueryResults: (any) => EncounterNote[]
}

const defaultEncounterNoteContext: EncounterNoteContextInterface = {
  encounterNoteQueryRef: null,
  createEncounterNote: (inputEncounterNote: EncounterNoteInput, onSuccess: (returnData: EncounterNote) => void, message?: string) => Promise.resolve(),
  updateEncounterNote: (inputEncounterNote: EncounterNoteInput, onSuccess: (returnData: EncounterNote) => void, message?: string) => Promise.resolve(),
  deleteEncounterNote: (encounterNote: EncounterNoteInput, onSuccess: () => void, onError: () => void) => Promise.resolve(),
  getEncounterNoteQueryResults: getEncounterNoteQueryResults,
  parseEncounterNoteQueryResults: parseEncounterNoteQueryResults
}

const EncounterNoteContext = React.createContext(defaultEncounterNoteContext)

export const EncounterNoteProvider = ({ children }) => {
  const [isPending, startTransition] = useTransition()
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { refetchPatientCurrentVitals  } = usePatientProfileContext()

  const [ createEncounterNoteMutation ] = useMutation(CREATE_ENCOUNTER_NOTE)
  const [ updateEncounterNoteMutation ] = useMutation(UPDATE_ENCOUNTER_NOTE)
  const [ deleteEncounterNoteMutation ] = useMutation(DELETE_ENCOUNTER_NOTE)

  const [encounterNoteQueryRef] = useBackgroundQuery(GET_PATIENT_ENCOUNTER_NOTES, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const createEncounterNote = async (inputEncounterNote: EncounterNoteInput, onSuccess, message) => {
    await createEncounterNoteMutation({
      variables: {
        tenantId: tenant_id,
        input: {
          ...inputEncounterNote,
          patientId: patient_id
        }
      },
      onCompleted: (data) => {
        const encounterNoteResult = _get(data, 'tenant.patient.encounterNote.create', null)
        showSuccessAlert(message || "Encounter note has been saved.")
        if(onSuccess) {
          onSuccess(encounterNoteResult)
        }
        startTransition(() => {
          refetchPatientCurrentVitals()
        })
      },
      onError: (error) => {
        showErrorAlert("Encounter note couldn't be created.")
      },
      update: (cache, { data }) => {
        const newNote = _get(data, 'tenant.patient.encounterNote.create', null)
        updateEncounterNoteCache(cache, newNote, tenant_id, patient_id)
      }
    })
  }

  const updateEncounterNote = async (inputEncounterNote: EncounterNoteInput, onSuccess, message) => {
    const inputEncounterNoteId = inputEncounterNote.id
    delete inputEncounterNote.id

    await updateEncounterNoteMutation({
      variables: {
        tenantId: tenant_id,
        id: inputEncounterNoteId,
        input: inputEncounterNote
      },
      onCompleted: (data) => {
        const encounterNoteResult = _get(data, 'tenant.patient.encounterNote.update', null)
        showSuccessAlert(message || "Encounter note has been saved.")
        if(onSuccess) {
          onSuccess(encounterNoteResult)
        }
        startTransition(() => {
          refetchPatientCurrentVitals()
        })
      },
      onError: (error) => {
        showErrorAlert("Encounter note couldn't be saved.")
      }
    })
  }

  const deleteEncounterNote = async (encounterNote: EncounterNoteInput, onSuccess, onError) => {
    await deleteEncounterNoteMutation({
      variables: {
        tenantId: tenant_id,
        id: encounterNote.id,
        version: encounterNote.version
      },
      onCompleted: () => {
        showSuccessAlert("Encounter note has been deleted.")
        onSuccess()
        startTransition(() => {
          refetchPatientCurrentVitals()
        })
      },
      onError: () => {
        showErrorAlert("Encounter note couldn't be deleted.")
        onError()
      },
      update: (cache) => {
        const normalizedId = cache.identify({
          id: encounterNote.id,
          __typename: "EncounterNote",
          isLinkedDocument: false
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const providerValues = {
    encounterNoteQueryRef,
    createEncounterNote,
    updateEncounterNote,
    deleteEncounterNote,
    getEncounterNoteQueryResults,
    parseEncounterNoteQueryResults
  }

  return (
    <EncounterNoteContext.Provider value={providerValues}>
      { children }
    </EncounterNoteContext.Provider>
  )
}

export const useEncounterNoteContext = () => {
  return React.useContext(EncounterNoteContext)
}

const updateEncounterNoteCache = async(cache, newEncounterNote, tenantId, patientId) => {
  await cache.updateQuery({
    query: GET_PATIENT_ENCOUNTER_NOTES,
    variables: {
      tenantId,
      patientId
    }
  }, (data) => {
    const existingEncounterNotes = _get(data, 'tenant.patient.encounterNote.list', []).filter(d => d.id !== newEncounterNote.id || d.isLinkedDocument !== newEncounterNote.isLinkedDocument)

    return {
      tenant: {
        patient: {
          encounterNote: {
            list: [...existingEncounterNotes, newEncounterNote]
          }
        }
      }
    }
  })
}

const transformLinkedDocument = (document) => {
  const description = !!document.description || document.description.trim().length > 0 ? document.description : "Encounter note"

  return {
    id: document.id,
    encounterNoteDate: document.documentDate,
    encounterNoteDisplayDate: document.documentDate,
    isLinkedDocument: true,
    linkedDocument: document,
    __typename: 'EncounterNote',
    audit: document.audit,
    description: description,
    template: {
      id: null,
      name: description,
      summaryFieldId: null,
      summaryField: null,
      fields: [],
      __typename: 'EncounterNoteTemplate'

    },
    signOffUser: null,
    signOffPit: null,
    practitioner: null,
    appointment: null,
    fields: [],
    version: "0"

  }
}

export const moveDocumentToEncounterNote= async(cache, document, tenantId, patientId) => {
  const newLab = transformLinkedDocument(document)

  await updateEncounterNoteCache(cache, newLab, tenantId, patientId)
}
