import {
  EncounterNote,
  EncounterNoteField,
  EncounterNoteTemplate,
  EncounterNoteTemplateFieldTree,
  EncounterNoteTemplateFieldType,
} from '../../../../types/patients'
import { nullOrUndefinedComparison } from 'saga-library/src/components/TableList/TableSortUtils'
import moment from 'moment-timezone'
import {
  LengthHeightType,
  LengthHeightUnits,
  PatientVitalsBloodPressure,
  PatientVitalsHeadCircumference,
  PatientVitalsHeartRate,
  PatientVitalsLengthHeight,
  PatientVitalsWeight,
  WeightUnits,
} from '../vitals/VitalTypes'

export type VitalEntry = {
  lengthHeight?: string,
  lengthHeightUnit?: LengthHeightUnits,
  lengthHeightType?: LengthHeightType,
  weight?: string,
  weightUnit?: WeightUnits,
  bloodPressureSystolic?: string,
  bloodPressureDiastolic?: string,
  heartRate?: string,
  headCircumference?: string,
  headCircumferenceUnit?: LengthHeightUnits
}

export type CheckboxTextboxEntry = {
  selected: boolean,
  text: string
}

export const EncounterNoteSortPredicate = (a:EncounterNote, b:EncounterNote) : number => {
  return (nullOrUndefinedComparison(a.encounterNoteDisplayDate, b.encounterNoteDisplayDate) ?? moment(b.encounterNoteDisplayDate).unix() - moment(a.encounterNoteDisplayDate).unix())
    || (nullOrUndefinedComparison(a.audit?.crePit, b.audit?.crePit) ?? moment(b.audit?.crePit).unix() - moment(a.audit?.crePit).unix())
    || 0
}

export const getEncounterNoteSummary = (encounterNote: EncounterNote) => {
  const summaryData = encounterNote.fields?.find(f => f.templateFieldId === encounterNote.template?.summaryFieldId)?.data
  if(summaryData) {
    return `${encounterNote.template?.summaryField || "Summary"}: ${summaryData}`
  }
}

export const convertEncounterNoteToInput = (encounterNote) => {
  let encounterNoteInput = {
    id: encounterNote.id,
    templateId: encounterNote.template?.id,
    appointmentId: encounterNote.appointment?.id,
    appointmentStartTime: encounterNote.appointment?.start,
    practitionerId: encounterNote.practitioner?.id,
    encounterNoteDate: encounterNote.encounterNoteDisplayDate,
    fields: encounterNote.fields,
    version: encounterNote.version
  }

  encounterNote.template?.fields?.forEach((templateField) => {
    const matchedFields = encounterNote.fields?.filter((field) => field.templateFieldId === templateField.id)
    matchedFields.forEach((matchedField) => {
      const fieldId = `${templateField.type.toLowerCase()}-${templateField.id}-${matchedField.additionalFieldOrder ?? 0}`
      if (matchedField.data || matchedField.isChecked) {
        if (templateField.type === EncounterNoteTemplateFieldType.CHECKBOX_TEXTBOX) {
          encounterNoteInput[fieldId] = {
            selected: matchedField.isChecked,
            text: matchedField.data
          }
        } else {
          encounterNoteInput[fieldId] = matchedField.data
        }
      } else {
        if (matchedField.patientVitalsWeight) {
          encounterNoteInput[fieldId] = {
            weight: matchedField.patientVitalsWeight.weight,
            weightUnit: matchedField.patientVitalsWeight.weightUnit,
          }
        } else if (matchedField.patientVitalsHeartRate) {
          encounterNoteInput[fieldId] = {
            heartRate: matchedField.patientVitalsHeartRate.heartRate,
          }
        } else if (matchedField.patientVitalsLengthHeight) {
          encounterNoteInput[fieldId] = {
            lengthHeight: matchedField.patientVitalsLengthHeight.lengthHeight,
            lengthHeightUnit: matchedField.patientVitalsLengthHeight.lengthHeightUnit,
          }
        } else if (matchedField.patientVitalsBloodPressure) {
          encounterNoteInput[fieldId] = {
            bloodPressureSystolic: matchedField.patientVitalsBloodPressure.bloodPressureSystolic,
            bloodPressureDiastolic: matchedField.patientVitalsBloodPressure.bloodPressureDiastolic,
          }
        } else if (matchedField.patientVitalsHeadCircumference) {
          encounterNoteInput[fieldId] = {
            headCircumference: matchedField.patientVitalsHeadCircumference.headCircumference,
            headCircumferenceUnit: matchedField.patientVitalsHeadCircumference.headCircumferenceUnit,
          }
        }
      }
    })
  })

  return encounterNoteInput
}

type KeyField = {
  type: string,
  id: string,
  order: string
}

export const splitFields = (input: string): KeyField => {
  const parts = input.split('-')
  return {
    type: parts[0],
    id: parts[1],
    order: parts[2]
  }
}

export const cleanEncounterNoteInput = (encounterNote) => {
  let encounterNoteData = Object.assign({}, encounterNote)
  let fields: EncounterNoteField[] = []

  for (const [key, value] of Object.entries(encounterNoteData)) {
    const keyField = splitFields(key)
    const matchedField = encounterNoteData.fields?.find((field) =>
      field.templateFieldId === keyField.id
      && (field.additionalFieldOrder === null || field.additionalFieldOrder === parseInt(keyField.order))
    )

    switch (keyField.type) {
      case "textbox":
        const field = formatTextboxField(matchedField, keyField.id, value as string)
        fields.push(field)
        delete encounterNoteData[key]
        break
      case "vitals":
        const vitals = formatVitalsField(matchedField, keyField, value as VitalEntry, encounterNote.encounterNoteDate, encounterNote.appointmentStartTime)
        if (vitals) {
          fields.push(vitals)
        }
        delete encounterNoteData[key]
        break
      case "checkbox_textbox":
        const checkboxTextboxField = formatCheckboxTextboxField(matchedField, keyField.id, value as CheckboxTextboxEntry)
        fields.push(checkboxTextboxField)
        delete encounterNoteData[key]
        break
      default:
        console.warn("Unknown field type: ", keyField.type)
    }
  }

  encounterNoteData.fields = fields

  if (!!BigInt(encounterNoteData.version)) {
    delete encounterNoteData.templateId
  }
  delete encounterNoteData.appointmentStartTime

  return encounterNoteData
}

const formatTextboxField = (matchedField: EncounterNoteField, fieldId: string, data: string): EncounterNoteField => {
  if (matchedField) {
    return {
      ...matchedField,
      data: data
    }
  }
  return {
    templateFieldId: fieldId,
    data: data,
    version: "0"
  }
}

const formatCheckboxTextboxField = (matchedField: EncounterNoteField, fieldId: string, data: CheckboxTextboxEntry): EncounterNoteField => {
  if (matchedField) {
    return {
      ...matchedField,
      data: data.text,
      isChecked: data.selected
    }
  }
  return {
    templateFieldId: fieldId,
    data: data.text,
    isChecked: data.selected,
    version: "0"
  }
}

export const VitalTypesList  = [
  {value: 'heartRate', label: 'Heart Rate'},
  {value: 'bloodPressure', label: 'BP'},
  {value: 'lengthHeight', label: 'Height'},
  {value: 'weight', label: 'Weight'},
  {value: 'headCircumference', label: 'Head Circumference'},
]

const formatVitalsField = (matchedField: EncounterNoteField, keyField: KeyField, data: VitalEntry, date: Date, appointmentTime?: string): EncounterNoteField | null => {
  if (!data.weight && !data.lengthHeight && !data.heartRate && !data.bloodPressureSystolic && !data.bloodPressureDiastolic && !data.headCircumference) {
    return null
  }

  let vitals: any = { ...matchedField, additionalFieldOrder: parseInt(keyField.order), templateFieldId: keyField.id, version: matchedField?.version ?? '0' }
  const vitalDate = appointmentTime ? moment(appointmentTime).startOf('day') : moment(date).startOf('day')
  const vitalTime = appointmentTime

  if (data.weight) {
    vitals.patientVitalsWeight = {
      vitalDate: vitalDate,
      vitalTime: vitalTime,
      weight: parseFloat(data.weight),
      weightUnit: data.weightUnit ?? WeightUnits.KG,
    }
  }
  if (data.lengthHeight) {
    vitals.patientVitalsLengthHeight = {
      vitalDate: vitalDate,
      vitalTime: vitalTime,
      lengthHeight: parseFloat(data.lengthHeight),
      lengthHeightUnit: data.lengthHeightUnit ?? LengthHeightUnits.CM,
      lengthHeightType: data.lengthHeightType ?? LengthHeightType.HEIGHT,
    }
  }
  if (data.heartRate) {
    vitals.patientVitalsHeartRate = {
      vitalDate: vitalDate,
      vitalTime: vitalTime,
      heartRate: parseFloat(data.heartRate),
    }
  }
  if (data.bloodPressureSystolic || data.bloodPressureDiastolic) {
    vitals.patientVitalsBloodPressure = {
      vitalDate: vitalDate,
      vitalTime: vitalTime,
      bloodPressureSystolic: data.bloodPressureSystolic ? parseFloat(data.bloodPressureSystolic) : null,
      bloodPressureDiastolic: data.bloodPressureDiastolic ? parseFloat(data.bloodPressureDiastolic) : null,
    }
  }
  if (data.headCircumference) {
    vitals.patientVitalsHeadCircumference = {
      vitalDate: vitalDate,
      vitalTime: vitalTime,
      headCircumference: parseFloat(data.headCircumference),
      headCircumferenceUnit: data.headCircumferenceUnit ?? LengthHeightUnits.CM
    }
  }
  return vitals
}

export const buildNewEncounterNote = (template:EncounterNoteTemplate) : EncounterNote => {
  const encounterNote:EncounterNote = {
    template: template,
    templateId: template.id,
    encounterNoteDisplayDate: new Date(),
    fields: template.fields?.filter(field => field.type !== EncounterNoteTemplateFieldType.GROUP)
      .map(field => {
        return {
          templateFieldId: field.id,
          data: field.type === EncounterNoteTemplateFieldType.VITALS ? undefined : null,
          patientVitalsWeight: field.type !== EncounterNoteTemplateFieldType.VITALS ? undefined : {
            weight: 0,
            weightUnit: WeightUnits.KG,
            vitalDate: new Date(),
            vitalTime: undefined,
          } as PatientVitalsWeight,
          patientVitalsHeartRate: field.type !== EncounterNoteTemplateFieldType.VITALS ? undefined : {
            heartRate: 0,
            vitalDate: new Date(),
            vitalTime: undefined,
          } as PatientVitalsHeartRate,
          patientVitalsLengthHeight: field.type !== EncounterNoteTemplateFieldType.VITALS ? undefined : {
            lengthHeight: 0,
            lengthHeightUnit: LengthHeightUnits.CM,
            lengthHeightType: LengthHeightType.HEIGHT,
            vitalDate: new Date(),
            vitalTime: undefined,
          } as PatientVitalsLengthHeight,
          patientVitalsBloodPressure: field.type !== EncounterNoteTemplateFieldType.VITALS ? undefined : {
            bloodPressureSystolic: 0,
            bloodPressureDiastolic: 0,
            vitalDate: new Date(),
            vitalTime: undefined,
          } as PatientVitalsBloodPressure,
          patientVitalsHeadCircumference: field.type !== EncounterNoteTemplateFieldType.VITALS ? undefined : {
            headCircumference: 0,
            headCircumferenceUnit: LengthHeightUnits.CM,
            vitalDate: new Date(),
            vitalTime: undefined,
          } as PatientVitalsHeadCircumference,
        } as EncounterNoteField
      })
  }

  return buildEncounterNote(encounterNote)
}

export const buildEncounterNote = (encounterNote:EncounterNote) : EncounterNote => {
  if (!encounterNote.template) {
    return encounterNote
  }

  // create new field objects with subfields and a link to the field on the encounter note
  const fieldTree = encounterNote.template?.fields?.map(templateField => {
    return {
      ...templateField,
      subFields: [],
      encounterNoteField: encounterNote.fields?.find(field => field.templateFieldId === templateField.id)
    } as EncounterNoteTemplateFieldTree
  })

  if (!fieldTree) {
    return encounterNote
  }

  // move each field to be contained within its parent group
  fieldTree?.forEach(templateField => {
    if (templateField.parentGroupId) {
      const parentTemplateField = fieldTree?.find(parentField => parentField.id === templateField.parentGroupId)
      if (parentTemplateField) {
        // sort by field.order
        const index = parentTemplateField.subFields.findIndex(subField => subField.order > templateField.order)
        if (index >= 0) {
          parentTemplateField.subFields.splice(index, 0, templateField)
        } else {
          parentTemplateField.subFields.splice(0, 0, templateField)
        }
        parentTemplateField.subFields.sort((a, b) => (a.order - b.order))
      }
    }
  })

  // remove each field that is now contained within its parent group
  const newTemplate = {
    ...encounterNote.template,
    fieldTree: fieldTree
      .filter(field => !field.parentGroupId)
      .sort((a, b) => (a.order - b.order)),
  };

  encounterNote = {
    ...encounterNote,
    template: newTemplate,
  };

  return encounterNote
}