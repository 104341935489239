import { TaskType } from './TaskType'
import { TaskState } from './TaskState'
import { TaskPriority } from './TaskPriority'
import { TaskTemplate } from './TaskTemplate'
import { AssignedToOptionType } from '../../components/AssignedTo/AssignedTo'
import { PatientSearchResult } from '../patients'
import { Role, User } from '../account'
import { PatientProfileType } from '../../apps/patients/PatientTypes'
import {SagaTypographyVariants} from "saga-library/src/components/Typography/Typography";

type TaskAssignmentBase = {
  name?: string
  description?: string
  priority?: TaskPriority
  startDate: Date
  dueDate: Date
  assignedUserId?: string | null
  assignedRoleId?: string | null
  practitionerId?: string
  version: string
  comments?: CommentType[]
  linkedItems?: LinkedItemType[]
}

export type TaskAssignmentInput = TaskAssignmentBase & {
  id?: string
  template?: TaskTemplate | null
  typeId?: string
  type?: TaskType
  stateId?: string
  state?: TaskState
  assignedTo?: AssignedToOptionType
  patientId?: string | null
  patient?: PatientSearchResult | PatientProfileType
  comment?: string | null
}

export type CommentType = {
  id?: string
  state: CommentStateType

}
export enum CommentStateType{
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export enum LinkType {
  CONDITION_PROCEDURE = 'CONDITION_PROCEDURE',
  LAB_INVESTIGATION = 'LAB_INVESTIGATION',
  ALLERGY = 'ALLERGY',
  ENCOUNTER_NOTE = 'ENCOUNTER_NOTE',
  FORM = 'FORM',
  LETTER = 'LETTER',
  PRESCRIPTION = 'PRESCRIPTION',
  OTHER_DOCUMENT = 'OTHER_DOCUMENT',
  TASK = 'TASK',
  LINKED_DOCUMENT='LINKED_DOCUMENT'
}

export type LinkedItemType = {
  id?: string
  linkType?: LinkType
}

export type TaskAssignmentType = TaskAssignmentBase & {
  id: string
  type: TaskType
  state: TaskState
  patient?: PatientSearchResult
  assignedUser?: User
  assignedRole?: Role
  variant?: SagaTypographyVariants
}