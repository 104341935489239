import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import {ConfirmationDialog, Form, TextField} from 'saga-library/src'
import { FormRow } from '../../../../components/FormRow'
import { AssignedTo } from '../../../../components/AssignedTo'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import FormattedDatePicker from '../../../../components/FormattedDatePicker'
import { TaskTemplateSelect } from '../../../../components/TaskTemplateSelect'
import { TaskStateSelect } from '../../../../components/TaskStateSelect'
import { PatientSearch } from '../../../../components/SearchControls/Patient/PatientSearch'
import { TaskTypeSelect } from '../../../../components/TaskTypeSelect'
import { TaskPrioritySelect } from '../../../../components/TaskPrioritySelect'
import { useFormContext } from 'react-hook-form'
import { rules } from './TaskValidationsSchema'
import { CommentsField } from './CommentsField'
import LinkItemSelect from '../LinkItemSelect/LinkItemSelect'
import { PermissionForm } from '../../../../components/PermissionForm'
import { PermissionType } from '../../../../types/settings/Permission'
import TabFocusInterceptor from 'saga-library/src/hooks/tabFocusInterceptor'

interface TaskFormProps {
  formName: string
  showTemplateSelect?: boolean
  canClearPatient?: boolean
  fetchDetails?: boolean
  autoFocus?: boolean
  dataTestId: string
}

export const TaskForm = ({
  requiredPermissionType,
  formDisabled = false,
  onSubmit,
  ...props
}: TaskFormProps & {
  requiredPermissionType?: PermissionType
  formDisabled?: boolean
  onSubmit: () => void
}) => {
  const { formName } = props

  if (requiredPermissionType !== undefined) {
    return (
      <PermissionForm
        name={formName}
        onSubmit={onSubmit}
        id={formName}
        requiredPermissionType={requiredPermissionType}
        readOnlyOverride={true}
        formDisabled={formDisabled}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '8px'
        }}
      >
        <TaskFormBase {...props} />
      </PermissionForm>
    )
  }

  return (
    <Form onSubmit={onSubmit} id={formName}>
      <TaskFormBase {...props} />
    </Form>
  )
}

const TaskFormBase = ({
  showTemplateSelect = true,
  canClearPatient = true,
  fetchDetails = false,
  autoFocus = false,
  dataTestId
}: TaskFormProps) => {
  const LINK_ITEM_NAME = "linkedItems"
  const { watch, setValue } = useFormContext()
  const firstFieldRef = useRef<any>(null)
  const [hasFocus, setHasFocus] = useState(autoFocus)
  const [openRemovePatientConfirmation, setOpenRemovePatientConfirmation] = useState<boolean>(false)

  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !autoFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef])

  const id = watch('id')
  const version = watch('version')

  const resetPatient = () => {
    setValue(LINK_ITEM_NAME, [])
    setValue("patient", null, { shouldDirty: true })
    setOpenRemovePatientConfirmation(false)
  }

  return (
    <>
      <ConfirmationDialog
          title={"Remove patient?"}
          message={"Linked items will be removed."}
          open={openRemovePatientConfirmation}
          primaryLabel={"Remove"}
          primaryAction={resetPatient}
          onClose={()=>setOpenRemovePatientConfirmation(false)}
      />
      {!!showTemplateSelect && (
        <TaskTemplateSelect
          inputRef={firstFieldRef}
          name={'template'}
          label={'Template'}
          autoFocus={autoFocus}
          dataTestId={`${dataTestId}-template`}
        />
      )}
      <FormRow>
        <TaskTypeSelect
          name={'typeId'}
          label={'Type'}
          sx={{ flex: '0 0 calc(25% - 12px)' }}
          dataTestId={`${dataTestId}-type`}
        />
        <TextField
          name={'name'}
          label={'Name'}
          inputProps={{ maxLength: rules.name.max }}
          fullWidth={true}
          dataTestId={`${dataTestId}-name`}
        />
      </FormRow>
      <FormRow>
        <Box flex={'1 1 50%'}>
          <AssignedTo
            name={'assignedTo'}
            label={'Assigned to'}
            includeRoles={true}
            dataTestId={`${dataTestId}-assignedTo`}
          />
          <FormRow>
            <TaskStateSelect
              name={'stateId'}
              label={'State'}
              dataTestId={`${dataTestId}-state`}
            />
            <TaskPrioritySelect
              name={'priority'}
              label={'Priority'}
              dataTestId={`${dataTestId}-priority`}
            />
          </FormRow>
          <FormRow>
            <FormattedDatePicker
              name={'startDate'}
              label={'Start date'}
              sx={{ width: '100%' }}
              dataTestId={`${dataTestId}-startDate`}
            />
            <FormattedDatePicker
              name={'dueDate'}
              label={'Due date'}
              sx={{ width: '100%' }}
              dataTestId={`${dataTestId}-dueDate`}
            />
          </FormRow>
          <PractitionerSelect
            name={'practitionerId'}
            label={'Practitioner'}
            dataTestId={`${dataTestId}-practitioner`}
          />
          <PatientSearch
            key={id && `${id}-${version}`}
            name={'patient'}
            label={'Patient'}
            defaultStyle={'expanded'}
            fetchDetails={!!id || !!fetchDetails}
            disabled={!canClearPatient}
            dataTestId={`${dataTestId}-patient`}
            patientLabelSx={{
              mb:3
            }}
            onClear={()=>setOpenRemovePatientConfirmation(true)}
          />
          <LinkItemSelect name={LINK_ITEM_NAME} />
        </Box>
        <Box flex={'1 1 50%'}>
          <TextField
            name={'description'}
            label={'Description'}
            multiline={true}
            rows={3}
            fullWidth={true}
            dataTestId={`${dataTestId}-description`}
          />
          <CommentsField dataTestId={`${dataTestId}`} key={`task_comments_${id}`}/>
        </Box>
      </FormRow>
    </>
  )
}