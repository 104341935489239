

export const getTenantStorageKey = (userId) => {
  return `${userId}:tenant_id`
}

export const getTenantLocationStorageKey = (userId) => {
  return `${userId}:tenant_location_id`
}

export const getScheduleLocationStorageKey = (userId) => {
  return `${userId}:schedule_location_id`
}

export const getScheduleStorageKey = (userId, tenantId) => {
  return `${userId}:${tenantId}:lastViewedSchedule`
}

export const getDocumentViewerStorageKey = (userId) => {
  return `${userId}:documentViewerSettings`
}

