import { gql } from "@apollo/client";
import { DOCUMENTS_FRAGMENT } from "./LinkedDocumentQueries";

export const ENCOUNTER_NOTE_FRAGMENT = gql`
  ${DOCUMENTS_FRAGMENT}
  fragment EncounterNoteFragment on EncounterNote {
    id
    encounterNoteDate
    encounterNoteDisplayDate
    isLinkedDocument
    linkedDocument {
      ...DocumentsFragment
    }
    description
    template {
      id
      name
      summaryFieldId
      summaryField
      fields {
        id
        name
        type
        order
        parentGroupId
      }
    }
    appointment {
      id
      start
    }
    practitioner {
      id
      firstName
      lastName
    }
    signOffUser {
      id
    }
    signOffPit
    fields {
      id
      templateFieldId
      data
      isChecked
      additionalFieldOrder
      patientVitalsWeight {
        weight
        weightUnit
      }
      patientVitalsHeartRate {
        heartRate
      }
      patientVitalsLengthHeight {
        lengthHeight
        lengthHeightUnit
        lengthHeightType
      }    
      patientVitalsBloodPressure {
        bloodPressureSystolic
        bloodPressureDiastolic
      }
      patientVitalsHeadCircumference {
        headCircumference
        headCircumferenceUnit
      }
      version
    }
    audit {
      crePit
    }
    version
  }
`

export const GET_PATIENT_ENCOUNTER_NOTES = gql`
  ${ENCOUNTER_NOTE_FRAGMENT}
  query GetPatientEncounterNotes($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        encounterNote {
          list {
            ...EncounterNoteFragment
          }
        }
      }
    }
  }
`

export const GET_ENCOUNTER_NOTE = gql`
  ${ENCOUNTER_NOTE_FRAGMENT}
  query GetEncounterNote($tenantId: ID!, $patientId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        encounterNote {
          get(id: $id) {
            ...EncounterNoteFragment
          }
        }
      }
    }
  }
`

export const CREATE_ENCOUNTER_NOTE = gql`
  ${ENCOUNTER_NOTE_FRAGMENT}
  mutation CreateEncounterNote($tenantId: ID!, $input: EncounterNoteCreateInput!) {
    tenant(id: $tenantId) {
      patient {
        encounterNote {
          create(input: $input) {
            ...EncounterNoteFragment
          }
        }
      }
    }
  }
`

export const UPDATE_ENCOUNTER_NOTE = gql`
  ${ENCOUNTER_NOTE_FRAGMENT}
  mutation UpdateEncounterNote($tenantId: ID!, $id: ID!, $input: EncounterNoteUpdateInput!) {
    tenant(id: $tenantId) {
      patient {
        encounterNote {
          update(id: $id, input: $input) {
            ...EncounterNoteFragment
          }
        }
      }
    }
  }
`

export const DELETE_ENCOUNTER_NOTE = gql`
  mutation DeleteEncounterNote($tenantId: ID!, $id : ID!, $version: String!) {
    tenant(id: $tenantId) {
      patient {
        encounterNote {
          delete(id: $id, version: $version)
        }
      }
    }
  }
`