import {useSagaAuthentication} from "../providers/Auth";
import {Navigate, Outlet, Route, useLocation} from "react-router-dom";
import {DestinationBeforeRedirect} from "../types";
import {AppSettingsContextProvider} from "../providers/AppSettingsContext";
import {StaticDataContextProvider} from "../providers/StaticDataContext";
import {AccountContextProvider} from "../providers/AccountContext";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import React from "react";
import {BasicLayout, SettingsLayout} from "../components/Layouts";
import {Home} from "./Home";
import {ResetEmail} from "./ResetEmail";
import {ResourceNotFound} from "./static/ErrorPages";
import { tenantRoutes } from "./TenantRoutes"


export const privateRoutes = () => {
  return (
    <Route element={<PrivateRouteBoundary/>}>
      <Route element={<PrivateProviders/>}>
        <Route path="signin" element={<Navigate to="/home" replace />} />

        <Route element={<SettingsLayout />}>
          <Route path="home" element={<Home />} />
          <Route index element={<Home />} />
        </Route>

        {tenantRoutes()}

        <Route path="invitation//*" element={<></>} />
        <Route path="reset-password" element={<></>} />
        <Route path="change-email-verification" element={<ResetEmail />} />
        <Route path="privacy-policy" element={<></>} />
        <Route path="terms-of-service" element={<></>} />
        <Route element={<BasicLayout />}>
          <Route path="*" element={<ResourceNotFound />} />
        </Route>
      </Route>
    </Route>
  )
}


const PrivateRouteBoundary = () => {
  const { isAuthenticated } = useSagaAuthentication()
  const location = useLocation()
  const destination: DestinationBeforeRedirect = {
    from: location
  }

  if (isAuthenticated){
    return <Outlet/>
  }

  return <Navigate to={"/signin"} state={ destination }/>
}


const PrivateProviders = () => {
  return (
    <AppSettingsContextProvider>
      <StaticDataContextProvider>
        <AccountContextProvider>
          <DndProvider backend={HTML5Backend}>
            <Outlet/>
          </DndProvider>
        </AccountContextProvider>
      </StaticDataContextProvider>
    </AppSettingsContextProvider>
  )
}






