import { Box } from '@mui/material'
import React from 'react'
import { ClaimBaseDetails } from './ClaimBaseDetails'

export const ClaimBase = ({
  isAssessed,
  dataTestId
}) => {
  return (
    <Box>
      <ClaimBaseDetails.Form
        isAssessed={isAssessed}
        dataTestId={dataTestId}
      />
    </Box>
  )
}
