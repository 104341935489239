import React from 'react'
import './App.css'
import {Route, Routes} from "react-router-dom";
import { ResetPassword } from 'routes/ResetPassword'
import { InviteRedemption } from './routes/InviteRedemption'
import { PrivacyPolicy } from './routes/static/PrivacyPolicy'
import { TermsOfService } from './routes/static/TermsOfService'
import { AlertsProvider } from 'saga-library/src/providers/Alerts'
import { NavigationPromptProvider } from "./providers/NavigationPrompt";
import { PageTitleContextProvider } from "./providers/PageTitleContextProvider";
import { useApolloClientContext, Apollo } from "./providers/Apollo";
import { UserInteractionProvider } from './providers/UserInteractionContext'
import {publicOnlyRoutes} from "./routes/PublicOnlyRoutes";
import {privateRoutes} from "./routes/PrivateRoutes"



const App = () => {
  const { mainClient } = useApolloClientContext()

  return (
    <Apollo client={mainClient}>
      <div className="App">

          <AlertsProvider>
            <NavigationPromptProvider>
              <PageTitleContextProvider>
                <UserInteractionProvider>

                  <Routes>

                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="terms-of-service" element={<TermsOfService />} />
                    <Route path="invitation//*" element={<InviteRedemption />} />
                    <Route path="reset-password" element={<ResetPassword />} />

                    {publicOnlyRoutes()}

                    {privateRoutes()}

                  </Routes>

                </UserInteractionProvider>
              </PageTitleContextProvider>
            </NavigationPromptProvider>
          </AlertsProvider>

      </div>
    </Apollo>
  )
}



export default App
